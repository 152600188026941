import axios from "axios";
import useAuth from "../Hooks/useAuth";
import AuthApiService from "./AuthApiService";

let failedQueue = [];
let isRefreshing = false;

const processQueue = (error) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve();
        }
    });

    failedQueue = [];
};

export function createAxiosClient({
    options,
    getCurrentAccessToken,
    getCurrentRefreshToken,
    setAccessTokens,
    setRefreshedTokens,
    signout
}) {
    const client = axios.create(options);

    client.interceptors.request.use(
        (config) => {
            if (config.authorization !== false) {
                const storage = JSON.parse(localStorage.auth);
                const accessToken = storage.state.accessToken;
                if (accessToken) {
                    config.headers.Authorization = accessToken;
                }
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    // const getAccesstokenWithRefreshtoken = async () => {
    //     const data = { refresh: getCurrentRefreshToken() }
    //     console.log('getAccesstokenWithRefreshtoken');
    //     console.log("refresh ✨");
    //     console.log(data);

    //     await AuthApiService.reqAccessToken(data)
    //         .then(async response => {

    //             const accessToken = response.headers.authorization;
    //             const refreshToken = response.headers.refresh;

    //             // response가 정상이라면 setToken
    //             setAccessTokens(accessToken);
    //             setRefreshedTokens(refreshToken);


    //         }).catch(error => {
    //             console.log('reqAccessToken.error', error);
    //         })
    // }

    // let isTokenRefreshing = false;
    // let refreshSubscribers = [];



    

    // const onTokenRefreshed = (originalRequest) => {
    //     originalRequest.headers.Authorization = getCurrentAccessToken();
    //     refreshSubscribers.map((callback) => callback(getCurrentAccessToken()));
    //     refreshSubscribers = [];
    // };

    // const addRefreshSubscriber = (callback) => {
    //     refreshSubscribers.push(callback);
    // };

    client.interceptors.response.use(
        //정상 응답 처리
        (response) => {
            // console.log("response interceptor")
            return response;
        },
        //오류 발생 시
        async (error) => {
            console.log("error!!!!");
            console.log(error);

            // 만료된 토큰일 시 아무것도 안함
            if (
                error.response?.status === 401 &&
                error.response?.data.code === '8002'
            ) {
                console.log("만료 토큰~!💥");
                signout();
                return;
            }
            // 권한이 없는 토큰일 시 로그아웃
            if (
                error.response?.status === 403
            ) {
                console.log("권한 없는 토큰~!💥");
                signout();
                return;
            }


            const refreshToken = getCurrentRefreshToken();
            // config.headers.Authorization 없을 때
            // authApi.defaults.headers.common[ 'Authorization' ] = `Bearer ${getAccessToken()}`;

            const originalRequest = error.config;
            console.log("originalRequest");
            console.log(originalRequest);

            originalRequest.headers = JSON.parse(
                JSON.stringify(originalRequest.headers || {})
            );
            

            // If error, process all the requests in the queue and logout the user.
            const handleError = (error) => {
                processQueue(error);
                // logout();
                return Promise.reject(error);
            };



            if (
                refreshToken &&
                error.response?.status === 401 &&
                originalRequest?._retry !== true
            ) {

                if (isRefreshing) {
                    return new Promise(function (resolve, reject) {
                        failedQueue.push({ resolve, reject });
                        console.log("failedQueue")
                        console.log(failedQueue)
                    })
                    .then(() => {
                        return client(originalRequest);
                    })
                    .catch((err) => {
                        return Promise.reject(err);
                    });
                }
                isRefreshing = true;
                originalRequest._retry = true;

                const data = { refresh: getCurrentRefreshToken() }
                console.log('✨ get Accesstoken With Refreshtoken');
                console.log(data);

                return AuthApiService.reqAccessToken(data)
                    .then((response) => {

                        const accessToken = response.headers.authorization;
                        const refreshToken = response.headers.refresh;
                        console.log("NEW RESPONSE");
                        console.log(response)
                        console.log("NEW access token");
                        console.log(accessToken);
                        console.log("NEW refresh token");
                        console.log(refreshToken);


                        // response가 정상이라면 setToken
                        setAccessTokens(accessToken);
                        setRefreshedTokens(refreshToken);
                        
                        processQueue(null);

                        return client(originalRequest);


                    }, handleError).finally(() => {
                        isRefreshing = false;
                    });


                // return client
                //     .post(refreshTokenUrl, {
                //         refreshToken: refreshToken,
                //     })
                //     .then((res) => {
                //         const tokens = {
                //             accessToken: res.data?.accessToken,
                //             refreshToken: res.data?.refreshToken,
                //         };
                //         setRefreshedTokens(tokens);
                //         processQueue(null);

                //         return client(originalRequest);
                //     }, handleError)
                //     .finally(() => {
                //         isRefreshing = false;
                //     });
            }

            // Refresh token missing or expired => logout user...
            if (
                error.response?.status === 401 &&
                error.response?.data?.message === "TokenExpiredError"
            ) {
                return handleError(error);
            }

            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            return Promise.reject(error);
        }

            
        //     if (status === 401) {
        //         console.log("401 입니다")
        //         if (!isTokenRefreshing) {
        //             // isTokenRefreshing이 false인 경우에만 token refresh 요청
        //             isTokenRefreshing = true;

        //             //엑세스 토큰 요청 및 
        //             await getAccesstokenWithRefreshtoken();

        //             isTokenRefreshing = false;
        //         }
        //         // token이 재발급 되는 동안의 요청은 refreshSubscribers에 저장
        //         const retryOriginalRequest = new Promise((resolve) => {
        //             addRefreshSubscriber(() => {
        //                 resolve(axios(originalRequest));
        //             });
        //         });

        //         console.log("retryOriginalRequest")
        //         console.log(retryOriginalRequest)
        //         // 새로운 토큰으로 지연되었던 요청 진행
        //         onTokenRefreshed(originalRequest);

        //         return retryOriginalRequest;
        //     }

        //     return Promise.reject(error);
        // }
    );

    return client;
}