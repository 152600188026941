import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import CustomModal from "../../components/CustomModal";
import { Helmet } from "react-helmet-async";
import loadingStore from "../../store/loadingStore";

export default function Join() {
  const navigate = useNavigate();
  const { setIsLoading } = loadingStore();

  // 회원정보 input values
  const [userId, setUserId] = useState("");
  const [useableId, setUsableId] = useState(false); // 아이디 사용 가능한지
  const [userPassword, setUserPassword] = useState("");
  const [userPasswordConfirm, setUserPasswordConfirm] = useState("");
  const [userEmail, setUserEmail] = useState("");
  // 약관동의 checkbox values
  const [checkAll, setCheckAll] = useState(false);
  const [checkTerms, setCheckTerms] = useState(false);
  const [checkPrivacyPolicy, setCheckPrivacyPolicy] = useState(false);
  const [teenagerPolicy, setTeenagerPolicy] = useState(false);
  const [isCheckedAll, setIsCheckedAll] = useState(false);

  // 모달
  const [failModal, setFailModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  // 모달 open, close, confirm handler
  const handleFailModalClose = () => setFailModal(false);
  const handleFailModalShow = () => setFailModal(true);
  const handleFailModalConfirm = () => {
    handleFailModalClose();
  };

  const handleSuccessModalClose = () => setSuccessModal(false);
  const handleSuccessModalShow = () => setSuccessModal(true);
  const handleSuccessModalConfirm = () => {
    handleSuccessModalClose();
  };

  useEffect(() => {
    if (!checkTerms || !checkPrivacyPolicy || !teenagerPolicy) {
      setCheckAll(false);
    }
    if (checkTerms && checkPrivacyPolicy && teenagerPolicy) {
      setCheckAll(true);
    }

    // 회원가입 버튼 disabled 컨트롤
    if (
      userId != "" &&
      userPassword != "" &&
      userPasswordConfirm != "" &&
      userEmail != "" &&
      checkTerms &&
      checkPrivacyPolicy &&
      teenagerPolicy &&
      userPassword === userPasswordConfirm &&
      useableId
    ) {
      setIsCheckedAll(true);
    } else {
      setIsCheckedAll(false);
    }
  }, [checkAll, checkTerms, checkPrivacyPolicy, userId, userPassword, userPasswordConfirm, userEmail, teenagerPolicy, useableId]);

  // 약관동의 checkbox 동작
  function handleChange(value, setValue) {
    setValue(!value);
  }

  // 약관동의 전체 체크 동작
  function changeCheckAll() {
    if (!checkAll) {
      setCheckTerms(true);
      setCheckPrivacyPolicy(true);
      setTeenagerPolicy(true);
    } else {
      setCheckTerms(false);
      setCheckPrivacyPolicy(false);
      setTeenagerPolicy(false);
    }
  }

  // 모달
  const [modalShow, setModalShow] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  // 모달 open, close, confirm handler
  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);
  const handleModalConfirm = () => {
    navigate("/login", { replace: true });
  };

  // 모달용 메세지
  const MESSAGE = {
    success: "회원가입이 완료되었습니다.",
    successId: "사용 가능한 아이디입니다",
    error: {
      duplication: "사용중인 아이디 입니다.",
      invalidId: "올바른 아이디 형식이 아닙니다.",
      invalidPassword: "올바른 비밀번호 형식이 아닙니다.",
      invalidEmail: "올바른 이메일 형식이 아닙니다.",
    },
  };

  // 회원가입 api
  const requestSignup = async ({ userId, userPassword, userEmail }) => {
    setIsLoading(true);
    const userData = {
      username: userId,
      password: userPassword,
      email: userEmail,
    };

    console.log(userData);

    await fetch(`${import.meta.env.VITE_APP_API_SERVER_HOST}/auth/signup`, {
      method: "POST",
      body: JSON.stringify(userData),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);

        console.log(data);
        if (data.message === "SUCCESS") {
          setModalMessage(MESSAGE.success);
          handleModalShow();
        } else {
          setModalMessage(data.message);
          handleModalShow();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err.message);
      });
  };

  // 아이디 중복 확인 api
  const requestIdCheck = async (userId) => {
    if (!isValidId()) {
      return;
    }

    const userName = {
      username: userId,
    };

    console.log("서버로 보내는 userName ::");
    console.log(userName);
    await fetch(`${import.meta.env.VITE_APP_API_SERVER_HOST}/auth/valid/username`, {
      method: "POST",
      body: JSON.stringify(userName),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.result);
        if (data.result === true) {
          console.log("⭕️ 가입 가능한 아이디");
          setModalMessage(MESSAGE.successId);
          handleModalShow();
          setUsableId(true);
        } else {
          console.log("❌ 중복된 아이디");
          setModalMessage(MESSAGE.error.duplication);
          handleModalShow();
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  // 아이디, 비밀번호 valid check
  const isValidId = () => {
    const IdRegex = /^(?!(?:[0-9]+)$)([a-zA-Z]|[0-9a-zA-Z]){4,15}$/; // 아이디 : 4~15 영문, 혹은 영문+숫자 조합

    // 아이디 조건이 정규식과 일치하지 않을 때
    if (!IdRegex.test(userId)) {
      setModalMessage(MESSAGE.error.invalidId);
      handleModalShow();
      return false;
    }
    
    return true;
  };

  const isValidPassword = () => {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,16}$/; // 비밀번호 : 영문+숫자 8~16

    // 비밀번호 조건이 정규식과 일치하지 않을 때
    if (!passwordRegex.test(userPassword)) {
      setModalMessage(MESSAGE.error.invalidPassword);
      handleModalShow();
      return false;
    }
    return true;
  };

  const isValidEmail = () => {
    // const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // 이메일 형식

    // // 비밀번호 조건이 정규식과 일치하지 않을 때
    // if (!emailRegex.test(userEmail)) {
    //   setModalMessage(MESSAGE.error.invalidEmail);
    //   handleModalShow();
    //   return false;
    // }
    return true;
  };

  // 회원가입 제출 버튼 동작
  const handleSubmit = (e) => {
    e.preventDefault();

    // 아이디, 비밀번호 형식에 맞지 않을 시 Modal + error message 노출 + return
    if (!isValidId() || !isValidPassword() || !isValidEmail()) {
      return;
    }

    // 약관 미동의 시 return
    if (!isCheckedAll) {
      return;
    }

    // 회원가입 API 호출
    const data = {
      userId,
      userPassword,
      userEmail,
    };
    requestSignup(data);
  };

  return (
    <>
      <Helmet>
        <title>회원가입 - 룰루플레이TV</title>
      </Helmet>
      <div id="join">
        <div className="container">
          <div className="row gx-2 gx-sm-2 gx-md-3">
            <div className="col-12 col-md-6 col-md-4 mx-auto">
              <div className="main-title">
                <h2>룰루플레이 회원가입</h2>
                <p className="text-center">아이디와 이메일로 간편하게 룰루플레이를 시작하세요!</p>
              </div>
            </div>
          </div>
          <div className="row gx-2 gx-sm-2 gx-md-3">
            <div className="col-12 col-md-6 col-xxl-6 mx-auto">
              <form className="mt-5" onSubmit={handleSubmit}>
                <div className="input-text">
                  <label htmlFor="userId">아이디</label>
                  <div className="d-flex">
                    <input id="userId" type="text" placeholder="아이디를 입력하세요." value={userId} onChange={(e) => {
                      setUsableId(false);
                      setUserId(e.target.value)}
                      } />
                    <button type="button" className="button w-100 ml-3" disabled={useableId} onClick={() => requestIdCheck(userId)}>
                      중복확인
                    </button>
                  </div>
                  <i className="message info">영문 또는 영문, 숫자 조합 4~15 자리</i>
                </div>
                <div className="input-text">
                  <label htmlFor="userPassword">비밀번호</label>
                  <input
                    id="userPassword"
                    type="password"
                    placeholder="비밀번호를 입력하세요."
                    value={userPassword}
                    onChange={(e) => setUserPassword(e.target.value)}
                    autoComplete="new-password"
                  />
                  <i className="message info">영문, 숫자 8자~16자 사이</i>
                </div>
                <div className="input-text">
                  <label htmlFor="userPasswordCheck">비밀번호 확인</label>
                  <input
                    id="userPasswordCheck"
                    type="password"
                    placeholder="비밀번호를 한번 더 입력하세요."
                    value={userPasswordConfirm}
                    onChange={(e) => setUserPasswordConfirm(e.target.value)}
                  />
                  {userPassword !== userPasswordConfirm && <i className="message error">비밀번호가 일치하지 않습니다.</i>}
                </div>

                <div className="input-text">
                  <label htmlFor="userEmail">이메일</label>
                  <input id="userEmail" type="email" placeholder="이메일 주소를 입력하세요." value={userEmail} onChange={(e) => setUserEmail(e.target.value)} />
                </div>
                <div className="mb-40 mt-4">
                  <div className="input-checkbox mb-3">
                    <input
                      id="agreeAll"
                      type="checkbox"
                      checked={checkAll}
                      onChange={(e) => {
                        handleChange(checkAll, setCheckAll);
                        changeCheckAll();
                      }}
                    />
                    <label htmlFor="agreeAll" className="fw-700">
                      모두 동의합니다.
                    </label>
                  </div>
                  <ul>
                    <li>
                      <div className="input-checkbox mb-3">
                        <input id="agreeTerms" type="checkbox" checked={checkTerms} onChange={() => handleChange(checkTerms, setCheckTerms)} />
                        <label htmlFor="agreeTerms">
                          <Link to="/terms" target="_blank" rel="개인정보 수집 및 이용 안내">
                            회원가입 약관 (필수)
                          </Link>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="input-checkbox mb-3">
                        <input
                          id="agreePrivacy"
                          type="checkbox"
                          checked={checkPrivacyPolicy}
                          onChange={() => handleChange(checkPrivacyPolicy, setCheckPrivacyPolicy)}
                        />
                        <label htmlFor="agreePrivacy">
                          <Link to="/privacy-policy" target="_blank" rel="개인정보 수집 및 이용 안내">
                            개인정보 수집 및 이용 (필수)
                          </Link>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="input-checkbox mb-3">
                        <input
                          id="agreeTeenager"
                          type="checkbox"
                          checked={teenagerPolicy}
                          onChange={() => handleChange(teenagerPolicy, setTeenagerPolicy)}
                        />
                        <label htmlFor="agreeTeenager">
                            만 14세 이상입니다. (필수)
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
                <button disabled={!isCheckedAll} type="submit" className="button" id="submit">
                  회원가입하기
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        handleShow={modalShow}
        handleClose={handleModalClose}
        message={modalMessage}
        handleConfirm={() => {
          if (modalMessage === MESSAGE.success) {
            handleModalConfirm();
          }
          handleModalClose();
        }}
        // close={modalMessage === MESSAGE.success ? true : false}
      />

      {/* 성공 모달 */}
      <Modal show={successModal} onHide={handleSuccessModalClose} backdrop="static" keyboard={false} centered>
        <Modal.Body>
          <div className="mt-3">본인인증에 성공했습니다.</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSuccessModalConfirm} style={{ height: "56px", display: "flex", flexDirection: "column" }}>
            <span>확인</span>
          </Button>
        </Modal.Footer>
      </Modal>
      {/* 실패 모달 */}
      <Modal show={failModal} onHide={handleFailModalClose} backdrop="static" keyboard={false} centered>
        <Modal.Body>
          <div className="mt-3">본인인증에 실패했습니다. 다시 시도해주세요.</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleFailModalConfirm} style={{ height: "56px", display: "flex", flexDirection: "column" }}>
            <span>확인</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
