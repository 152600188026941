import { useState, useRef, useEffect } from 'react';
import videojs from 'video.js';
import {authStore} from '../store/authStore';

export const VideoJS = (props) => {
  const { username } = authStore();

  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const { options, onReady, qualityIndex, setQualityIndex, autoJumpTime, setAutoJumpTime, anchorsData, qualityHandler } = props;



  const [marktop, setmarktop] = useState("10%");
  const [markleft, setmarkleft] = useState("10%");


  /** 워터마크 표시를 위한 인터벌 */
  function useInterval(callback, delay) {
    const savedCallback = useRef(); // 최근에 들어온 callback을 저장할 ref를 하나 만든다.

    useEffect(() => {
      savedCallback.current = callback; // callback이 바뀔 때마다 ref를 업데이트 해준다.
    }, [callback]);

    useEffect(() => {
      function tick() {
        savedCallback.current(); // tick이 실행되면 callback 함수를 실행시킨다.
      }
      if (delay !== null) {
        // 만약 delay가 null이 아니라면
        let id = setInterval(tick, delay); // delay에 맞추어 interval을 새로 실행시킨다.
        return () => clearInterval(id); // unmount될 때 clearInterval을 해준다.
      }
    }, [delay]); // delay가 바뀔 때마다 새로 실행된다.
  }


  /** 워터마크 설정, 엘리먼트 */
  const randomNumberLeft = Math.floor(Math.random() * 90);
  const randomNumberTop = Math.floor(Math.random() * 90);
  const waterMarkElement = document.createElement("div");
  waterMarkElement.classList.add("watermark");
  waterMarkElement.textContent = username;

  /** 워터마크 생성 함수 */
  function initWaterMark () {
    if (playerRef.current.paused()) {
      return;
    }
    // 워터마크 element 초기화
    if (document.querySelector(".watermark")) {
      document.querySelector(".watermark").remove();
    }
    setmarkleft(`${randomNumberLeft}%`);
    setmarktop(`${randomNumberTop}%`);
    // 워터마크 엘리먼트 생성
    videoRef?.current?.querySelector("video-js")?.appendChild(waterMarkElement);

    waterMarkElement.style.left = markleft;
    waterMarkElement.style.top = marktop;
  }

  /** 워터마크 초기화 및 이동 (3초 마다) */
  useInterval(() => {
    initWaterMark();
  }, 3000);


  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (options.sources.length === 0) {
      return;
    }

    if (!playerRef.current) {
      // 비디오 생성
      const videoElement = document.createElement("video-js");
      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        // videojs.log("player is ready");
        onReady && onReady(player);

        // 워터마크 초기 생성
        initWaterMark();

        player.one("loadedmetadata", () => {
          let qualities = player.tech({ IWillNotUseThisInPlugins: true }).vhs.representations();
          console.log("qualities", qualities);
          createButtonsQualities({
            class: "item",
            qualities: qualities,
            father: player.controlBar.el_,
          });

          function createAutoQualityButton(params) {
            let button = document.createElement("div");

            button.id = "auto";
            button.innerText = `Auto`;

            button.classList.add("selected");

            if (params && params.class) button.classList.add(params.class);

            button.addEventListener("click", () => {
              removeSelected(params);
              button.classList.add("selected");
              qualities.map((quality) => quality.enabled(true));
            });

            return button;
          }

          function createButtonsQualities(params) {
            let contentMenu = document.createElement("div");
            let menu = document.createElement("div");
            let icon = document.createElement("div");

            let fullscreen = params.father.querySelector(".vjs-fullscreen-control");
            contentMenu.appendChild(icon);
            contentMenu.appendChild(menu);
            fullscreen.before(contentMenu);

            menu.classList.add("menu");
            icon.classList.add("icon", "vjs-icon-cog");
            contentMenu.classList.add("contentMenu");

            let autoButton = createAutoQualityButton(params);

            menu.appendChild(autoButton);

            qualities.sort((a, b) => {
              return a.height > b.height ? 1 : 0;
            });

            qualities.map((quality) => {
              let button = document.createElement("div");

              if (params && params.class) button.classList.add(params.class);

              button.id = `${quality.height}`;
              button.innerText = quality.height + "p";

              button.addEventListener("click", () => {
                resetQuality(params);
                button.classList.add("selected");
                quality.enabled(true);
              });

              menu.appendChild(button);
            });
          }

          function removeSelected(params) {
            document.querySelector("#auto").classList.remove("selected");
            [...document.querySelectorAll(`.${params.class}`)].map((quality) => {
              quality.classList.remove("selected");
            });
          }

          function resetQuality(params) {
            removeSelected(params);

            for (let quality of params.qualities) {
              quality.enabled(false);
            }
          }

          // 앵커(마커) 데이터가 있을때만 그리기
          if (anchorsData[0].time !== 0) {
            const anchors = anchorsData;
            console.log("anchors");
            console.log(anchors);

            var total = player.duration();
            var p = player.controlBar.progressControl.children_[0].el_;
            for (var i = 0; i < anchors?.length; i++) {
              var left = (anchors[i]?.time / total) * 100 + "%";

              var time = anchors[i]?.time;

              const div = document.createElement("div");
              div.setAttribute("class", "vjs-marker");
              div.style.left = left;

              div.setAttribute("data-time", time);
              const span = document.createElement("span");
              div.appendChild(span);
              span.textContent = anchors[i].label;

              const el = div;
              // var el = `<div class="vjs-marker" style="left:${left}" data-time="${time}"><span>${anchors[ i ].label}</span></div>`;
              el.addEventListener("click", function () {
                player.currentTime(el.getAttribute("data-time"));
              });

              p.append(el);
            }
          }

        });
      }));

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      console.log("else block here !!");

      const player = playerRef.current;

      player.src(options.sources);
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div data-vjs-player>
      <div ref={videoRef} style={{ height: "100%", width:"100%", aspectRatio: "16/9"}}/>
    </div>
  );
}

export default VideoJS;