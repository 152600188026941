import React, { useEffect, useState } from "react";
import VideoList from "../components/VideoList";
import { Link, useNavigate } from "react-router-dom";
import { authStore } from "../store/authStore";
import { Helmet } from "react-helmet-async";
import loadingStore from "../store/loadingStore";
import ShortFormList from "../components/ShortFormList";
// import Swiper core and required modules
import { Pagination, Scrollbar, A11y, Controller, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import ApiService from "../services/ApiService";
import Popup from "../components/Popup";
import { AspectRatio } from "@mui/joy";
import { Skeleton } from "@mui/material";

export default function Home() {
  const { isLogin } = authStore();
  const { setIsLoading } = loadingStore();
  const navigate = useNavigate();

  const [categoryList, setCategoryList] = useState([]);

  const SLIDE_PER_LOAD = 10;
  /** 모바일 (~744) 에서 한 화면에 몇개 씩 보여줄 지 */
  const SLIDE_PER_VIEW_MOBILE = 1;
  /** PC/태블릿(745~)에서 한 화면에 몇개 씩 보여줄 지 */
  const SLIDE_PER_VIEW_PC = 3;

  useEffect(() => {
    fetchCategoryList();
    fetchBannerList();
  }, []);

  const [bannerList, setBannerList] = useState([]);
  const fetchBannerList = async () => {
    ApiService.getBannerList()
      .then((response) => {
        if (response.data.message !== "SUCCESS") {
          console.log("배너 정보를 불러올 수 없습니다.");
        }
        // console.log("FETCH 배너 리스트👇");
        // console.log(response.data.result);
        setBannerList(response.data.result);
      })
      .catch((err) => {
        console.log("FETCH 배너 ERROR!👇");
        console.log(err);
      });
  };

  const fetchCategoryList = async () => {
    ApiService.getCategoryListData()
      .then((response) => {
        if (response.status !== 200) {
          console.log(`카테고리 정보를 불러올 수 없습니다. STATUS: ${response.status}`);
        }
        // console.log("FETCH 카테고리 리스트👇");
        // console.log(response.data.result);
        const result = response.data.result;

        if (result.length > 0) {
          result.map((item) => {
            fetchCategoryData(item, SLIDE_PER_LOAD);
          });
        }
      })
      .catch((err) => {
        console.log("FETCH 카테고리 ERROR!👇");
        console.log(err);
      });
  };

  /**
   * 카테고리 리스트 전체 조회 한 내용을 기반으로 카테고리 비디오 데이터를 조회함
   * @return array object 카테고리 리스트 + 카테고리 비디오 데이터 merged
   */
  const fetchCategoryData = async (categoryData, size) => {
    ApiService.getCategoryData(categoryData.id, size)
      .then((response) => {
        if (response.status !== 200) {
          console.log(`카테고리 정보를 불러올 수 없습니다. STATUS: ${response.status}`);
        }
        const result = response.data.result;
        const paging = response.data.paging;
        // console.log("카테고리 별 영상 👇");
        // console.log(result);


        /** 카테고리 리스트 + 비디오 데이터 merge */
        let newCategoryData = { ...categoryData, videoList: result, paging: paging };

        setCategoryList((prev) => [...prev, newCategoryData]);
      })
      .catch((err) => {
        console.log("FETCH 카테고리 ERROR!👇");
        console.log(err);
      });
  };

  /**
   * 카테고리 더 불러오기 업데이트 API 호출
   */
  const updateCategoryData = async (categoryId, size) => {
    console.log("update Category Data!!");

    ApiService.getCategoryData(categoryId, size)
      .then((response) => {
        if (response.status !== 200) {
          console.log(`카테고리 정보를 불러올 수 없습니다. STATUS: ${response.status}`);
        }
        const result = response.data.result;

        // console.log("카테고리 별 영상 👇");
        console.log(result);

        /** 카테고리 리스트 + 비디오 데이터 merge */
        // let newCategoryData = { ...categoryData, videoList: result };
        console.log("categoryList");
        console.log(categoryList);

        const targetIndex = categoryList.findIndex((item) => item.id === categoryId);
        let copiedCategoryList = [...categoryList];
        copiedCategoryList[targetIndex].videoList = result;

        setCategoryList(copiedCategoryList);
      })
      .catch((err) => {
        console.log("FETCH 카테고리 더 불러오기 ERROR!👇");
        console.log(err);
      });
  };

  const handleBrowseMoreSlide = (categoryId, size) => {
    console.log(`browseMoreSlide catId: ${categoryId}`);
    updateCategoryData(categoryId, size);
  };

  const handleBannerClick = (item) => {
    if (!isLogin) {
      navigate("/login", { state: { access: "denied" } });
    } else {
      /**
       * 내부 주소면 현재 창에서 열기
       * 외부 주소면 새 창에서 열기
       */
      const URL = item.url;
      const currentHost = window.location.host;

      // url 포맷이 아닐 시 링크 동작 안함
      const urlRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
      if (!urlRegex.test(URL)) {
        return;
      }

      // 내부 주소일 경우
      if (URL.includes(currentHost)) {
        const subDirectory = URL.slice(URL.indexOf(currentHost) + currentHost.length);
        console.log("subDirectory");
        console.log(subDirectory);
        navigate(subDirectory);
      } else {
        // 외부 주소일 경우
        console.log(item.url);
        window.open(`${item.url}`, "_blank");
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>룰루플레이TV</title>
      </Helmet>
      <div id="home">
        <div className="container mobile-padding-0">
          <div className="row gx-2 gx-sm-2 gx-md-3" style={{marginLeft:0, marginRight: 0}}>
            <div className="col-12 col-md-12 col-xxl-12 mx-auto" style={{padding: 0}}>
              <div className="main-banner-frame">
                {bannerList.length > 0 && bannerList ? (
                  <Swiper
                    modules={[Scrollbar, Controller, Pagination, Autoplay]}
                    allowTouchMove={true}
                    pagination={{ clickable: true }}
                    autoplay={{
                      delay: 6000,
                      disableOnInteraction: false,
                    }}
                    loop={true}
                  >
                    {bannerList?.map((item, index) => {
                      return (
                        <SwiperSlide key={`banner-${item.id}`}>
                          <span className="main-banner" onClick={() => handleBannerClick(item)}>
                            <img src={`${item?.bannerUrl}?w=1280&h=520&q=100`} alt={item?.bannerUrl ? "main-banner" : ""} />
                          </span>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                ) : (
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '16px', marginBottom: 32}}>
                    <Skeleton className="main-banner" sx={{ width: "100%", height: "100%", backgroundColor: '#212121 !important', WebkitTransform: "unset", borderRadius: 0, marginBottom: '0 !important'}}></Skeleton>
                    <Skeleton sx={{ width: "20%", height: "16px", backgroundColor: '#212121 !important', WebkitTransform: "unset"}}></Skeleton>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row gx-2 gx-sm-2 gx-md-3">
            <div className="col-12 col-md-12 col-xxl-12 mx-auto">
              <div className="video-list">
                <div className="list-wrap">
                  <ShortFormList />
                  {/* 카테고리별 영상 (시퀀스:seq 기반으로 정렬)*/}
                  {categoryList.length > 0 && categoryList ? (
                    categoryList
                      ?.sort((a, b) => a.seq - b.seq)
                      .map((categoryItem, categoryIndex) => {
                        // console.log("categoryItem");
                        // console.log(categoryItem);
                        if (categoryItem.videoList.length > 0) {
                          return (
                            <div key={`category-${categoryItem.id}`}>
                              <h3 className="video-title mt-4">
                                <Link to={`/streaming/${categoryItem.id}`} state={{ title: categoryItem.title }}>
                                  {categoryItem.title} {">"}
                                </Link>
                              </h3>
                              <div className="list-container">
                                <VideoList
                                  listData={categoryItem.videoList}
                                  categoryId={categoryItem.id}
                                  handleBrowseMoreSlide={handleBrowseMoreSlide}
                                  totalLength={categoryItem.paging.totalElements}
                                />
                              </div>
                            </div>
                          );
                        }
                      })
                  ) : (
                    // 비디오 리스트 스켈레톤 컴포넌트
                    <div>
                      <h3 className="video-title mt-4">
                        <Skeleton sx={{ width: "30%", bgcolor: "grey.900", WebkitTransform: "unset", height: "24px" }} />
                      </h3>
                      <div className="list-container">
                        <Swiper
                          slidesPerView={SLIDE_PER_VIEW_MOBILE + 0.2}
                          spaceBetween={8}
                          breakpoints={{
                            1440: {
                              slidesPerView: SLIDE_PER_VIEW_PC + 0.2,
                              spaceBetween: 16,
                            },
                            744: {
                              slidesPerView: SLIDE_PER_VIEW_PC + 0.2,
                              spaceBetween: 8,
                            },
                          }}
                        >
                          {Array.from({ length: 4 }).map((list, index) => {
                            return (
                              <SwiperSlide key={`slide-${index}`}>
                                <AspectRatio
                                  ratio="16/9"
                                  variant="plain"
                                  objectFit="cover"
                                  sx={{
                                    width: "100%",
                                  }}
                                >
                                  <Skeleton sx={{ width: "100%", height: "100px", bgcolor: "grey.900", WebkitTransform: "unset" }}></Skeleton>
                                </AspectRatio>
                                <Skeleton sx={{ width: "100%", bgcolor: "grey.900", WebkitTransform: "unset", height: "24px", marginTop: "10px" }} />
                                <Skeleton sx={{ width: "60%", bgcolor: "grey.900", WebkitTransform: "unset", height: "18px", marginTop: "10px" }} />
                              </SwiperSlide>
                            );
                          })}
                        </Swiper>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 팝업 */}
      {/* <Popup /> */}
    </>
  );
}
