import React, { useEffect, useState } from "react";
import VideoList from "../components/VideoList";
import { Link } from "react-router-dom";
import {authStore} from "../store/authStore";
import useAuth from "../Hooks/useAuth";
import { Helmet } from "react-helmet-async";
import { Button, Modal } from "react-bootstrap";
import loadingStore from "../store/loadingStore";
import { useCookies } from "react-cookie";
import AuthApiService from "../services/AuthApiService";
import useWish from "../utils/useWish";
import useCoin from "../utils/useCoin";

export default function Mypage() {
  const [myVideoList, setMyVideoList] = useState([]);
  const { username, isLogin, authDate } = authStore();
  const {wishList} = useWish();


  // 코인
  const { coin, updateCoin } = useCoin();


  const [userPassword, setUserPassword] = useState("");
  const [userPasswordConfirm, setUserPasswordConfirm] = useState("");

  const [releaseVideoList, setReleaseVideoList] = useState(null);
  const { signout } = useAuth();
  const [cookies, setCookie, removeCookie] = useCookies(["remember_user_id"]);


  // 모달
  const [resultModalShow, setResultModalShow] = useState(false);
  const handleResultModalClose = () => setResultModalShow(false);
  const handleResultModalShow = () => setResultModalShow(true);

  const [withdrawalModalShow, setWithdrawalModalShow] = useState(false);
  const [withdrawalSuccess, setWithdrawalSuccess] = useState(false);
  const handleWithdrawalModalClose = () => setWithdrawalModalShow(false);
  const handleWithdrawalModalShow = () => setWithdrawalModalShow(true);
  const [checkTerms, setCheckTerms] = useState(false);

  const handleCheckboxChange = (value, setValue) => {
    setValue(!value);
  };

  const handleLogout = () => {
    signout();
  };

  useEffect(() => {
    fetchMyVideoList();
    fetchReleaseVideoList();
    // 코인 업데이트
    updateCoin();
  }, []);

  const fetchMyVideoList = async () => {
    AuthApiService.getMyVideoList()
      .then((response) => {
        if (response.data.message !== "SUCCESS") {
          console.log("비디오 정보를 불러올 수 없습니다.");
        }
        console.log("FETCH 구매한 비디오 리스트👇");
        console.log(response.data.result);
        setMyVideoList(response.data.result);
      })
      .catch((err) => {
        console.log("FETCH 구매한 비디오 ERROR!👇");
        console.log(err.message);
      });
  };

  const fetchReleaseVideoList = async () => {
    AuthApiService.getReleaseVodList()
      .then((response) => {
        if (response.data.message !== "SUCCESS") {
          console.log("출시예정 비디오 정보를 불러올 수 없습니다.");
        }
        console.log("FETCH 출시예정 비디오 리스트👇");
        console.log(response.data.result);
        setReleaseVideoList(response.data.result);
      })
      .catch((err) => {
        console.log("FETCH 출시예정 비디오 ERROR!👇");
        console.log(err.message);
      });
  };

  const handleWithdrawalClick = () => {
    requestWithdrawal();
  };

  const handleResultClick = () => {
    if (withdrawalSuccess) {
      // 회원 탈퇴 성공 시 : 로그아웃
      handleLogout();
    } else {
      // 회원 탈퇴 오류 시 : 모달창 닫기
      handleResultModalClose();
    }
  };

  const isValid = () => {
    if (userPassword === userPasswordConfirm && userPassword.length > 0 && userPasswordConfirm.length > 0 && checkTerms) {
      return true;
    } else {
      return false;
    }
  };
  const { setIsLoading } = loadingStore();

  const requestWithdrawal = async () => {
    setIsLoading(true);

    const data = {
      password: userPassword,
    };

    AuthApiService.postWithdrawal(data)
      .then((response) => {
        setIsLoading(false);

        console.log(response);
        if (response.data.message !== "SUCCESS") {
          console.log("탈퇴 요청을 처리할 수 없습니다.");
        } else {
          console.log("탈퇴 요청 성공👇");
          console.log(response.data.result);
          setWithdrawalSuccess(true);

          // 쿠키 삭제
          if (cookies.remember_user_id && cookies.remember_user_id === "undefined") {
            removeCookie("remember_user_id");
          }

          // 현재 모달 닫고
          handleWithdrawalModalClose();
          // 결과 모달 열고
          handleResultModalShow();
        }
      })
      .catch((err) => {
        setIsLoading(false);

        console.log("탈퇴 요청 ERROR!👇");
        alert(err.response.data.message);
      });
  };

  return (
    <>
      <Helmet>
        <title>마이페이지 - 룰루플레이TV</title>
      </Helmet>
      <div id="mypage">
        <div className="container">
          <div className="row gx-2 gx-sm-2 gx-md-3">
            <div className="col-12 col-md-12 col-xxl-12 mx-auto">
              <h3 className="mt-5">마이페이지</h3>
              <div className="my-info">
                {/* <div className="info-left">
                  <span>
                    <img src={Img_profileDefault} alt="default" />
                  </span>
                  <button className="button sm">변경하기</button>
                </div> */}
                <div className="info-right">
                  <ul>
                    <li>
                      <p>아이디</p>
                      <div>
                        <span className="mr-2">{username}</span>
                      </div>
                    </li>
                    <li>
                      <p>성인인증</p>
                      <div>
                        <span className={authDate ? "mr-2" : null}>
                          {authDate && "인증완료: "} {authDate}
                        </span>
                        {!authDate && (
                          <Link to="/authentication-request" className="button sm">
                            성인인증하기
                          </Link>
                        )
                        }
                      </div>
                    </li>
                    {/* <li>
                      <p>이메일</p>
                      <div>
                        <span>이메일 정보 없음</span>
                      </div>
                    </li> */}
                    <li>
                      <p>코인</p>
                      <div>
                        <span>{coin}</span>
                        <Link to="/coin-history" className="button sm ml-2">
                          충전/사용내역
                        </Link>
                      </div>
                    </li>
                    <li>
                      <p>비밀번호 변경</p>
                      <div>
                        <Link to="/change-password" className="button sm">
                          비밀번호 변경하기
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="wish-list">
                <h4>내 영상</h4>
                <ul className="nav nav-pills mb-4" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="my-list-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#my-list"
                      type="button"
                      role="tab"
                      aria-controls="my-list"
                      aria-selected="false"
                    >
                      대여한 작품
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="wish-list-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#wish-list"
                      type="button"
                      role="tab"
                      aria-controls="wish-list"
                      aria-selected="true"
                    >
                      찜한 작품
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  {/* 대여한 작품 */}
                  <div className="tab-pane fade show active" id="my-list" role="tabpanel" aria-labelledby="my-list-tab">
                    {myVideoList?.length > 0 ? (
                      <VideoList listData={myVideoList} mypage />
                    ) : (
                      <p className="text-center my-5 text-gray">대여한 작품이 없습니다.</p>
                    )}
                  </div>
                  {/* 찜한 작품 */}
                  <div className="tab-pane fade" id="wish-list" role="tabpanel" aria-labelledby="wish-list-tab">
                    {wishList?.length > 0 ? (
                      <VideoList listData={wishList} releaseData={releaseVideoList && releaseVideoList} mypage />
                    ) : (
                      <p className="text-center my-5 text-gray">찜한 작품이 없습니다.</p>
                    )}
                  </div>
                </div>

                <div className="withdrawal mt-5">
                  <button onClick={handleLogout}>로그아웃</button>
                  <button onClick={handleWithdrawalModalShow}>회원탈퇴</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 회원탈퇴 모달 */}
      <Modal show={withdrawalModalShow} onHide={handleWithdrawalModalClose} backdrop="static" keyboard={false} centered className="modal-lg-custom">
        <Modal.Body>
          <div className="mt-3 text-left">
            <h5>회원 탈퇴 안내</h5>
          </div>
          <ul className="mt-3 px-2 text-left" style={{ wordBreak: "keep-all" }}>
            <li className="mb-3">· 회원탈퇴 시 보유중인 코인, 대여한 작품, 찜한 상품의 데이터는 삭제되어 복구 불가능 합니다.</li>
            <li className="mb-3">· 탈퇴 즉시 어떠한 방법으로도 회원계정은 복원할 수 없습니다.</li>
            <li className="mb-3">· 회원 탈퇴 후 코인 충전 및 거래 정보는 5년간 분리 보관됩니다.</li>
          </ul>

          <div className="input-text text-left">
            <label htmlFor="userPassword">비밀번호</label>
            <input
              id="userPassword"
              type="password"
              placeholder="비밀번호를 입력하세요."
              value={userPassword}
              onChange={(e) => setUserPassword(e.target.value)}
              autoComplete="new-password"
            />
            <i className="message info">영문, 숫자 8자~16자 사이</i>
          </div>
          <div className="input-text text-left">
            <label htmlFor="userPasswordCheck">비밀번호 확인</label>
            <input
              id="userPasswordCheck"
              type="password"
              placeholder="비밀번호를 한번 더 입력하세요."
              value={userPasswordConfirm}
              onChange={(e) => setUserPasswordConfirm(e.target.value)}
            />
            {userPassword !== userPasswordConfirm && <i className="message error">비밀번호가 일치하지 않습니다.</i>}
          </div>

          <div className="input-checkbox mb-2 text-left">
            <input id="agreeTerms" type="checkbox" checked={checkTerms} onChange={() => handleCheckboxChange(checkTerms, setCheckTerms)} />
            <label htmlFor="agreeTerms" className="text-red">
              위 안내사항을 모두 확인하였고, 회원탈퇴에 동의합니다.
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleWithdrawalModalClose} style={{ height: "56px" }}>
            취소
          </Button>
          <Button
            variant="primary"
            style={{
              height: "56px",
              display: "flex",
              flexDirection: "column",
            }}
            onClick={handleWithdrawalClick}
            disabled={!isValid()}
          >
            <span>회원탈퇴하기</span>
          </Button>
        </Modal.Footer>
      </Modal>

      {/* 회원탈퇴 결과 모달 */}
      <Modal show={resultModalShow} onHide={handleResultModalClose} backdrop="static" keyboard={false} centered>
        <Modal.Body>
          <div className="mt-3">{withdrawalSuccess ? "회원 탈퇴가 완료되었습니다." : "회원 탈퇴에 실패했습니다. 다시 시도 해 주세요."}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            style={{
              height: "56px",
              display: "flex",
              flexDirection: "column",
            }}
            onClick={handleResultClick}
          >
            <span>닫기</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
