import AuthApiService from "../services/AuthApiService";
import { useQuery } from "@tanstack/react-query";
import { authStore } from "../store/authStore";

const useWish = () => {
  const { isLogin, username } = authStore();

  const fetchWishList = async () => {
    console.log("🚀 FETCH 찜목록 🚀");
    return AuthApiService.getWishVideoList();
  };

  const { data, refetch: updateWishList } = useQuery({
    queryKey: ["wishlist", username],
    queryFn: fetchWishList,
    staleTime: Infinity,
    enabled: !!isLogin,
  });

  const wishList = data?.data.result;

  const isWishVideo = (videoListId) => {
    return wishList?.some((item) => item.video?.id === videoListId);
  };

  /**
   * **[설명]** : 찜 하기 / 찜 취소 요청
   * @param {string} listId 찜 하기: video.uuid 또는 찜취소: wishId
   * @param {string} method 찜 하기: "POST" 또는 찜 취소: "DELETE"
   */
  const requestWishItem = async (listId, method) => {
    AuthApiService.modifyWishItem(listId, method)
      .then((response) => {
        console.log("response");
        console.log(response);
        if (response.status === 200) {
          if (response.config.method.toUpperCase() === "DELETE") {
            console.log("찜 취소");
          } else {
            console.log("찜 하기");
          }
          // 찜 리스트 다시 불러오기
          updateWishList();
        }
      })
      .catch((err) => {
        console.log("FETCH 찜 ERROR!👇");
        console.log(err.message);
      });
  };

  return { wishList, updateWishList, requestWishItem, isWishVideo };
};

export default useWish;
