import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./styles/reset.css";
import "./styles/bootstrap.css";
import "./styles/global.css";
import "./styles/pages.css";
import "video.js/dist/video-js.css";

import * as Sentry from "@sentry/react";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";


Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  debug: false,
  integrations: [
    Sentry.browserTracingIntegration({
      tracePropagationTargets:
        import.meta.VITE_SENTRY_ENVIRONMENT === "development" ? [/^https:\/\/client-dev\.luluplaytv\.com\//] : [/^https:\/\/luluplaytv\.com\//],
    }),
    Sentry.replayIntegration(),
    Sentry.breadcrumbsIntegration({
      console: import.meta.VITE_SENTRY_ENVIRONMENT === "development" ? false : true,
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1,
  tracePropagationTargets: import.meta.VITE_SENTRY_ENVIRONMENT === "development" ? [/^https:\/\/client-dev\.luluplaytv\.com\//] : ["localhost",/^https:\/\/luluplaytv\.com\//],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});


// React-Devtool 비활성화
if (import.meta.env.VITE_ENVIRONMENT === "production") {
    disableReactDevTools();
}

ReactDOM.createRoot(document.getElementById('root')).render(
    <App />
)
