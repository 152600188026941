import React from 'react'
import loadingStore from '../store/loadingStore';
import {authStore} from '../store/authStore';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Cookies from 'universal-cookie';
import getUserAgeByJwt from '../utils/getUserAgeByJwt';
import moment from 'moment';
import * as Sentry from "@sentry/react";


export default function useAuth() {
    const { setIsLoading } = loadingStore();
    const { setIsLogin, setAccessToken, setRefreshToken, setUserName, setUserAge, setAuthDate } = authStore();
    const cookies = new Cookies();
    const navigate = useNavigate();

    const signin = async({userId, userPassword}, isRemember) => {

        setIsLoading(true);
        const userData = {
            username: userId,
            password: userPassword,
        };

        await axios.post(`${import.meta.env.VITE_APP_API_SERVER_HOST}/auth/signin`, userData)
            .then((response) => {
                setIsLoading(false);

                console.log("SIGN IN :::: response");
                console.log(response);
                const accessToken = response.headers.authorization;
                const refreshToken = response.headers.refresh;
                const userAge = getUserAgeByJwt(accessToken) || null;
                
                setIsLogin(true);
                setUserName(userId);
                setAccessToken(accessToken);
                setRefreshToken(refreshToken);
                setUserAge(userAge);
                // send to sentry (userid)
                Sentry.setUser({ username: userId });

                const authDate = response.data.result.selfAuthDate;
                const formattedAuthDate = moment(authDate).add(9, 'h').format('YYYY-MM-DD');

                if (authDate) {
                    setAuthDate(formattedAuthDate)
                }

                
                navigate("/home", { replace: true });

                if (isRemember) {
                    // setCookie("remember_user_id", userId);
                    cookies.set('remember_user_id', userId);
                } else {
                    cookies.remove('remember_user_id');
                }
            })
            .catch(function (error) {
                setIsLoading(false);

                cookies.remove('remember_user_id');


                console.log(error);
                // 에러메세지 얼럿
                if(error.response) {
                    alert(error?.response?.data?.message);
                }
            });
    };

    const signout = () => {
        setIsLogin(false);
        setUserName('');
        setAccessToken('');
        setRefreshToken('');
        setAuthDate(null);
        window.localStorage.clear()
        navigate("/login", { replace: true });
    }
    
    const signoutOnce = () => {
        setIsLogin(false);
        setUserName('');
        setAccessToken('');
        setRefreshToken('');
        window.localStorage.clear()
    }


    return { signin, signout, signoutOnce }
}
