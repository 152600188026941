import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import loadingStore from "../store/loadingStore";
import {authStore} from "../store/authStore";
import { Helmet } from "react-helmet-async";
import AuthApiService from "../services/AuthApiService";

export default function PaymentsCancel() {
  const params = useParams();
  const location = useLocation();
  
  console.log(location.state);
  const navigate = useNavigate();

  // 이전페이지에서 전달받은 선택한 주문 정보(uuid)
  const uuid = location?.state?.uuid || null;

  const [title, setTitle] = useState("");
  const [price, setPrice] = useState(0);
  const [fee, setFee] = useState(0);
  const [cancelPrice, setCancelPrice] = useState(0);

  useEffect(() => {
    // if (!location.state) {
    //   alert('잘못된 접근입니다. 다시 시도해주세요.');
    //   navigate('/payments');
    //   return;
    // }

    requestTicketCancel(uuid);
    
  }, []);
  
  const requestTicketCancel = (uuid) => {
    
    // TODO: uuid로 취소 요청서 받기
    setTitle("상품명");
    setPrice(20000);
    setFee(5000);
    setCancelPrice(10000);

  }


  const handleSubmit = (e) => {
    e.preventDefault();

    if (isMobile) {
      window.location.href = `${import.meta.env.VITE_APP_API_SERVER_HOST}/payment/mobile?uuid=${params.uuid}`;
    } else {
      window.location.href = `${import.meta.env.VITE_APP_API_SERVER_HOST}/payment/pc?uuid=${params.uuid}`;
    }
  };

  // 모달
  const [failModal, setFailModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  // 모달 open, close, confirm handler
  const handleFailModalClose = () => setFailModal(false);
  const handleFailModalShow = () => setFailModal(true);
  const handleFailModalConfirm = () => {
    // navigate(-1);
    handleFailModalClose();
  };

  const handleSuccessModalClose = () => setSuccessModal(false);
  const handleSuccessModalShow = () => setSuccessModal(true);
  const handleSuccessModalConfirm = () => {
    navigate(-2);
    handleSuccessModalClose();
  };

  return (
    <>
      <Helmet>
        <title>주문서 정보 - 룰루플레이TV</title>
      </Helmet>
      <div id="payments-detail">
        <h3 className="text-center pt-5">결제 취소</h3>
        <div className="container">
          <div className="row gx-2 gx-sm-2 gx-md-3">
            <div className="col-12 col-md-6 col-md-4 mx-auto">
              <p className="text-center mb-2 text-gray">하단의 예매 취소정보를 확인 후 취소를 진행해주세요.</p>
              <p className="text-center mb-5 text-gray" style={{wordBreak: "keep-all"}}>환불 및 취소는 카드사의 정책에 따라 평일(은행영업일) 기준 3~5일 이상 소요될 수 있습니다.</p>
              <form name="fm" className="table">
                <ul>
                  <li>
                    <p>상품명</p>
                    <span>{title && title}</span>
                  </li>
                  <li>
                    <p>결제 금액</p>
                    <span>{price && price?.toLocaleString()}원</span>
                  </li>
                  <li>
                    <p>취소 수수료</p>
                    <span>(-){fee && fee?.toLocaleString()}원</span>
                  </li>
                  <li>
                    <p>총 반환 금액</p>
                    <span>{cancelPrice && cancelPrice?.toLocaleString()}원</span>
                  </li>
                </ul>
                <button className="button mt-5" type="button" onClick={handleSubmit}>
                  취소 요청
                </button>
              </form>
            </div>
          </div>
        </div>

        {/* 성공 모달 */}
        <Modal show={successModal} onHide={handleSuccessModalClose} backdrop="static" keyboard={false} centered>
          <Modal.Body>
            <div className="mt-3">취소 요청이 완료되었습니다.</div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={handleSuccessModalConfirm}
              style={{
                height: "56px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span>확인</span>
            </Button>
          </Modal.Footer>
        </Modal>
        {/* 실패 모달 */}
        <Modal show={failModal} onHide={handleFailModalClose} backdrop="static" keyboard={false} centered>
          <Modal.Body>
            <div className="mt-3">취소 요청에 실패했습니다. 다시 시도해주세요.</div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={handleFailModalConfirm}
              style={{
                height: "56px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span>확인</span>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
