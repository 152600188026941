import React from "react";
import Icon_add from "../assets/images/icons/add.svg";
import Icon_add_active from "../assets/images/icons/add-active.svg";
import { zeroPad } from "react-countdown";
import moment from "moment/moment";
import { authStore } from "../store/authStore";
import { Helmet } from "react-helmet-async";
import AuthApiService from "../services/AuthApiService";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Skeleton } from "@mui/material";
import { AspectRatio } from "@mui/joy";
import useWish from "../utils/useWish";

export default function Release() {

  const { userAge } = authStore();
  const { wishList, requestWishItem, isWishVideo } = useWish();


  const queryClient = useQueryClient();
  const updateWishList = () => {
    queryClient.invalidateQueries(["wishlist"]);
  };

  // 찜
  function handleWishClick(list) {
    const uuid = list?.video ? list?.video?.uuid : list?.uuid;
    const id = list?.video ? list?.video?.id : list?.id;

    // 찜한 비디오 리스트 에서, 클릭한 video id와 일치하는 항목의 찜 비디오 ID를 발굴
    const targetWishVideoListItem = wishList?.filter((listItem) => listItem?.video?.id === id);
    // 현재 클릭한 비디오가 찜한 비디오면 -> 찜 취소 / 찜한 비디오가 아니면 -> 찜 요청
    if (isWishVideo(id)) {
      console.log("asdfjkl");
      requestWishItem(targetWishVideoListItem[0].id, "DELETE");
      return;
    }
    requestWishItem(uuid, "POST", updateWishList);
  }

  // useEffect(() => {
  //   fetchVideoList();
  // }, []);
  // 출시예정 비디오 불러오기
  const fetchVideoList = async () => {
    console.log("🚀 FETCH 출시예정 리스트 🚀");
    return AuthApiService.getReleaseVodList();
  };


  const { data: response } = useQuery(["release-list"], fetchVideoList);
  const releaseVideoList = response?.data?.result || null;


  function secondsToMinutes(seconds) {
    const result = new Date(seconds * 1000).toISOString().slice(11, 19);
    return result;
  }

  // // 출시예정 비디오 불러오기
  // const fetchVideoList = async () => {
  //   AuthApiService.getReleaseVodList()
  //     .then((response) => {
  //       if (response.data.message !== "SUCCESS") {
  //         console.log("출시예정 비디오 정보를 불러올 수 없습니다.");
  //       }
  //       console.log("FETCH 출시예정 비디오 리스트👇");
  //       console.log(response.data.result);
  //       setReleaseVideoList(response.data.result);
  //     })
  //     .catch((err) => {
  //       console.log("FETCH 출시예정 비디오 ERROR!👇");
  //       console.log(err.message);
  //     });
  // };

  function calculateDate(releaseTime) {
    const releaseDate = moment(releaseTime).add(9, "hours").valueOf();
    const today = moment().valueOf();
    const result = releaseDate - today;

    return Date.now() + result;
  }

  const renderer = ({ days, hours, minutes, seconds }) => (
    <span>
      {zeroPad(days)}일&nbsp;
      {zeroPad(hours)}시&nbsp;{zeroPad(minutes)}분&nbsp;{zeroPad(seconds)}초
    </span>
  );
  return (
    <>
      <Helmet>
        <title>출시예정 - 룰루플레이TV</title>
      </Helmet>
      <div id="release">
        {releaseVideoList?.length > 0 && releaseVideoList ? (
          releaseVideoList?.map((item, index) => {
            return (
              <div className="release-list" key={index + "-key"}>
                <div className="main-banner">
                  <img
                    src={item.thumbnailUrl}
                    alt="sample-main-banner"
                    style={{ opacity: 0.7 }}
                    className={userAge !== "ADULT" && item?.servicePolicyAgeLevel === "ADULT" ? `thumbnailBlur` : ""}
                  />
                  <div className="release-wrap">
                    <div className="container" style={{ height: "100%" }}>
                      <div className="row gx-2 gx-sm-2 gx-md-3">
                        <div className="col-12 col-md-12 col-xxl-12 mx-auto">
                          <div className="release-info">
                            <h4>
                              {/* 출시까지{" "} */}
                              <strong>
                                {
                                  // <Countdown
                                  //   date={calculateDate(item.launchDateTime)}
                                  //   renderer={renderer}
                                  // />
                                  moment(item.launchDateTime).format("YY년 M월")
                                }
                              </strong>{" "}
                              출시예정
                            </h4>
                            <button
                              className={isWishVideo(item.id) ? "add active" : "add"}
                              onClick={() => {
                                handleWishClick(item);
                              }}
                            >
                              <img src={isWishVideo(item.id) ? Icon_add_active : Icon_add} alt="add" />
                              <span>찜하기</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : releaseVideoList?.length === 0 && releaseVideoList ? (
          <div className="text-gray text-center" style={{ padding: "240px 0 120px 0" }}>
            ⏳ 빠르게 영상을 추가할 예정입니다 ⌛️
          </div>
        ) : (
          <li style={{ display: "flex", flexDirection: "column" }}>
            <AspectRatio
              ratio="16/9"
              variant="plain"
              objectFit="cover"
              sx={{
                width: "100%",
              }}
            >
              <Skeleton sx={{ width: "100%", height: "100px", bgcolor: "grey.900", WebkitTransform: "unset" }}></Skeleton>
            </AspectRatio>
          </li>
        )}
      </div>
    </>
  );
}

