import React, { useCallback, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ShortFormPlayer from "./ShortFormPlayer";
import videojs from "video.js";
import { useState, useEffect } from "react";
// import Swiper core and required modules
import { Scrollbar, Controller } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { isIOS, isMobile } from "react-device-detect";
import { authStore } from "../store/authStore";
import AuthApiService from "../services/AuthApiService";
import { Helmet } from "react-helmet-async";

export default function ShortFormDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const { userAge } = authStore();
  const BUNCH = location?.state?.BUNCH;
  const totalElements = location?.state?.totalElements;
  const [shortFormData, setShortFormData] = useState(location?.state?.data || []);

  // 현재 선택된 슬라이드 index
  const [currentSlideIndex, setCurrentSlideIndex] = useState(location?.state?.selectedIndex || 0);

  const playerRef = React.useRef(null);
  const swiperRef = useRef();

  const updateShortFormList = async (size) => {
    AuthApiService.getShortFormListWithPlayUrl(size)
      .then((response) => {
        if (response.data.message !== "SUCCESS") {
          console.log("숏폼숏폼 정보를 불러올 수 없습니다.");
        }

        console.log("FETCH 숏폼숏폼 리스트👇");
        console.log(response.data.result);

        setShortFormData(response.data.result);
      })
      .catch((err) => {
        console.log("FETCH 숏폼숏폼 ERROR!👇");
        console.log(err);
      });
  };

  const handleBrowseMoreList = (size) => {
    updateShortFormList(size);
  };

  useEffect(() => {
    if (shortFormData?.length === 0) {
      navigate("/home");
    }

    
    console.log("location?.state?.data");
    console.log(location?.state?.data);
    setShortFormData(location?.state?.data);

    /** 슬라이드 초기화 페이지 설정 */
    swiperRef.current.slideTo(location?.state?.selectedIndex);
  }, []);

  // const [posterUrl,setPosterUrl] = useState("");

  /** 숏폼 VideoJS 옵션 설정 */
  const videoJsOptions = {
    autoplay: false,
    playsinline: true,
    controls: false,
    loop: true,
    userActions: {
      doubleClick: false,
      click: false,
    },
    html5: {
      nativeTextTracks: false,
      nativeAudioTracks: false,
      nativeVideoTracks: false,
      vhs: {
        overrideNative: true,
      },
    },
    preload: "none", // safari에서 poster 사용하려면 preload를 none으로 지정해야 한다고 함.
    fluid: false,
    sources: [
      {
        // src: "http://content.jwplatform.com/manifests/yp34SRmf.m3u8",
        src: shortFormData?.length > 0 && shortFormData[currentSlideIndex]?.playUrl,
        type: "application/x-mpegURL",
        withCredentials: true,
      },
    ],
  };

  /** videoJS player Controller */
  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on("waiting", () => {
      // videojs.log("player is waiting");
    });
    player.on("dispose", () => {
      // videojs.log("player will dispose");
    });
    player.on("error", function () {
      console.log("💥player.error ");
      console.log(player.error());
      // navigate(-1);
    });
  };

  const handleSlideChange = (swiper) => {
    const { activeIndex, previousIndex } = swiper;
    const currentLastIndex = shortFormData.length - 1;

    /** 슬라이드가 변경될 때 활성화된 index를 넘겨준다. */
    setCurrentSlideIndex(swiper.activeIndex);

    // 모든 숏폼 영상 불러오면 아무것도 안함
    if (currentLastIndex === totalElements - 1) {
      return;
    }

    if (previousIndex < activeIndex) {
      // console.log("next!");

      if (activeIndex === currentLastIndex) {
        console.log("데이터 업데이트!");
        handleBrowseMoreList(currentLastIndex + BUNCH);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>숏폼 - 룰루플레이TV</title>
        <style>
          {`
              html, body {
                  margin: 0; height: 100%; overflow: hidden
            }
          `}
        </style>
      </Helmet>
      <div className="shortform-detail-swiper" style={{ backgroundColor: "#000" }}>
        <Swiper
          modules={[Scrollbar, Controller]}
          preventInteractionOnTransition
          resistance={true}
          direction="vertical"
          speed={600}
          allowTouchMove={true}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          onSlideChange={(swiper) => {
            handleSlideChange(swiper);
          }}
        >
          {shortFormData?.map((item, index) => {
            const { title, servicePolicyAgeLevel, uuid } = item;
            return (
              <SwiperSlide key={uuid}>
                  <ShortFormPlayer
                    options={videoJsOptions}
                    onReady={handlePlayerReady}
                    swiperRef={swiperRef}
                    currentSlideIndex={currentSlideIndex}
                    totalSlideLength={shortFormData.length}
                    accessDenied={userAge !== "ADULT" && servicePolicyAgeLevel === "ADULT"}
                    index={index}
                    title={title}
                    shortFormData={shortFormData}
                  />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
}
