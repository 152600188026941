import React, { useCallback, useEffect, useState } from "react";
import {
  useParams,
  useNavigate,
  Link,
} from "react-router-dom";
import AppLoader from 'tui-app-loader';

import Icon_clock from "../assets/images/icons/clock.svg";
import Icon_coin from "../assets/images/icons/coin.svg";
import Icon_coin_white from "../assets/images/icons/coin-white.svg";
import Icon_play from "../assets/images/icons/play.svg";
import Icon_add from "../assets/images/icons/add.svg";
import Icon_add_active from "../assets/images/icons/add-active.svg";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import VideoJsPlayer from "../components/VideoJsPlayer";
import loadingStore from "../store/loadingStore";
import { authStore } from "../store/authStore";
import { Helmet } from "react-helmet-async";
import { checkAvailableForPurchase } from "../utils/coinCheck";
import AuthApiService from "../services/AuthApiService";
import VideoList from "../components/VideoList";
import ApiService from "../services/ApiService";
import { AspectRatio } from "@mui/joy";
import { Skeleton } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import is from "is_js";
import { browserName, browserVersion, deviceDetect } from "react-device-detect";
import * as Sentry from "@sentry/react";
import { isAndroid, isIOS } from 'react-device-detect';
import { isSamsungBrowser, isIpad } from '../utils/checkBrowser';
import useWish from "../utils/useWish";
import useCoin from "../utils/useCoin";


export default function VideoDetail() {
  const {wishList, requestWishItem, isWishVideo} = useWish();

  const PLAYER_NOTICE_LINK_DEV = "https://client-dev.luluplaytv.com/notice/281";
  const PLAYER_NOTICE_LINK_PROD = "https://luluplaytv.com/notice/7";
  /**
   * 이전 페이지의 location.state로 넘겨받은 정보로 데이터 그리고/ 배우 정보, 카테고리 정보는 uuid로 단건조회 함.
   */
  const { setIsLoading } = loadingStore();
  const params = useParams();
  const navigate = useNavigate();
  const { refreshToken, setAccessToken, setRefreshToken, setUserAge, setAuthDate } = authStore();

  const [videoUrls, setVideoUrls] = useState([]);
  const [videoData, setVideoData] = useState({});
  const [isDrm, setIsDrm] = useState(null);

  const { username } = authStore();
  const [canWatchable, setCanWatchable] = useState(null);

  // 코인
  const { coin, updateCoin } = useCoin();



  // 비디오 단건 조회에 붙은 카테고리 -> 해당 카테고리로 조회 -> 데이터 담기
  const [categoryList, setCategoryList] = useState([]);
  const [categoryVideoData, setCategoryVideoData] = useState([]);



  const [selectedIndex, setSelectedIndex] = useState(2); // 0: SD , 1: HD, 2: HFD
  // 모달
  const [buyModalShow, setBuyModalShow] = useState(false);
  const [chargeModalShow, setChargeModalShow] = useState(false);
  const [resultModalShow, setResultModalShow] = useState(false);
  const [buyResult, setBuyResult] = useState(false);

  // 모달 open, close, confirm handler
  const handleBuyModalClose = () => setBuyModalShow(false);
  const handleBuyModalShow = () => setBuyModalShow(true);
  const handleBuyModalConfirm = () => {
    updateCoin();

    // 잔여 금액이 구매 금액보다 크면 구매, 아니면 새 팝업 띄우기
    if (checkAvailableForPurchase(coin, videoData?.coin)) {
      requestBuyVideo(videoData?.uuid);
    } else {
      handleBuyModalClose();
      handleChargeModalShow();
    }
  };

  useEffect(() => {
    // 카테고리 리스트 초기화
    setCategoryList([]);
    setVideoUrls([]);


    fetchVideoData(params.uuid);
    console.log("params.uuid");
    console.log(params.uuid);
  }, [params.uuid]);



  const [recommendToUpdate, setRecommendToUpdate] = useState(false);
  const [deprecatedBrowser, setDeprecatedBrowser] = useState(false);

  useEffect(() => {

    // 권장 브라우저인가 ? 브라우저 버전 체크 하기 : 권장 브라우저 사용 안내하기
    if (checkUserBrowser()) {
      if (!checkUserBrowserVersion()) {
        setRecommendToUpdate(true);
      }
    } else {
      setDeprecatedBrowser(true);
    }

  }, [])

  const checkUserBrowser = () => {
    if (browserName === "Mobile Safari" || browserName === "Chrome" || browserName === "Edge" || browserName === "Safari") {
      console.log("브라우저 OK ✅✅✅");
      return true;
    } else {
      return false;
    }

  }


  const checkUserBrowserVersion = () => {

    console.log("is.VERSION");
    console.log("browserName : ", browserName);
    console.log("browserVersion : ", browserVersion);
    console.log("deviceDetect : ", deviceDetect);
    if (is.chrome('>=110') || is.safari('>=13') || is.edge('>=110')) {
      console.log("브라우저 버전 OK ✅✅✅");
      return true;
    } else {
      return false;
    }

  }

  const handlePlayClick = () => {
    updateCoin();

    if (coin - videoData.coin < 0) {
      handleChargeModalShow();
    } else {
      handleBuyModalShow();
    }
  }

  const handleChargeModalClose = () => setChargeModalShow(false);
  const handleChargeModalShow = () => setChargeModalShow(true);
  const handleChargeModalConfirm = () => {
    navigate("/payments");
  };

  const handleResultModalClose = () => setResultModalShow(false);
  const handleResultModalShow = () => setResultModalShow(true);

  /** 서버에서 받아온 영상 정보 중 플레이타임 시간 format 변환 */
  function secondsToMinutes(seconds) {
    if (!seconds) {
      return;
    }
    const result = new Date(seconds * 1000).toISOString().slice(11, 19);
    return result;
  }
  /** dateTime format에서 시간 삭제 */
  function removeTimes(date) {
    /**
     * [Before]
     * YYYY-MM-DD hh:mm:ss
     *
     * [After]
     * YYYY-MM-DD
     */
    //
    const result = date?.slice(0, 11);
    return result;
  }

  function isDrmPlayUrl(playUrl) {
    return playUrl.includes('kollus');
  }

  /** 영상 구매 API 호출 */
  const requestBuyVideo = async (uuid) => {
    setIsLoading(true);
    console.log(uuid);
    AuthApiService.postOrderVod(uuid)
      .then((response) => {
        setIsLoading(false);
        if (response.data.message !== "SUCCESS") {
          updateCoin();

          console.log("비디오를 구매할 수 없습니다.");
          handleBuyModalClose();
          handleResultModalShow();
        } else {
          console.log("FETCH 비디오 성공👇");
          console.log(response.data.result);
          // 코인 업데이트
          updateCoin();

          setBuyResult(true);
          handleBuyModalClose();
          handleResultModalShow();
          // setCanWatchable(true);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        // 코인 수량 부족일 때
        if (error.response.data.code === "5298") {
          handleBuyModalClose();
          handleChargeModalShow();
        }
        console.log("FETCH 비디오 ERROR!👇");
        console.log(error.message);
      });
  };

  /** 영상 시청 정보 API 호출 (구매한 + 시청 가능한 비디오인지 확인) */
  const requestVideoCanWatchable = async (uuid) => {
    setIsLoading(true);
    console.log(uuid);
    AuthApiService.getVideoCanWatchable(uuid)
      .then((response) => {
        console.log(response);
        setIsLoading(false);

        if (response.status === 200) {
          console.log("⭕️ 시청 가능한(구매한) 비디오입니다.");
          console.log(response.data.result);

          const playUrl = response.data.result.playUrl;

          setCanWatchable(true);
          // accesstoken 업데이트 후 셋팅
          setVideoUrls(playUrl);

          // drm 여부에 따라 설정
          if (isDrmPlayUrl(playUrl)) {
            setIsDrm(true);
            setVideoUrls(playUrl);


            callPlayer(encodeUriScheme(playUrl)); // drm player 재생

          } else {
            setIsDrm(false);
            setVideoUrls(playUrl);

            const parsedAnchorsData = JSON.parse(response.data.result.anchors);
            setAnchors(parsedAnchorsData);
          }

        }
      })
      .catch((err) => {
        setIsLoading(false);

        if (err?.response?.status === 400) {
          console.log("❌ 구매한 비디오가 아닙니다.");
          // handleBuyModalShow();
          setCanWatchable(false);
        } else {
          console.log("FETCH 비디오 ERROR!👇");
          console.log(err);
        }
      });
  };


  /** uuid 기반 비디오 단건조회 */
  const fetchVideoData = async (uuid) => {
    console.log(uuid);
    AuthApiService.getVideoData(uuid)
      .then((response) => {
        console.log(response);
        setIsLoading(false);

        if (response.status === 200) {
          console.log("비디오 단건조회 👇");
          console.log(response.data.result);
          const result = response.data.result;
          setVideoData(result);

          console.log(result);
          console.log("result.categories ❌");
          console.log(result.categories);

          if (result.uuid) {
            requestVideoCanWatchable(result.uuid);
          }
          if (result.categories.length > 0) {
            result.categories.map((item) => {
              fetchCategoryData(item, uuid);
            });
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);

        console.log("FETCH 비디오 ERROR!👇");
        console.log(err.message);
        if (err?.response?.status === 400) {
          alert(err.response.data.message);
          navigate("/authentication-request", { replace: true });
        }
        // alert("오류가 발생했습니다. 고객센터에 문의해주세요.");
      });
  };

  /**
   * 카테고리 리스트 전체 조회 한 내용을 기반으로 카테고리 비디오 데이터를 조회함
   * @return array object 카테고리 리스트 + 카테고리 비디오 데이터 merged
   */
  const fetchCategoryData = async (categoryData, uuid) => {
    ApiService.getCategoryData(categoryData.id, 24)
      .then((response) => {
        if (response.status !== 200) {
          console.log(`카테고리 정보를 불러올 수 없습니다. STATUS: ${response.status}`);
        }

        /** 영상 상세 하단 카테고리 리스트에서 해당 영상은 중복이니 목록에서 제거함 */
        const result = response?.data?.result?.filter((item) => item.uuid !== uuid);
        const paging = response.data.paging;

        /** 카테고리 리스트 + 비디오 데이터 merge */
        let newCategoryData = { ...categoryData, videoList: result, paging: paging };

        setCategoryList((prev) => [...prev, newCategoryData]);
      })
      .catch((err) => {
        console.log("FETCH 카테고리 ERROR!👇");
        console.log(err.message);
      });
  };

  const handleScreenClick = () => {
    if (playerRef.current.paused()) {
      playerRef.current.play();
    } else {
      playerRef.current.pause();
    }
  };
  // 비디오 플레이어 옵션
  const [qualityIndex, setQualityIndex] = useState(2);
  const [autoJumpTime, setAutoJumpTime] = useState(0);
  const [anchors, setAnchors] = useState(null);
  const playerRef = React.useRef(null);
  const videoJsOptions = {
    poster: videoData?.thumbnailUrl,
    playsinline: true,
    controls: true,
    textTrackSettings: false,
    html5: {
      nativeTextTracks: false,
      nativeAudioTracks: false,
    },
    controlBar: {
      pictureInPictureToggle: false,
      qualitySelector: true,
    },
    playbackRates: [0.5, 1, 1.5, 2],
    userActions: {
      click: handleScreenClick,
    },
    sources: [
      {
        type: "application/x-mpegURL",
        src: videoUrls?.length > 0 && videoUrls,
        withCredentials: true,
      },
    ],
  };
  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on("error", (e) => {
      console.log("💥player.error ");
      console.log(player.error());
      console.log(e);
      console.log('----------------');

      sendError("video-js", e)

      Sentry.withScope((scope) => {
        scope.setTag("Player-ERROR", "플레이어에러");
        scope.setLevel("error");

        Sentry.captureException(new Error(e));
      });

    });
  };

  const sendError = (player, event) => {
    if (username) {
      const { type, error } = event;

      // * send error info to Sentry
      Sentry.setContext("HLS 플레이어 에러 상세", {
        playformPlayer: player,
        hlsErrortype: type,
        hlsErrormessage: error,
      })
      Sentry.withScope((scope) => {
        scope.setLevel("error");
        scope.setUser({
          id: username,
        })
        Sentry.captureMessage("HLS 플레이어 에러");
      });

    }
  }

  const changeVideoQualityHandler = (index) => {
    setSelectedIndex(index);
  };

  // 찜
  function handleWishClick(list) {
    const uuid = list.uuid;
    const id = list.id;

    // 찜한 비디오 리스트 에서, 클릭한 video id와 일치하는 항목의 찜 비디오 ID를 발굴
    const targetWishVideoListItem = wishList?.filter((listItem) => listItem.id === id || listItem.video.id === id);

    // 현재 클릭한 비디오가 찜한 비디오면 -> 찜 취소 / 찜한 비디오가 아니면 -> 찜 요청
    if (isWishVideo(id)) {
      requestWishItem(targetWishVideoListItem[0]?.id, "DELETE");
      console.log("찜한걸 누름");
      return;
    }
    requestWishItem(uuid, "POST");
  }

  // 찜하기 초기화 방지 저장
  const VideoComponent = useCallback(() => {
    return (
      <VideoJsPlayer
        options={videoJsOptions}
        onReady={handlePlayerReady}
        qualityHandler={changeVideoQualityHandler}
        qualityIndex={qualityIndex}
        setQualityIndex={setQualityIndex}
        autoJumpTime={autoJumpTime}
        setAutoJumpTime={setAutoJumpTime}
        anchorsData={anchors}
      />
    );
  }, [videoUrls, selectedIndex, canWatchable]);

  const handleBrowseMoreSlide = (categoryId, size) => {
    console.log("browseMoreSlide catId: 1");
    updateCategoryData(categoryId, size);
  };

  /**
   * 카테고리 더 불러오기 업데이트 API 호출
   */
  const updateCategoryData = async (categoryId, size) => {
    console.log("update Category Data!!");

    ApiService.getCategoryData(categoryId, size)
      .then((response) => {
        if (response.status !== 200) {
          console.log(`카테고리 정보를 불러올 수 없습니다. STATUS: ${response.status}`);
        }
        const result = response.data.result;

        const targetIndex = categoryList.findIndex((item) => item.id === categoryId);
        let copiedCategoryList = [...categoryList];
        copiedCategoryList[targetIndex].videoList = result;

        setCategoryList(copiedCategoryList);
      })
      .catch((err) => {
        console.log("FETCH 카테고리 더 불러오기 ERROR!👇");
        console.log(err);
      });
  };

  const loader = new AppLoader();

  function encodeUriScheme(url) {
    return "kollus://path?url=" + encodeURIComponent(url.replace("/s?jwt", "/si?jwt"));
  }

  function callPcPlayer(uriScheme) {
    let appOpened = false;

    // 사용자가 창에서 벗어났다가 돌아오면 앱이 열렸다고 간주
    window.onblur = function () {
      appOpened = true;
    };
    document.querySelector('iframe')?.remove(); //iframe 초기화

    // iframe을 생성하여 페이지에 추가
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = uriScheme;
    document.body.appendChild(iframe);

    // 2초 후에 체크하여 앱이 열리지 않았으면 다운로드 페이지로 이동
    setTimeout(function () {
      if (!appOpened) {
        if (confirm('플레이어 설치가 필요한 경우, 확인을 클릭해주세요.')) {
          downloadLink({ method: "TO", platform: "PC" });
        }
      }
    }, 2000);
  }

  function downloadLink({ method, platform }) {

    const downloadLinkMap = {
      "IOS": "https://itunes.apple.com/app/id760006888",
      "ANDROID": "market://details?id=com.kollus.media",
      "PC": "https://v.kr.kollus.com/pc_player_install"
    }

    if (method === "GET") {
      return downloadLinkMap[platform];
    } else if (method === "TO") {
      location.href = downloadLinkMap[platform];
    }

  }


  function callPlayer(uriScheme) {
        
    if (isIpad()) {
      let appOpened = false;
      
      window.location.href = uriScheme;


      window.onblur = function () {
          appOpened = true;
      };
      window.onfocus = function () {
          appOpened = false;
      };

      // 3초 후에 체크하여 앱이 열리지 않았으면 다운로드 페이지로 이동
      setTimeout(function () {
          console.log("appOpened")
          console.log(appOpened)
          if (!appOpened) {
              if (confirm('플레이어 설치가 필요한 경우, 확인을 클릭해주세요.')) {
                  handleDownloadClick();
              }
          }
      }, 3000);

  } else {

    loader.exec({
        ios: {
            scheme: uriScheme,
            url: downloadLink({ method: "GET", platform: "IOS" }),
        },
        android: {
            intentURI: uriScheme,
        },
        timerSet: {
            ios: 4000, // ios는 앱이 설치되어 있어도 앱 이동 confirm창이 하나 더 떠서, android 보다 지연 시간이 더 발생함.
            android: 4000
        },
        etcCallback: function () {
            // PC일때
            if (!isIOS && !isAndroid) {
                callPcPlayer(uriScheme);
            }
        },
        notFoundCallback: function () {
            if (isIOS) {
                downloadLink({ method: "TO", platform: "IOS" });
            } else if (isAndroid && !isSamsungBrowser()) {
               downloadLink({ method: "TO", platform: "ANDROID" });
            }
  
        }
    });
  
  
    if (isSamsungBrowser()) {
        let appOpened = false;
        document.querySelector('iframe')?.remove(); //iframe 초기화
  
        // iframe을 생성하여 페이지에 추가
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        iframe.src = uriScheme;
  
  
        window.onblur = function () {
            appOpened = true;
        };
  
        // 4초 후에 체크하여 앱이 열리지 않았으면 다운로드 페이지로 이동
        setTimeout(function () {
            if (!appOpened) {
                if (confirm('플레이어 설치가 필요한 경우, 확인을 클릭해주세요.')) {
                    downloadLink({ method: "TO", platform: "ANDROID" });
                }
            }
        }, 4000);
    }

  }


}


  const handleDownloadClick = () => {
    if (isIOS) {
      downloadLink({ method: "TO", platform: "IOS" });
    } else if (isAndroid) {
      downloadLink({ method: "TO", platform: "ANDROID" });
    } else {
      downloadLink({ method: "TO", platform: "PC" });
    }
  }

  if (videoData.id) {
    return (
      <>
        <Helmet>
          <title>{videoData?.title || "비디오 상세"} - 룰루플레이TV</title>
          <meta name="og:description" content={videoData?.title || "비디오 상세"} />
        </Helmet>
        <div id="video-detail">
          <div className="container">
            {canWatchable !== null && canWatchable ? (
              isDrm ? (
                <div className="thumbnail" style={{ cursor: "pointer" }} onClick={() => callPlayer(encodeUriScheme(videoUrls))}>
                  <div className="alertDrmPlayer" onClick={(e) => e.stopPropagation()}>
                    <span>
                      본 영상은 전용 플레이어로 재생됩니다.
                    </span>
                    <div>
                      <i>
                        플레이어 설치가 필요한 경우,<br />다운로드 버튼을 클릭해주세요.
                      </i>
                      <button className="player-link-button" onClick={handleDownloadClick}>
                        다운로드
                      </button>
                    </div>
                  </div>
                  <img src={`${videoData?.thumbnailUrl}?w=1280&h=720&q=100`} alt="banner-image" />
                  <span className="play-button">
                    <img src={Icon_play} alt="play" />
                  </span>
                </div>
              ) : (
                <VideoComponent />
              )
            ) : canWatchable !== null && !canWatchable ? (
              <div className="thumbnail" style={{ cursor: "pointer" }} onClick={handlePlayClick}>
                <img src={`${videoData?.thumbnailUrl}?w=1280&h=720&q=100`} alt="banner-image" />
                <span className="play-button">
                  <img src={Icon_play} alt="play" />
                </span>
              </div>
            ) : ("")}
            {
              // ? 플레이어 안내
              isDrm && <div
                style={{
                  display: "inline-flex", gap: "8px", alignItems: "flex-end", color: "#ccc", marginTop: 8, wordBreak: "keep-all", fontSize: 12, borderBottom: "1px solid #ccc", cursor: "pointer"
                }}
                onClick={() => window.open('about:blank').location.href = import.meta.env.VITE_ENVIRONMENT === "production" ? PLAYER_NOTICE_LINK_PROD : PLAYER_NOTICE_LINK_DEV}
              >
                * 플레이어 재생에 문제가 있나요?
              </div>}
            {
              // ? 브라우저 안내
              recommendToUpdate && (
                <div style={{ color: "red", marginTop: 16, wordBreak: "keep-all", fontSize: 12 }}>
                  * 원활한 영상 재생을 위해 최신 버전의 브라우저로 업데이트 하는 것을 권장합니다.
                </div>
              )
            }
            {
              // ? 브라우저 안내
              deprecatedBrowser && (
                <div style={{ color: "red", marginTop: 16, wordBreak: "keep-all", fontSize: 12 }}>
                  * 원활한 영상 재생을 위해 Chrome / Safari / Edge 브라우저 사용을 권장합니다.
                </div>
              )
            }
            <div className="row gx-2 gx-sm-2 gx-md-3">
              <div className="col-12 col-md-12 col-xxl-12 mx-auto">
                <div className="info">
                  <div className="info-body">
                    <div className="title mb-1">{videoData?.title}</div>
                    <div className="mb-3 d-flex gap-4 align-items-center">
                      <div className="created-at">{removeTimes(videoData?.launchDateTime) || "-"}</div>
                      <div className="play-time">
                        <img src={Icon_clock} alt="clock" />
                        <span>{secondsToMinutes(videoData?.runningTime) || "-"}</span>
                      </div>
                      <div className="price">
                        <img src={Icon_coin} alt="coin" />
                        <span>{videoData?.coin}</span>
                      </div>
                      <button className="add">
                        <img
                          src={isWishVideo(videoData?.id) ? Icon_add_active : Icon_add}
                          alt="add"
                          onClick={() => handleWishClick(videoData)}
                        />
                      </button>
                    </div>
                    <div className="mb-32 col-12 col-md-12 col-xxl-6">
                      <div className="desc">
                        <pre
                          dangerouslySetInnerHTML={{
                            __html: videoData?.description,
                          }}
                        ></pre>
                      </div>
                    </div>
                    <div className="extra-info mb-32">
                      <div>
                        <span>출연자</span>
                        <i>
                          {videoData?.actors
                            ? videoData?.actors?.map((actorItem, index) => {
                              return (
                                <span key={actorItem.id}>
                                  <span>{actorItem.name}</span>
                                  {actorItem.breast && actorItem.breast !== 0 ? (
                                    <span>
                                      ({actorItem.breast}
                                      {actorItem.cup})&nbsp;&nbsp;
                                    </span>
                                  ) : (
                                    <span>&nbsp;</span>
                                  )}
                                </span>
                              );
                            })
                            : "-"}
                        </i>
                      </div>
                      <div>
                        <span>제작</span>
                        <i>{videoData?.producer}</i>
                      </div>
                    </div>
                    <div className="tags">
                      {videoData?.tags
                        ?.replace(/\s/g, "")
                        .split(",")
                        .map((item) => (
                          <span className="tag" key={item + "-key"}>
                            {item}
                          </span>
                        ))}
                    </div>
                  </div>
                </div>

                <div className="category-list">
                  {/* 카테고리별 영상 (시퀀스:seq 기반으로 정렬)*/}
                  {categoryList.length > 0 &&
                    categoryList
                      ?.sort((a, b) => a.seq - b.seq)
                      .map((categoryItem, categoryIndex) => {
                        // 카테고리에 비디오 하나만 있는 경우 아무것도 안그림
                        if (categoryItem.videoList.length === 0) {
                          return;
                        }
                        return (
                          <div key={`category-${categoryItem.title}`}>
                            <h3 className="video-title mt-4">{categoryItem.title}</h3>
                            <div className="list-container">
                              <VideoList
                                listData={categoryItem.videoList}
                                categoryId={categoryItem.id}
                                handleBrowseMoreSlide={handleBrowseMoreSlide}
                                totalLength={categoryItem.paging?.totalElements - 1} //현재 영상을 전체 개수에서 빼야함
                              />
                            </div>
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 결제 모달 */}
        <Modal show={buyModalShow} onHide={handleBuyModalClose} backdrop="static" keyboard={false} centered>
          <Modal.Body>
            <div className="mt-3">
              내 잔여 코인 {coin}개<br />
              <strong>{videoData?.title}</strong>&nbsp; 3일 대여합니다.
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleBuyModalClose} style={{ height: "56px" }}>
              취소
            </Button>
            <Button variant="primary" onClick={handleBuyModalConfirm} style={{ height: "56px", display: "flex", flexDirection: "column" }}>
              <span>구매</span>
              <span style={{ display: "flex", alignItems: "center", opacity: 0.8 }}>
                <img src={Icon_coin_white} alt="coin" style={{ display: "block", width: 16, height: 16 }} />
                <span style={{ fontSize: 12 }}>{videoData?.coin}</span>
              </span>
            </Button>
          </Modal.Footer>
        </Modal>

        {/* 충전 모달 */}
        <Modal show={chargeModalShow} onHide={handleChargeModalClose} backdrop="static" keyboard={false} centered>
          <Modal.Body>
            <div className="mt-3">
              코인이 부족합니다.
              <br />
              필요 코인 {videoData?.coin}개 / 보유 코인 {coin}개
              <br />
              <br />
              코인 구매를 위해 <br />
              충전 페이지로 이동합니다.
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleChargeModalClose} style={{ height: "56px" }}>
              취소
            </Button>
            <Button variant="primary" onClick={handleChargeModalConfirm} style={{ height: "56px", display: "flex", flexDirection: "column" }}>
              <span>확인</span>
            </Button>
          </Modal.Footer>
        </Modal>

        {/* 결제완료 모달 */}
        <Modal show={resultModalShow} onHide={handleResultModalClose} backdrop="static" keyboard={false} centered>
          <Modal.Body>
            <div className="mt-3">{buyResult ? "구매가 완료되었습니다." : "이미 소장중인 작품입니다."}</div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                handleResultModalClose();
                navigate(0);
              }}
              style={{ height: "56px", display: "flex", flexDirection: "column" }}
            >
              <span>확인</span>
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  } else {
    return <SkeletonComponent />
  }
}



function SkeletonComponent() {
  /** 모바일 (~744) 에서 한 화면에 몇개 씩 보여줄 지 */
  const SLIDE_PER_VIEW_MOBILE = 1;
  /** PC/태블릿(745~)에서 한 화면에 몇개 씩 보여줄 지 */
  const SLIDE_PER_VIEW_PC = 3;

  return (
    <div id="video-detail">
      <AspectRatio
        ratio="16/9"
        variant="plain"
        objectFit="cover"
        sx={{
          width: "100%",
        }}
      >
        <Skeleton sx={{ minWidth: "100%", width: "100%", bgcolor: "grey.900", WebkitTransform: "unset" }}></Skeleton>
      </AspectRatio>
      <div className="container">
        <div className="row gx-2 gx-sm-2 gx-md-3">
          <div className="col-12 col-md-12 col-xxl-12 mx-auto">
            <div className="info">
              <div className="info-body">
                <Skeleton sx={{ width: "100%", bgcolor: "grey.900", WebkitTransform: "unset" }}>
                  <div className="title mb-1">Title Loading...</div>
                </Skeleton>
                <Skeleton sx={{ width: "100%", bgcolor: "grey.900", WebkitTransform: "unset" }}></Skeleton>
                <Skeleton sx={{ width: "100%", bgcolor: "grey.900", WebkitTransform: "unset" }}>
                  <div className="mb-32 col-12 col-md-12 col-xxl-6"></div>
                </Skeleton>
                <Skeleton sx={{ width: "100%", bgcolor: "grey.900", WebkitTransform: "unset" }}></Skeleton>
                <Skeleton sx={{ width: "100%", bgcolor: "grey.900", WebkitTransform: "unset" }}></Skeleton>
              </div>
            </div>

            <div className="category-list">
              <h3 className="video-title mt-4">
                <Skeleton sx={{ width: "30%", bgcolor: "grey.900", WebkitTransform: "unset", height: "24px" }} />
              </h3>
              <div className="list-container">
                <Swiper
                  slidesPerView={SLIDE_PER_VIEW_MOBILE + 0.2}
                  spaceBetween={8}
                  breakpoints={{
                    1440: {
                      slidesPerView: SLIDE_PER_VIEW_PC + 0.2,
                      spaceBetween: 16,
                    },
                    744: {
                      slidesPerView: SLIDE_PER_VIEW_PC + 0.2,
                      spaceBetween: 8,
                    },
                  }}
                >
                  {Array.from({ length: 4 }).map((list, index) => {
                    return (
                      <SwiperSlide key={`slide-${index}`}>
                        <AspectRatio
                          ratio="16/9"
                          variant="plain"
                          objectFit="cover"
                          sx={{
                            width: "100%",
                          }}
                        >
                          <Skeleton sx={{ width: "100%", height: "100px", bgcolor: "grey.900", WebkitTransform: "unset" }}></Skeleton>
                        </AspectRatio>
                        <Skeleton sx={{ width: "100%", bgcolor: "grey.900", WebkitTransform: "unset", height: "24px", marginTop: "10px" }} />
                        <Skeleton sx={{ width: "60%", bgcolor: "grey.900", WebkitTransform: "unset", height: "18px", marginTop: "10px" }} />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}