import React from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

export default function PaymentsError() {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/home", {replace: true});
  };

  return (
    <>
      <Helmet>
        <title>주문 결과 - 룰루플레이TV</title>
      </Helmet>
      <div id="payments-detail">
        <h3 className="text-center pt-5">주문 결과</h3>
        <p className="text-center mb-5 text-gray" style={{height: 160, display: "flex", alignItems: "center", justifyContent: "center"}}>코인 결제에 실패했습니다. 다시 시도해주세요.</p>
        <div className="container">
          <div className="row gx-2 gx-sm-2 gx-md-3">
            <div className="col-12 col-md-6 col-md-4 mx-auto">
              <button className="button mt-5" type="button" onClick={handleSubmit}>
                메인으로
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
