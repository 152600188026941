import React, { Suspense } from 'react'
import { useLocation } from 'react-router-dom'
// import Footer from './Footer'
import Header from './Header'

export default function Layout({children}) {
  const location = useLocation();

  const Footer = React.lazy(() => import('./Footer'));

  return (
    <>
    <Header></Header>
    <div className="content">
        {children}
    </div>
    {
      location.pathname !== '/shortform-detail' && (
        <Suspense>
          <Footer />
        </Suspense>
      )
    }
    </>
  )
}
