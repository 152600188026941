import { useInfiniteQuery } from "@tanstack/react-query";
import { Helmet } from "react-helmet-async";
import VideoList from "../components/VideoList";
import ApiService from "../services/ApiService";
import { useLocation, useParams } from "react-router-dom";
import AuthApiService from "../services/AuthApiService";
import React, { useState } from "react";

export default function Streaming() {

  // init size + 더보기 클릭할 때마다 불러올 영상 개수
  const params = useParams();
  const location = useLocation();
  const SIZE = 36;
  const categoryId = params?.categoryId || null;

  const [title, setTitle] = useState(location?.state?.title);


  const getTitle = () => {
    console.log("getTitle 호출")
    fetchCategoryList();
  }
  
  const fetchCategoryList = async () => {
    ApiService.getCategoryListData()
      .then((response) => {
        const result = response.data.result;
        const matchIndex = result.findIndex((item) => item.id == categoryId);
        const targetTitle = result[matchIndex]?.title;
        setTitle(targetTitle);
      })
      .catch((err) => {
        console.log("FETCH 카테고리 ERROR!👇");
        console.log(err);
      });
  };

  useState(() => {
    // HOME Link를 타지 않아 state가 없을 시 타이틀 조회
    if(!location?.state?.title) {
      getTitle();
    }
  }, []);


  const fetchVideoList = async (pageParam) => {
    if(categoryId) {
      const response = await AuthApiService.getCategoryData(categoryId ,SIZE, pageParam);
      return response.data;
    } else {
      const response = await ApiService.getVodList(SIZE, pageParam);
      return response.data;
    }
  };
  
  const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, status, isError } = useInfiniteQuery({
    queryKey: ["all-video", categoryId],
    queryFn: ({pageParam = 1}) => fetchVideoList(pageParam),
    getNextPageParam: (item) => {
      const offset = item.paging.page.offset;
      const lastPage = item.paging.totalPages;

      let currentPage = (offset / SIZE) + 1;
      if (offset === 0) {
        currentPage = 1;
      }

      if (currentPage < lastPage) {
        return currentPage + 1;
      } else {
        return undefined;
      }
    },
    keepPreviousData: true,
  });
  
  /** VideoList 컴포넌트에 값을 넘겨주기 위해 리스트 재가공 */
  const getMergedVideoList = () => {
    let mergeVideoList = [];
    data?.pages?.map((page) => {
      mergeVideoList = [...mergeVideoList, ...page.result];
    });
    console.log("mergeVideoList");
    console.log(mergeVideoList);
    return mergeVideoList;
  };

  return (
    <>
      <Helmet>
        <title>스트리밍 - 룰루플레이TV</title>
      </Helmet>
      <div id="release">
        <div className="container">
          <div className="row gx-2 gx-sm-2 gx-md-3">
            <div className="col-12 col-md-12 col-xxl-12 mx-auto">
              <div className="video-list mt-5">
                <div className="list-wrap">
                  <h3 className="video-title">{categoryId ? title : "⭐️ 전체 영상"}</h3>

                      <VideoList listData={getMergedVideoList()} showAll />
                
                  {getMergedVideoList().length > 0 && hasNextPage && (
                    <button
                      className="more"
                      style={{
                        width: "100%",
                        marginTop: 32,
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        fetchNextPage();
                      }}
                      disabled={!hasNextPage || isFetchingNextPage}
                    >
                      {isError ? (
                        <span>
                          목록을 불러올 수 없습니다.
                          <br />
                          다시 시도 해주세요.
                        </span>
                      ) : (
                        <span>더보기</span>
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
