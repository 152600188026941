import React from 'react'

export default function CopyRightPolicy() {
  return (
    <div className="container">
      <div className="row gx-2 gx-sm-2 gx-md-3">
        <div className="col-12 col-md-12 col-md-12 mx-auto">
          <h3 className="mt-5">저작권보호센터</h3>
          <div>
            <span style={{ whiteSpace: "pre-line", wordBreak: "keep-all" }}>
                업데이트 예정입니다.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
