import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import moment from "moment";
import Icon_arr from "../assets/images/icons/arr-rignt.svg";
import { authStore } from "../store/authStore";
import { useState } from "react";
import { useNoticeList } from "../utils/useNotice";

export default function Notice() {
  // 한번에 불러올 공지사항 갯수
  const SIZE = 10;
  const PAGE_SIZE = 5;
  const [page, setPage] = useState(1);
  const [bunch, setBunch] = useState(1);
  
  const { noticeList, totalElements, totalPages } = useNoticeList({size: SIZE, page});


  const handlePaginationClick = (e, index) => {
    e.preventDefault();
    setPage(index + 1);
  };

  const handleNextClick = () => {
    if (page === totalPages) {
      return;
    }
    setBunch(bunch + 1);
    setPage(bunch * PAGE_SIZE + 1);
  }
  const handlePrevClick = () => {
    if (page === 1 || bunch === 1) {
      return;
    }
    setBunch(bunch - 1);
    setPage(page - PAGE_SIZE);
  }
  return (
    <>
      <Helmet>
        <title>공지사항 - 룰루플레이TV</title>
      </Helmet>

      <div id="community">
        <div className="container">
          <div className="row gx-2 gx-sm-2 gx-md-3">
            <div className="col-12 col-md-10 col-xxl-10 mx-auto">
              <h3 className="pt-5 text-center">공지사항</h3>
              <div className="notice-table">
                {noticeList?.length === 0 ? (
                  <div className="text-gray text-center" style={{ marginTop: 180 }}>
                    <span>공지사항이 없습니다.</span>
                  </div>
                ) : (
                  <>
                    <table className="table table-dark">
                      <thead>
                        <tr>
                          <th scope="col">종류</th>
                          <th scope="col">제목</th>
                          <th scope="col">날짜</th>
                        </tr>
                      </thead>
                      <tbody>
                        {noticeList?.map((noticeItem, noticeIndex) => {
                          return (
                            <tr key={`${noticeItem.id} + ${noticeIndex}`}>
                              <th scope="row">공지</th>
                              <td>
                                <Link
                                  to={`/notice/${noticeItem.id}`}
                                  state={{ noticeList: noticeList, currentIndex: noticeIndex, totalElements: totalElements }}
                                >
                                  {noticeItem.title}
                                </Link>
                              </td>
                              <td>{moment(noticeItem.createdAt).add(9, "h").format("YYYY-MM-DD")}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <nav aria-label="Page navigation example">
                      <ul className="pagination">
                        <li className="page-item control">
                          <Link className="page-link" aria-label="Previous" onClick={handlePrevClick}>
                            <img src={Icon_arr} alt="Previous" />
                          </Link>
                        </li>
                        {noticeList?.length > 0 &&
                          Array.from(Array(totalPages), (item, index) => {
                            return (
                              index + 1 > PAGE_SIZE * (bunch - 1) &&
                              index + 1 <= PAGE_SIZE * bunch && (
                                <li className={index + 1 === page ? "page-item active" : "page-item"} key={"page-" + index}>
                                  <Link className="page-link" style={{ cursor: "pointer" }} onClick={(e) => handlePaginationClick(e, index)}>
                                    <span>{index + 1}</span>
                                  </Link>
                                </li>
                              )
                            );
                          })}
                        <li className="page-item control">
                          <Link className="page-link" aria-label="Next" onClick={handleNextClick}>
                            <img src={Icon_arr} alt="next" />
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
