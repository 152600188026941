import { useQuery } from "@tanstack/react-query";
import AuthApiService from "../services/AuthApiService";
import { authStore } from "../store/authStore";

const useCoin = () => {
    const { isLogin, username } = authStore();

    const fetchCoin = async () => {
        console.log("🚀 FETCH Coin 🚀");
        // 슈가 업데이트
        return AuthApiService.getMyCoin();
    };

    const { data, refetch: updateCoin } = useQuery({
        queryKey: ["coin", username],
        queryFn: fetchCoin,
        enabled: !!isLogin
    });

    const coin = data?.data.result;

    
    return {coin, updateCoin};
};



export default useCoin;