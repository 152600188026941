import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import loadingStore from "../../store/loadingStore";

export default function FindPassword() {
  const [userId, setUserId] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [findState, setFindState] = useState(null);
  const { setIsLoading } = loadingStore();

  const requestFindPassword = async (userId, userEmail) => {
    setIsLoading(true);
    const userData = {
      username: userId,
      email: userEmail
    }
    await fetch(`${import.meta.env.VITE_APP_API_SERVER_HOST}/auth/find/password`, {
      method: "POST",
      body: JSON.stringify(userData),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);

        if (data.message !== "SUCCESS") {
          console.log("에러 💥");
          console.log(data);

          if(data.code === "9999") {
            alert("오류가 발생했습니다. 다시 시도해 주시거나 문제가 반복될 시 고객센터에 문의해주세요.");
            setFindState("ERROR");
          } else {
            alert("이메일, 아이디 정보를 다시 확인해주세요.");
            setFindState("FAIL");
          }

        } else {
          console.log("비밀번호 찾기 성공! 내려받은 데이터👇");
          console.log(data);
          setFindState("SUCCESS");
        }
      })
      .catch((err) => {
        setIsLoading(false);

        console.log("ERROR!👇");
        console.log(err.message);
      });
  };

  function handleSubmit(e) {
    e.preventDefault();
    requestFindPassword(userId, userEmail);
  }

  return (
    <>
      <Helmet>
        <title>비밀번호 찾기 - 룰루플레이TV</title>
      </Helmet>
      <div id="login">
        <div className="container">
          <div className="row gx-2 gx-sm-2 gx-md-3">
            <div className="col-12 col-md-6 col-md-4 mx-auto">
              <div className="main-title">
                <h2 className="text-center">비밀번호 찾기</h2>
                <p className="text-center">
                  비밀번호를 찾고자 하는 아이디와 이메일을 입력해주세요.
                  <br />
                  해당 메일주소로 임시 비밀번호가 전송됩니다.
                </p>
                {findState === "SUCCESS" && (
                  <p
                    className="text-center text-warning p-3"
                    style={{
                      borderColor: "#ffc107",
                      border: "1px solid #ffc107",
                      borderRadius: "8px",
                    }}
                  >
                    이메일 주소로 임시 비밀번호를 발송했습니다.
                  </p>
                )}
                {findState === "FAIL" && (
                  <p
                    className="text-center text-warning p-3"
                    style={{
                      borderColor: "#ffc107",
                      border: "1px solid #ffc107",
                      borderRadius: "8px",
                    }}
                  >
                    이메일 또는 아이디 정보를 다시 확인해주세요.
                  </p>
                )}
                {findState === "ERROR" && (
                  <p
                    className="text-center text-warning p-3"
                    style={{
                      borderColor: "#ffc107",
                      border: "1px solid #ffc107",
                      borderRadius: "8px",
                    }}
                  >
                    오류가 발생했습니다. 다시 시도해 주시거나 문제가 반복될 시 고객센터에 문의해주세요.
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="row gx-2 gx-sm-2 gx-md-3">
            <div className="col-12 col-md-6 col-xxl-6 mx-auto">
              <form className="mt-5" onSubmit={handleSubmit}>
                <div className="input-text">
                  <label htmlFor="userId">아이디</label>
                  <input id="userId" name="userId" type="text" placeholder="아이디를 입력하세요." value={userId} onChange={(e) => setUserId(e.target.value)} />
                </div>
                <div className="input-text">
                  <label htmlFor="userEmail">이메일</label>
                  <input
                    id="userEmail"
                    name="userEmail"
                    type="email"
                    placeholder="이메일 주소를 입력하세요."
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                  />
                </div>
                <button disabled={userEmail === "" || findState === "SUCCESS"} type="submit" className="button mt-5">
                  비밀번호 찾기
                </button>
                {findState === "SUCCESS" && (
                  <Link to={"/login"} type="submit" className="button mt-3" style={{ backgroundColor: "#333" }}>
                    로그인 화면으로
                  </Link>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
