import { useQuery } from "@tanstack/react-query";
import AuthApiService from "../services/AuthApiService";
import { authStore } from "../store/authStore";

const useNoticeData = ({ noticeId }) => {
  const { isLogin } = authStore();

  const fetchNoticeData = async (noticeId) => {
    console.log("🚀 FETCH 공지사항상세 🚀");
    return AuthApiService.getNoticeData(noticeId);
  };

  const { data: noticeData, isError } = useQuery({
    queryKey: ["notice-data"],
    queryFn: () => fetchNoticeData(noticeId),
    enabled: !!isLogin,
  });

  return {noticeData, isError};
};


const useNoticeList = ({ size, page }) => {
  const { isLogin } = authStore();

  const fetchNoticeList = async (size, page) => {
    console.log("🚀 FETCH 공지사항목록 🚀");
    return AuthApiService.getNoticeList(size, page);
  };

  const { data } = useQuery({
    queryKey: ["notice-list", {page}],
    queryFn: () => fetchNoticeList(size, page),
    enabled: !!isLogin,
  });

  const noticeList = data?.data?.result;
  const totalElements = data?.data?.paging?.totalElements;
  const totalPages = data?.data?.paging?.totalPages;

  return {noticeList, totalElements, totalPages};
};

export {useNoticeList, useNoticeData};
