import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet-async";
import AuthApiService from "../services/AuthApiService";
import useCoin from "../utils/useCoin";


export default function PaymentsResult() {
  const [resultMessage, setResultMessage] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const uuidParam = searchParams.get("uuid");
  const isTicket = searchParams.get("ticket") || null;

  // 코인
  const { updateCoin } = useCoin();


  useEffect(() => {
    console.log(uuidParam);
    if (uuidParam) {
      requestResult(uuidParam);
    }
  }, []);

  const requestResult = async (uuid) => {
    if (isTicket) {
      console.log("isTicket");
      console.log(isTicket);
      AuthApiService.getTicketOrderResult(uuid)
        .then((response) => {
          console.log(response.data.result);
          console.log("티켓 주문이 완료되었습니다.");
          setResultMessage("결제가 완료되었습니다.");
          // 코인 업데이트
          updateCoin();

          // handleSuccessModalShow();
        })
        .catch((err) => {
          console.log("FETCH 주문 결과 ERROR!👇");
          setResultMessage("결제가 실패했습니다. 다시 시도해주세요.");
          console.log(err.message);
        });
    } else {
      AuthApiService.getOrderResult(uuid)
        .then((response) => {
          console.log(response.data.result);

          if (response.data.message !== "SUCCESS") {
            console.log("주문조회 실패");
            setResultMessage("오류가 발생했습니다. 다시 시도해주세요.");
          } else {
            if (response.data.result.orderStatus === "CHARGED") {
              console.log("주문이 완료되었습니다.");
              setResultMessage("결제가 완료되었습니다.");
              // 코인 업데이트
              updateCoin();

            } else {
              console.log("결제가 실패했습니다. 다시 시도해주세요.");
              setResultMessage("결제가 실패했습니다. 다시 시도해주세요.");
            }
          }
        })
        .catch((err) => {
          console.log("FETCH 주문 결과 ERROR!👇");
          console.log(err.message);
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/home");
  };

  // 모달
  const [failModal, setFailModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  // 모달 open, close, confirm handler
  const handleFailModalClose = () => setFailModal(false);
  const handleFailModalShow = () => setFailModal(true);
  const handleFailModalConfirm = () => {
    // navigate(-1);
    handleFailModalClose();
  };

  const handleSuccessModalClose = () => setSuccessModal(false);
  const handleSuccessModalShow = () => setSuccessModal(true);
  const handleSuccessModalConfirm = () => {
    navigate(-2);
    handleSuccessModalClose();
  };

  return (
    <>
      <Helmet>
        <title>주문 결과 - 룰루플레이TV</title>
      </Helmet>
      <div id="payments-detail">
        <h3 className="text-center pt-5">주문 결과</h3>
        <p className="text-center mb-5 text-gray">{resultMessage}</p>
        <div className="container">
          <div className="row gx-2 gx-sm-2 gx-md-3">
            <div className="col-12 col-md-6 col-md-4 mx-auto">
              <button className="button mt-5" type="button" onClick={handleSubmit}>
                확인
              </button>
            </div>
          </div>
        </div>

        {/* 성공 모달 */}
        <Modal show={successModal} onHide={handleSuccessModalClose} backdrop="static" keyboard={false} centered>
          <Modal.Body>
            <div className="mt-3">결제에 성공했습니다.</div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={handleSuccessModalConfirm}
              style={{
                height: "56px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span>확인</span>
            </Button>
          </Modal.Footer>
        </Modal>
        {/* 실패 모달 */}
        <Modal show={failModal} onHide={handleFailModalClose} backdrop="static" keyboard={false} centered>
          <Modal.Body>
            <div className="mt-3">결제에 실패했습니다. 다시 시도해주세요.</div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={handleFailModalConfirm}
              style={{
                height: "56px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span>확인</span>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
