/**
 * 현재 보유한 코인으로 구매 가능한 상품인지 확인하는 함수
 * @param {number} myCoin - param1: 보유 중인 코인
 * @param {number} price - param2: 상품의 가격
 * @returns {boolean}
 */
export function checkAvailableForPurchase(myCoin, price) {
    if(myCoin >= price) {
        return true // 구매 가능
    } else {
        return false // 구매 불가
    }
}