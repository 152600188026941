import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import Icon_play from "../assets/images/icons/play.svg";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

const ShortFormPlayer = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);

  const { options, onReady, swiperRef, currentSlideIndex, totalSlideLength, accessDenied, index, shortFormData, title } = props;


  const [isPlaying, setIsPlaying] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  /** video.js 초기화 */
  useEffect(() => {
    if (options.sources.length === 0) {
      return;
    }

    // Make sure Video.js player is only initialized once

    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");

      videoElement.classList.add("vjs-big-play-centered");
      videoRef?.current?.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, { ...options }, () => {
        onReady && onReady(player);

        // init
        player.muted(true);
        player.pause();
        player.autoplay(false);
        setIsPlaying(false);


        // 성인인증이 필요한 컨텐츠의 경우 Stop & 음소거 Init
        if (accessDenied) {
          player.muted(true);
          player.pause();
          player.autoplay(false);
          setIsPlaying(false);
        }

        if (currentSlideIndex === index) {
          player.autoplay("any");
          
          // 스트림 세그먼트가 로드되고 재생이 가능한 시점
          player.on("canplaythrough", () => {
            console.log("Stream segment loaded : can playing 1️⃣");
            player.muted(false);
            setIsPlaying(true);
            console.log("player.muted()");
            if (player.muted()) {
              setIsMuted(true);
            } else {
              setIsMuted(false);
            }
          });

          player.on("pause", () => {
            setIsPaused(true);
          });
          player.on("play", () => {
            setIsPaused(false);
          });
        } else {
          // 현재 슬라이드 영상이 아닌 경우 멈춤
          player.autoplay(false);
          setIsPlaying(false);
          setIsMuted(true);
          player.pause();
        }
      }));

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;

      player.muted(true);
      player.pause();
      player.autoplay(false);
      setIsPlaying(false);
      
      if (currentSlideIndex === index) {
        player.src(options.sources);
        player.autoplay("any");

        // 스트림 세그먼트가 로드되고 재생이 가능한 시점
        player.on("canplaythrough", () => {
          console.log("Stream segment loaded : can playing 2️⃣");
          player.muted(false);
          setIsPlaying(true);

          if (player.muted()) {
            setIsMuted(true);
          } else {
            setIsMuted(false);
          }
        });

        player.on("pause", () => {
          setIsPaused(true);
        });
        player.on("play", () => {
          setIsPaused(false);
        });
      } else {
        // 현재 슬라이드 영상이 아닌 경우 멈춤
        player.autoplay(false);
        setIsMuted(true);
        setIsPlaying(false);
        player.pause();
      }
    }
  }, [options, videoRef]);

  /** video.js 클리어 : Dispose the Video.js player when the functional component unmounts */
  React.useEffect(() => {
    if (playerRef.current) {
      const player = playerRef.current;

      return () => {
        if (player && !player.isDisposed()) {
          player.dispose();
          playerRef.current = null;
        }
      };
    }
  }, [playerRef]);

  const handleNextSlide = () => {
    swiperRef.current.slideNext();
    setIsPlaying(false);
  };

  const handlePrevSlide = (page) => {
    swiperRef.current.slideTo(page);
    setIsPlaying(false);
  };

  /** video.js 재생/정지 컨트롤 */
  const handlePlayControl = () => {
    // if (playerRef.current.play() === undefined) return;

    if (playerRef.current.paused()) {
      playerRef.current.play();
    } else {
      playerRef.current.pause();
    }
  };
  /** video.js 음소거 컨트롤 */
  const handleMuteControl = () => {
    if (playerRef.current.play() === undefined) return;

    if (playerRef.current.muted()) {
      setIsMuted(false);
      playerRef.current.muted(false);
    } else {
      setIsMuted(true);
      playerRef.current.muted(true);
    }
  };

  /** mobile 터치 시 position 저장 및 touchEnd position과 비교해서 slide prev/next 인지 판단 */
  const [touchPositionStart, setTouchPositionStart] = useState(null);

  const handleTouchStart = (e) => {
    // 터치 시작 Y 좌표
    setTouchPositionStart(e.targetTouches[0].screenY);
  };

  const handleTouchEnd = (e) => {
    // 터치 끝 Y 좌표
    const mousePositionEnd = e.changedTouches[0].screenY;

    /**
     * 터치 시작점 > 터치 끝점 -> 다음 슬라이드로 넘기기
     * 터치 시작점 < 터치 끝점 -> 이전 슬라이드로 넘기기
     *
     * extraArea -> 터치 여유분 -> 너무 타이트하면 살짝만 움직여도 넘어가서 오작동처럼 보일 수 있음.
     */
    const extraArea = 40;

    if (touchPositionStart - extraArea > mousePositionEnd) {
      if (currentSlideIndex !== totalSlideLength - 1) {
        // sllide next
        handleNextSlide();

        playerRef.current.play();
        // console.log(playerRef.current)
      }
    } else if (touchPositionStart + extraArea < mousePositionEnd) {
      if (currentSlideIndex !== 0) {
        // sllide prev
        handlePrevSlide(currentSlideIndex - 1);

        playerRef.current.play();

        // console.log(playerRef.current)
      }
    }
  };
  const AccessDeniedScreen = () => {
    return (
      <>
        <span
          style={{
            display: "block",
            position: "absolute",
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 888,
            opacity: 1,
          }}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          <div
            style={{
              display: "inline-block",
              position: "absolute",
              left: "50%",
              top: "50%",
              width: "max-content",
              transform: "translate(-50%, -50%)",
              zIndex: 999,
              opacity: 1,
            }}
          >
            <span style={{ color: "gray" }}>😭 성인 컨텐츠를 열람하실 수 없습니다.</span>
            <Link className="button mt-3" to="/authentication-request">
              성인인증하기
            </Link>
          </div>
        </span>
      </>
    );
  }

  if (accessDenied) {
    return <AccessDeniedScreen />
  } else {
    return (
      <>
        <>
          {isMuted && (
            <span
              className="play-button"
              style={{
                cursor: "pointer",
                padding: "4px 12px",
                fontSize: 14,
                position: "absolute",
                right: "20px",
                top: "40px",
                zIndex: 1999,
                opacity: "0.8",
                backgroundColor: "#000",
                border: "1px solid #fff",
              }}
              onClick={() => {
                handleMuteControl();
              }}
            >
              <VolumeOffIcon />
            </span>
          )}
          {isPaused && (
            <span
              className="play-button"
              style={{
                width: 120,
                height: 120,
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 999,
                opacity: "0.8",
              }}
              onClick={() => {
                handlePlayControl();
              }}
            >
              <img src={Icon_play} alt="play" />
            </span>
          )}
          <span
            style={{
              display: "block",
              position: "absolute",
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 888,
              opacity: 0,
            }}
            onClick={() => {
              handlePlayControl();
            }}
          ></span>
          <div data-vjs-player style={{ zIndex: 10 }} className={`video-js-fit-height index:${index}-current:${currentSlideIndex}`}>
            <div ref={videoRef} className="shortform-video-tag" />
          </div>
          <img
            style={{
              display: currentSlideIndex === index && isPlaying ? "none" : "block",
              position: "absolute",
              left: "50%",
              transform: "translate(-50%, 0)",
              width: "100%",
              height: "100%",
              objectFit: "contain",
              zIndex: 30,
              filter: "contrast(120%) brightness(1.1)",
            }}
            src={shortFormData[index].thumbnailUrl}
            alt="thumbnail"
          />
        </>
        <div className="shortform-title">
          <h5>{title}</h5>
        </div>
      </>
    );
  }
};

export default ShortFormPlayer;
