import React from 'react'
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";


export default function CustomModal({
  handleConfirm,
  handleShow,
  handleClose,
  header,
  message,
  close,
}) {

  return (
    <Modal
      show={handleShow}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <span>{header && header}</span>
      </Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        {close && (
          <Button variant="secondary" onClick={handleClose}>
            취소
          </Button>
        )}
        <Button variant="primary" onClick={handleConfirm}>
          확인
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
