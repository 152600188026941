import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../components/CustomModal";
import AuthApiService from "../../services/AuthApiService";
import { authStore } from "../../store/authStore";

export default function ChangePassword() {
  const [userPassword, setUserPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [passwordState, setPasswordState] = useState(null);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const navigate = useNavigate();
  const { accessToken } = authStore();

  // 회원가입 버튼 disabled 컨트롤
  useEffect(() => {
    if (userPassword != "" && newPassword != "" && newPasswordConfirm != "" && newPassword === newPasswordConfirm) {
      setIsCheckedAll(true);
    } else {
      setIsCheckedAll(false);
    }
  }, [userPassword, newPassword, newPasswordConfirm]);

  // 비밀번호 valid check
  const isValidFormat = () => {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,16}$/; // 비밀번호 : 영문+숫자 8~16

    // 비밀번호 조건이 정규식과 일치하지 않을 때
    if (!passwordRegex.test(userPassword)) {
      return false;
    }
    if (!passwordRegex.test(newPassword)) {
      return false;
    }
    if (!passwordRegex.test(newPasswordConfirm)) {
      return false;
    }
    return true;
  };

  // 모달
  const [modalShow, setModalShow] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  // 모달 open, close, confirm handler
  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);
  const handleModalConfirm = () => {
    navigate("/mypage");
  };

  const isValidPassword = () => {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,16}$/; // 비밀번호 : 영문+숫자 8~16

    // 비밀번호 조건이 정규식과 일치하지 않을 때
    if (!passwordRegex.test(userPassword)) {
      alert("올바른 비밀번호 형식이 아닙니다. (영문, 숫자 8자~16자 사이)");

      return false;
    }
    return true;
  };

  const requestFindPassword = async (userPassword, newPassword) => {
    const userData = {
      password: userPassword,
      newPassword: newPassword,
    };

    AuthApiService.postChangePassword(userData)
      .then((response) => {
        if (response.status !== 200) {
          console.log("에러 💥");
          alert("비밀번호를 다시 확인해주세요.");
          setPasswordState("FAIL");
        } else {
          console.log("비밀번호 찾기 성공! 내려받은 데이터👇");
          console.log(response.data);
          handleModalShow();
        }
      })
      .catch((err) => {
        console.log("ERROR!👇");
        alert("비밀번호를 다시 확인해주세요.");
        setPasswordState("FAIL");
        console.log(err.message);
      });
  };

  function handleSubmit(e) {
    e.preventDefault();
    if (userPassword === newPassword) {
      alert("현재 비밀번호와 동일한 비밀번호로 변경할 수 없습니다.");
      return;
    }
    if (!isValidFormat()) {
      setPasswordState("INVALID");
      alert("비밀번호 형식이 맞지 않습니다.");
      return;
    }

    requestFindPassword(userPassword, newPassword);
  }

  return (
    <>
      <Helmet>
        <title>비밀번호 변경 - 룰루플레이TV</title>
      </Helmet>
      <div id="login">
        <div className="container">
          <div className="row gx-2 gx-sm-2 gx-md-3">
            <div className="col-12 col-md-6 col-md-4 mx-auto">
              <div className="main-title">
                <h2 className="text-center">비밀번호 변경</h2>
              </div>
              {passwordState === "FAIL" && (
                <p
                  className="text-center text-warning p-3"
                  style={{
                    borderColor: "#ffc107",
                    border: "1px solid #ffc107",
                    borderRadius: "8px",
                  }}
                >
                  비밀번호가 유효하지 않습니다. 다시 확인해주세요.
                </p>
              )}
              {passwordState === "INVALID" && (
                <p
                  className="text-center text-warning p-3"
                  style={{
                    borderColor: "#ffc107",
                    border: "1px solid #ffc107",
                    borderRadius: "8px",
                  }}
                >
                  비밀번호의 형식이 유효하지 않습니다. 다시 확인해주세요.
                </p>
              )}
            </div>
          </div>
          <div className="row gx-2 gx-sm-2 gx-md-3">
            <div className="col-12 col-md-6 col-xxl-6 mx-auto">
              <form className="mt-5" onSubmit={handleSubmit}>
                <input type="text" readOnly hidden name="username" autoComplete="username" />
                <div className="input-text">
                  <label htmlFor="userPassword">현재 비밀번호</label>
                  <input
                    id="userPassword"
                    name="userPassword"
                    type="password"
                    placeholder="비밀번호를 입력하세요."
                    value={userPassword}
                    onChange={(e) => setUserPassword(e.target.value)}
                    autoComplete="password"
                  />
                  <i className="message info">영문, 숫자 8자~16자 사이</i>
                </div>
                <div className="input-text">
                  <label htmlFor="newPassword">새 비밀번호</label>
                  <input
                    id="newPassword"
                    name="newPassword"
                    type="password"
                    placeholder="비밀번호를 입력하세요."
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    autoComplete="new-password"
                  />
                </div>
                <div className="input-text">
                  <label htmlFor="newPasswordConfirm">새 비밀번호 확인</label>
                  <input
                    id="newPasswordConfirm"
                    name="newPasswordConfirm"
                    type="password"
                    placeholder="비밀번호를 한번 더 입력하세요."
                    value={newPasswordConfirm}
                    onChange={(e) => setNewPasswordConfirm(e.target.value)}
                    autoComplete="new-password"
                  />
                  {newPassword !== newPasswordConfirm && <i className="message error">비밀번호가 일치하지 않습니다.</i>}
                </div>
                <button disabled={!isCheckedAll} type="submit" className="button mt-5">
                  비밀번호 변경
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <CustomModal
        handleShow={modalShow}
        handleClose={handleModalClose}
        message={"비밀번호 변경이 완료되었습니다."}
        handleConfirm={handleModalConfirm}
        close={false}
      />
    </>
  );
}
