import React from "react";
import Icon_adult from "../../assets/images/icons/icon_adult.svg";
import { authStore } from "../../store/authStore";

export default function AuthenticationRequest() {
  const { username } = authStore();

  const handleAuthSelfClick = () => {
    window.location.href = `${import.meta.env.VITE_APP_API_SERVER_HOST}/auth/self/request?username=${username}`;
  };

  return (
    <div id="join">
      <div className="container">
        <div className="row gx-2 gx-sm-2 gx-md-3">
          <div className="col-12 col-md-6 col-md-4 mx-auto">
            <div>
              <div
                className="mb-4"
                style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 8, marginTop: 120 }}
              >
                <img src={Icon_adult} alt="adult" style={{ display: "block", width: 80 }} />
                <h2 className="mt-3">룰루플레이 회원 성인 인증</h2>
                <p className="text-center">성인 인증 후 다양한 컨텐츠를 마음껏 즐기세요!</p>
              </div>
              <button className="button" onClick={handleAuthSelfClick}>
                성인인증하기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
