import React, { useEffect, useRef, useState } from "react";
import Icon_coin from "../assets/images/icons/coin-white.svg";
import Icon_add from "../assets/images/icons/add.svg";
import Icon_add_active from "../assets/images/icons/add-active.svg";
import Icon_timer from "../assets/images/icons/timer.svg";
import { Link } from "react-router-dom";
import moment from "moment";
import Countdown, { zeroPad } from "react-countdown";
import styles from "./videoList.module.css";
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Controller } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import Icon_arr from "../assets/images/icons/arr-rignt.svg";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {authStore} from "../store/authStore";
import { useQueryClient } from "@tanstack/react-query";
import { Skeleton } from "@mui/material";
import AspectRatio from "@mui/joy/AspectRatio";
import useWish from "../utils/useWish";

/**
 * @param {listData, releaseData, mypage, showAll, handleBrowseMoreSlide, totalLength} props
 *
 * listData : [array] 비디오 리스트 데이터 * 필수
 * releaseData : [array] 출시 예정 비디오 리스트 데이터 (마이페이지용)
 * mypage : [boolean] 현재 비디오 리스트가 마이페이지에서 진입한 것인지 판단
 * showAll : [boolean] 현재 비디오 리스트가 전체보기에서 진입한 것인지 판단 : CSS 레이아웃이 다름
 * totalLength : [number] 해당 카테고리에 해당되는 비디오 전체 갯수
 */
export default function VideoList(props) {
  const { isLogin, userAge, username } = authStore();

  /** useQuery로 찜 목록 캐싱 */
  const {wishList, requestWishItem, isWishVideo} = useWish();


  const listData = props.listData || null;
  const categoryId = props.categoryId;
  const releaseData = props.releaseData;
  const mypage = props.mypage || null;
  const showAll = props.showAll || null;
  const handleBrowseMoreSlide = props.handleBrowseMoreSlide;
  const totalLength = props.totalLength;

  const swiperRef = useRef();

  const [slidePerView, setSlidePerView] = useState(null);
  const [prevButtonDisable, setPrevButtonDisable] = useState(true);
  const [nextButtonDisable, setNextButtonDisable] = useState(false);

  /** 모바일 (~744) 에서 한 화면에 몇개 씩 보여줄 지 */
  const SLIDE_PER_VIEW_MOBILE = 1;
  /** PC/태블릿(745~)에서 한 화면에 몇개 씩 보여줄 지 */
  const SLIDE_PER_VIEW_PC = 3;

  /** 비디오 더 불러올 때 한번에 몇개씩 가져올지 */
  const BUNCH = 10;

  /** 화면 리사이징에 따른 슬라이드 버튼 설정 */
  const handleResize = () => {
    if (window.innerWidth < 744) {
      setSlidePerView(SLIDE_PER_VIEW_MOBILE);
    } else {
      setSlidePerView(SLIDE_PER_VIEW_PC);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.addEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // 화면에 따른 슬라이드 개수 초기 설정
    if (matchMedia("screen and (min-width: 744px)").matches) {
      setSlidePerView(SLIDE_PER_VIEW_PC);
    } else {
      setSlidePerView(SLIDE_PER_VIEW_MOBILE);
    }

    if (slidePerView) {
      if (totalLength <= slidePerView) {
        setNextButtonDisable(true);
      } else {
        setNextButtonDisable(false);
      }

      if(mypage) {
        console.log("마이페이지~!")
        if (listData.length <= slidePerView) {
          setNextButtonDisable(true);
        } else {
          setNextButtonDisable(false);
        }
      }
    }
  }, [slidePerView]);

  const queryClient = useQueryClient();


  // 찜
  function handleWishClick(e, list) {
    const uuid = list.uuid;
    const id = list.id;
    e.preventDefault();

    // 찜한 비디오 리스트 에서, 클릭한 video id와 일치하는 항목의 찜 비디오 ID를 발굴
    const targetWishVideoListItem = wishList?.filter((listItem) => listItem?.video?.id === id);

    // 현재 클릭한 비디오가 찜한 비디오면 -> 찜 취소 / 찜한 비디오가 아니면 -> 찜 요청
    if (isWishVideo(id)) {
      requestWishItem(targetWishVideoListItem[0].id, "DELETE");
      return;
    }
    requestWishItem(uuid, "POST");
  }




  // 출시예정작인가?
  const isReleaseVideo = (id) => {
    return releaseData?.some((item) => item.id === id);
  };

  const isNewVideo = (createdAt) => {
    // 오늘 - 출시일 <= 15
    const result = moment().diff(moment(createdAt), "days");
    if (result <= 15) {
      return true;
    } else {
      return false;
    }
  };

  const isFreeVideo = (videoCoin) => {
    if (videoCoin === 0) {
      return true;
    } else {
      return false;
    }
  };

  function calculateDate(paymentTime) {
    const expireTime = moment(paymentTime).add(3, "days").add(9, "hours").valueOf();

    const today = moment().valueOf();

    const result = expireTime - today;

    return Date.now() + result;
  }

  const renderer = ({ days, hours, minutes, seconds }) => (
    <span>
      {zeroPad(days)}일&nbsp;
      {zeroPad(hours)}시간 남음
    </span>
  );

  /**
   * swiper에서 기본으로 제공하는 navigation 버튼은 커스텀하기 힘들어서
   * 따로 element에 이벤트 붙여서 데이터 더 불러오기 등 컨트롤함.
   */
  const handlePrevSlide = (page) => {
    swiperRef.current.slidePrev();
  };

  const handleNextSlide = () => {
    swiperRef.current.slideNext();
  };

  /** 슬라이드가 변경될 때 동작 (prev/next button click or touch move) */
  const slideChangeHandler = (swiper) => {
    const { activeIndex, previousIndex } = swiper;
    /** 지금 슬라이드의 total length */
    const totalSlideLength = listData.length;

    if (activeIndex === 0) {
      setPrevButtonDisable(true);
    } else {
      setPrevButtonDisable(false);
    }

    if (activeIndex >= totalLength - slidePerView) {
      setNextButtonDisable(true);
    } else {
      setNextButtonDisable(false);
    }

    if (previousIndex < activeIndex) {
      console.log("NEXT!");

      // 데이터 모두 불러왔다면 데이터 안불러옴
      if (totalLength === totalSlideLength) {
        return;
      }

      // 현재 슬라이드가 불러와진 슬라이드 중 *마지막 또는 직전* 이면 컨텐츠 더 불러오기
      if (activeIndex === totalSlideLength - slidePerView - 1 || activeIndex === totalSlideLength - slidePerView) {
        console.log("🚀 업데이트 🚀");
        handleBrowseMoreSlide && handleBrowseMoreSlide(categoryId, totalSlideLength + BUNCH);
      }
    } else {
      console.log("PREV!");
    }
  };

  return (
    <div className={`${styles.listFrame} video-list-swiper`}>
      {showAll ? (
        <ul className="listAll">
          {listData.length
            ? listData?.map((list, index) => (
                <li key={`${list?.title}-${list.id}`}>
                  <Link
                    to={list?.servicePolicyAgeLevel === "ADULT" && userAge === "TEENAGER" ? "/authentication-request" : `/video/${list?.uuid}`}
                    state={{
                      data: list,
                      wishVideoList: wishList,
                    }}
                  >
                    <div className={userAge !== "ADULT" && list?.servicePolicyAgeLevel === "ADULT" ? `${styles.thumbnailBlur}` : `${styles.thumbnail}`}>
                      <img src={`${list?.thumbnailUrl}?w=560&h=315&q=100`} alt="thumbnail" loading={index < 8 ? "eager" : "lazy"} />
                      {isNewVideo(list?.createdAt) && (
                        <div className={styles.videoTags}>
                          <div className={styles.tagLeft}>새로운 에피소드</div>
                          <div className={styles.tagRight}>{isFreeVideo(list?.coin) ? "무료 시청하기" : "지금 시청하기"}</div>
                        </div>
                      )}
                    </div>
                    <div className={styles.info}>
                      <div className={styles.infoHead}>
                        <div className={styles.title}>{list.title}</div>
                        <div className={styles.detailInfo}>
                          <div className={styles.price}>
                            <img src={Icon_coin} alt="coin" />
                            <span>{list?.coin}</span>
                          </div>
                          {isLogin && (
                            <button className={styles.add}>
                              <img
                                src={isWishVideo(list.id) ? Icon_add_active : Icon_add}
                                alt="add"
                                onClick={(e) => {
                                  handleWishClick(e, list);
                                }}
                              />
                            </button>
                          )}
                        </div>
                      </div>
                      <span className={styles.infoBody}>
                        <div className={styles.tags}>
                          {list?.tags
                            ?.replace(/\s/g,"")
                            .split(",")
                            .map((item) => (
                              <span className={styles.tag} key={item + "-tag-key2"}>
                                {item}
                              </span>
                            ))}
                        </div>
                      </span>
                    </div>
                  </Link>
                </li>
              ))
            : // Skeleton Component /스트리밍
              Array.from({ length: 3 }).map((list, index) => {
                return (
                  <li key={`${list}-${index}`} style={{ display: "flex", flexDirection: "column" }}>
                    <AspectRatio
                      ratio="16/9"
                      variant="plain"
                      objectFit="cover"
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Skeleton sx={{ width: "100%", height: "100px", bgcolor: "grey.900", WebkitTransform: "unset" }}></Skeleton>
                    </AspectRatio>
                    <Skeleton sx={{ width: "100%", bgcolor: "grey.900", WebkitTransform: "unset", height: "24px", marginTop: "10px" }} />
                    <Skeleton sx={{ width: "60%", bgcolor: "grey.900", WebkitTransform: "unset", height: "18px", marginTop: "10px" }} />
                  </li>
                );
              })}
        </ul>
      ) : (

        <div style={{ position: "relative" }}>
          <div className={prevButtonDisable ? `custom-navigation-prev disabled` : `custom-navigation-prev`} onClick={handlePrevSlide}>
            <img src={Icon_arr} alt="PREV" />
          </div>
          <div className={nextButtonDisable ? `custom-navigation-next disabled` : `custom-navigation-next`} onClick={handleNextSlide}>
            <img src={Icon_arr} alt="NEXT" />
          </div>
          <Swiper
            modules={[Scrollbar, Controller]}
            allowTouchMove={true}
            slidesPerView={SLIDE_PER_VIEW_MOBILE + 0.2}
            spaceBetween={8}
            breakpoints={{
              1440: {
                slidesPerView: SLIDE_PER_VIEW_PC + 0.2,
                spaceBetween: 16,
              },
              744: {
                slidesPerView: SLIDE_PER_VIEW_PC + 0.2,
                spaceBetween: 8,
              },
            }}
            onSwiper={(swiper) => {
              /** swiperRef Mount */
              swiperRef.current = swiper;
            }}
            onSlideChange={(swiper) => {
              slideChangeHandler(swiper);
            }}
          >
            {/* props.mypage = true / 마이페이지용 리스트인 경우 */}
            {listData.length && listData
              ? mypage
                ? listData?.map((list, index) => (
                    <SwiperSlide key={list.id + "-video-key" + index}>
                      <Link
                        to={
                          list.servicePolicyAgeLevel === "ADULT" && userAge === "TEENAGER"
                            ? "/authentication-request"
                            : `/video/${list.video ? list.video.uuid : list.uuid}`
                        }
                        state={{
                          data: list.video || list,
                          wishVideoList: wishList,
                        }}
                        onClick={(e) => {
                          console.log(list.id);
                          console.log("isReleaseVideo(list.id)");
                          console.log(isReleaseVideo(list?.video?.id));
                          if (isReleaseVideo(list?.video?.id)) {
                            e.preventDefault();
                          }
                        }}
                      >
                        <div
                          className={
                            (userAge !== "ADULT" && list?.servicePolicyAgeLevel) || (userAge !== "ADULT" && list?.video?.servicePolicyAgeLevel === "ADULT")
                              ? `${styles.thumbnailBlur}`
                              : `${styles.thumbnail}`
                          }
                        >
                          <img src={(list.video ? `${list.video?.thumbnailUrl}?w=560&h=315&q=100` : `${list.thumbnailUrl}?w=560&h=315&q=100`)} alt="thumbnail" />
                        </div>
                        <div className={styles.info}>
                          <span className={styles.infoHead}>
                            <div className={styles.title}>{list.video ? list.video?.title : list.title}</div>
                          </span>
                        </div>
                        {list?.paymentTime && (
                          <p
                            style={{
                              display: "flex",
                              gap: "4px",
                              marginBottom: 0,
                              color: "#FF0055",
                            }}
                          >
                            <img src={Icon_timer} alt="timer" />
                            <span style={{ fontWeight: 600 }}>
                              <Countdown date={calculateDate(list.paymentTime)} renderer={renderer} />
                            </span>
                          </p>
                        )}
                        {isReleaseVideo(list.video?.id) && (
                          <p
                            style={{
                              display: "flex",
                              gap: "4px",
                              marginBottom: 0,
                              color: "#FF0055",
                            }}
                          >
                            <img src={Icon_timer} alt="timer" />
                            <span style={{ fontWeight: 600 }}>출시 예정작</span>
                          </p>
                        )}
                      </Link>
                    </SwiperSlide>
                  ))
                : listData?.map((list, index) => (
                    <SwiperSlide key={list.id + "-video-key" + index}>
                      <Link
                        to={list.servicePolicyAgeLevel === "ADULT" && userAge === "TEENAGER" ? "/authentication-request" : `/video/${list.uuid}`}
                        state={{
                          data: list,
                          wishVideoList: wishList,
                        }}
                        style={{ display: "block", marginBottom: 16 }}
                      >
                        <div className={userAge !== "ADULT" && list?.servicePolicyAgeLevel === "ADULT" ? `${styles.thumbnailBlur}` : `${styles.thumbnail}`}>
                          <img src={`${list.thumbnailUrl}?w=560&h=315&q=100`} alt="thumbnail" loading={index < 8 ? "eager" : "lazy"} />
                          {isNewVideo(list.createdAt) && (
                            <div className={styles.videoTags}>
                              <div className={styles.tagLeft}>새로운 에피소드</div>
                              <div className={styles.tagRight}>{isFreeVideo(list.coin) ? "무료 시청하기" : "지금 시청하기"}</div>
                            </div>
                          )}
                        </div>
                        <div className={styles.info}>
                          <div className={styles.infoHead}>
                            <div className={styles.title}>{list.title}</div>
                            <div className={styles.detailInfo}>
                              <div className={styles.price}>
                                <img src={Icon_coin} alt="coin" />
                                <span>{list.coin}</span>
                              </div>
                              {isLogin && (
                                <button className={styles.add}>
                                  <img
                                    src={isWishVideo(list.id) ? Icon_add_active : Icon_add}
                                    alt="add"
                                    onClick={(e) => {
                                      handleWishClick(e, list);
                                    }}
                                  />
                                </button>
                              )}
                            </div>
                          </div>
                          <span className={styles.infoBody}>
                            <div className={styles.tags}>
                              {list?.tags
                                ?.replace(/\s/g,"")
                                .split(",")
                                .map((item) => (
                                  <span className={styles.tag} key={item + "-tag-key2"}>
                                    {item}
                                  </span>
                                ))}
                            </div>
                          </span>
                        </div>
                      </Link>
                    </SwiperSlide>
                  ))
              : // Skeleton Component /스트리밍
                Array.from({ length: 4 }).map((list, index) => {
                  return (
                    <SwiperSlide style={{ display: "flex", flexDirection: "column" }} key={`slide-${list}`}>
                      <AspectRatio
                        ratio="16/9"
                        variant="plain"
                        objectFit="cover"
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Skeleton sx={{ width: "100%", height: "100px", bgcolor: "grey.900", WebkitTransform: "unset" }}></Skeleton>
                      </AspectRatio>
                      <Skeleton sx={{ width: "100%", bgcolor: "grey.900", WebkitTransform: "unset", height: "24px", marginTop: "10px" }} />
                      <Skeleton sx={{ width: "60%", bgcolor: "grey.900", WebkitTransform: "unset", height: "18px", marginTop: "10px" }} />
                    </SwiperSlide>
                  );
                })}
          </Swiper>
        </div>
      )}
    </div>
  );
}
