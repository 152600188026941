import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Icon_adult from "../../assets/images/icons/icon_adult.svg";
import AuthApiService from "../../services/AuthApiService";
import { authStore } from "../../store/authStore";
import getUserAgeByJwt from "../../utils/getUserAgeByJwt";

export default function Authentication() {
  const { refreshToken, setAccessToken, setRefreshToken, setUserAge, setAuthDate } = authStore();
  const navigate = useNavigate();

  useEffect(() => {
    updateUserInfo();
  },[])

  const updateUserInfo = () => {

    const data = { refresh: refreshToken };

    AuthApiService.reqAccessToken(data)
      .then((res) => {
        console.log(res);
        const accessToken = res.headers.authorization;
        const refreshToken = res.headers.refresh;
        const userAge = getUserAgeByJwt(accessToken) || null;

        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
        setUserAge(userAge);

        const authDate = res.data.result.selfAuthDate;
        const formattedAuthDate = moment(authDate).add(9, "h").format("YYYY-MM-DD");

        if (authDate) {
          console.log("authDate")
          console.log(authDate)
          setAuthDate(formattedAuthDate);
        } else {
          setAuthDate(null);
        }

      })
      .catch((err) => console.log(err));
  }

  const handleSubmit = () => {
    navigate("/home", { replace: true });
  };
  return (
    <div id="join">
      <div className="container">
        <div className="row gx-2 gx-sm-2 gx-md-3">
          <div className="col-12 col-md-6 col-md-4 mx-auto">
            <div>
              <div
                className="mb-4"
                style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 8, marginTop: 120 }}
              >
                <img src={Icon_adult} alt="adult" style={{ display: "block", width: 80 }} />
                <h2 className="mt-3">룰루플레이 회원 본인 인증</h2>
                <p className="text-center">본인 인증이 완료되었습니다.</p>
              </div>
              <button className="button" onClick={handleSubmit}>
                확인
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
