import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";


export default function Minor() {


  
  return (
    <div id="join">
      <div className="container">
        <div className="row gx-2 gx-sm-2 gx-md-3">
          <div className="col-12 col-md-6 col-md-4 mx-auto">
            <div className="mb-4" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: 8, marginTop: 120 }}>
              <h2 className="mt-3">성인 인증 결과</h2>
              <p className="text-center">19세 미만은 성인 컨텐츠를 열람할 수 없습니다.</p>
            </div>
            <Link className="button" to="/home">
              확인
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}