import React from "react";
import { Helmet } from "react-helmet-async";

export default function PrivacyPolicy() {
  return (
    <>
      <Helmet>
        <title>개인정보 처리방침 - 룰루플레이TV</title>
      </Helmet>
      <div className="container">
        <div className="row gx-2 gx-sm-2 gx-md-3">
          <div className="col-12 col-md-12 col-md-12 mx-auto">
            <h3 className="mt-5">개인정보처리방침</h3>
            <div>
              <div>
                <span>
                  “(주)호윤”이 취급하는 모든 개인정보는 관련 법령에
                  근거하거나 정보주체의 동의에 의하여 수집ㆍ보유 및 처리되고
                  있습니다.
                </span>
              </div>
              <div>
                <span>
                  “(주)호윤”은 법령의 규정에 따라 수집ㆍ보유 및 처리하는
                  개인정보를 업무의 적절한 수행과 정보주체의 권익을 보호하기
                  위하여 적법하고 적정하게 취급할 것입니다. “(주)호윤”의
                  개인정보 처리방침은 「개인정보보호법」에 근거를 두고 있으며
                  정보통신서비스제공자가 준수하여야 할 관련 법령상의
                  개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보
                  처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.
                </span>
              </div>
              <div>
                <span>개인정보의 수집범위</span>
              </div>
              <div>
                <span>
                  (주)호윤의 회원제 서비스를 이용하시고자 할 경우 다음의
                  정보를 입력해주셔야 하며 선택항목을 입력하시지 않았다 하여
                  입력했을 때와의 이용에 차이가 없습니다.
                </span>
              </div>
              <div>
                <span>1) 회원 가입시 수집하는 개인정보의 범위</span>
              </div>
              <div>
                <span>
                  - 필수항목 : 희망 ID, 비밀번호, 성명, 전화번호, 이메일주소
                </span>
              </div>
              <div>
                <span>
                  2) 유료정보 이용 시 또는 문서등록 시 수집하는 개인정보의 범위
                </span>
              </div>
              <div>
                <span>
                  - 본인확인 정보로써 ID와 성명, 휴대전화번호
                </span>
              </div>
              <div>
                <span>- 결제방법에 따라</span>
              </div>
              <div>
                <span>
                  ∵ 신용카드종류, 카드번호, 유효기한, 비밀번호, 할부기간
                </span>
              </div>
              <div>
                <span>∵ 휴대폰번호</span>
              </div>
              <div>
                <span>3) 개인정보의 수집방법</span>
              </div>
              <div>
                <span>
                  홈페이지(회원가입, 비회원구매, 상담, 게시판)와 팩스, 전화,
                  이메일, 제휴사로부터의 제공, 생성정보 수집틀을 통해 개인정보를
                  수집하고 있습니다.
                </span>
              </div>
              <div>
                <span>개인정보 수집에 대한 동의</span>
              </div>
              <div>
                <span>
                  “(주)호윤”은 귀하께서 “(주)호윤”의
                  개인정보보호방침 또는 이용약관의 내용에 대해 「동의한다」버튼
                  또는「동의하지 않는다」버튼을 클릭할 수 있는 절차를 마련하여,
                  「동의한다」버튼을 클릭하면 개인정보 수집에 대해 동의한 것으로
                  봅니다.
                </span>
              </div>
              <div>
                <span>개인정보의 수집목적 및 이용목적</span>
              </div>
              <div>
                <span>
                  ① “(주)호윤”은 다음과 같은 목적을 위하여 개인정보를
                  수집하고 있습니다.
                </span>
              </div>
              <div>
                <span>
                  - 성명, 아이디, 비밀번호 : 회원제 서비스 이용에 따른 본인 식별
                  절차에 이용
                </span>
              </div>
              <div>
                <span>
                  - 이메일주소, 전화번호 : 고지사항 전달, 본인 의사 확인, 불만
                  처리 등 원활한 의사소통 경로의 확보, 새로운 서비스/신상품이나
                  이벤트 정보의 안내
                </span>
              </div>
              <div>
                <span>
                  - 신용카드정보 : 유료정보 이용에 대한 요금
                  결제용
                </span>
              </div>
              <div>
                <span>
                  - 주소, 전화번호 : 청구서, 경품과 쇼핑 물품 배송에 대한 정확한
                  배송지의 확보
                </span>
              </div>
              <div>
                <span>
                  - 그 외 선택항목 : 개인맞춤 서비스를 제공하기 위한 자료
                </span>
              </div>
              <div>
                <span>
                  ② 단, 이용자의 기본적 인권 침해의 우려가 있는 민감한
                  개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적
                  성향 및 범죄기록, 건강상태 등)는 수집하지 않습니다.
                </span>
              </div>
              <div>
                <span>쿠키에 의한 개인정보 수집</span>
              </div>
              <div>
                <span>① 쿠키(cookie)란?</span>
              </div>
              <div>
                <span>
                  “(주)호윤”은 귀하에 대한 정보를 저장하고 수시로 찾아내는
                  ‘쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트가 귀하의 컴퓨터
                  브라우저(넷스케이프, 인터넷 익스플로러 등)로 전송하는 소량의
                  정보입니다. 귀하께서 웹사이트에 접속을 하면 “(주)호윤”의
                  컴퓨터는 귀하의 브라우저에 있는 쿠키의 내용을 읽고, 귀하의
                  추가정보를 귀하의 컴퓨터에서 찾아 접속에 따른 성명 등의 추가
                  입력 없이 서비스를 제공할 수 있습니다. 쿠키는 귀하의 컴퓨터는
                  식별하지만 귀하를 개인적으로 식별하지는 않습니다. 또한 귀하는
                  쿠키에 대한 선택권이 있습니다. 웹브라우저 상단의 도구 &gt;
                  인터넷옵션 탭(option tab)에서 모든 쿠키를 다 받아들이거나,
                  쿠키가 설치될 때 통지를 보내도록 하거나, 아니면 모든 쿠키를
                  거부할 수 있는 선택권을 가질 수 있습니다.
                </span>
              </div>
              <div>
                <span>② “(주)호윤”의 쿠키(cookie) 운용</span>
              </div>
              <div>
                <span>
                  “(주)호윤”은 이용자의 편의를 위하여 쿠키를 운영합니다.
                  “(주)호윤”이 쿠키를 통해 수집하는 정보는
                  “(주)호윤”회원 ID에 한하며, 그 외의 다른 정보는 수집하지
                  않습니다. “(주)호윤”이 쿠키(cookie)를 통해 수집한 회원
                  ID는 다음의 목적을 위해 사용됩니다.
                </span>
              </div>
              <div>
                <span>
                  - 쇼핑한 품목들에 대한 정보와 관심있게 둘러본 품목들에 대한
                  자취를 추적하여 다음번 쇼핑 때 개인 맞춤 서비스를 제공
                </span>
              </div>
              <div>
                <span>- 게시판 글 등록</span>
              </div>
              <div>
                <span>쿠키는 브라우저의 종료시나 로그아웃시 만료됩니다.</span>
              </div>
              <div>
                <span>목적외 사용 및 제3자에 대한 제공 및 공유</span>
              </div>
              <div>
                <span>
                  ① “(주)호윤”은 귀하의 개인정보를 「개인정보의 수집목적
                  및 이용목적」에서 고지한 범위내에서 사용하며, 동 범위를
                  초과하여 이용하거나 타인 또는 타기업,기관에 제공하지 않습니다.
                </span>
              </div>
              <div>
                <span>
                  특히 다음의 경우는 주의를 기울여 개인정보를 이용 및 제공할
                  것입니다.
                </span>
              </div>
              <div>
                <span>
                  - 제휴관계 : 보다 나은 서비스 제공을 위하여 귀하의 개인정보를
                  제휴사에게 제공하거나 또는 제휴사와 공유할 수 있습니다.
                  개인정보를 제공하거나 공유할 경우에는 사전에 귀하께 제휴사가
                  누구인지, 제공 또는 공유되는 개인정보항목이 무엇인지, 왜
                  그러한 개인정보가 제공되거나 공유되어야 하는지, 그리고
                  언제까지 어떻게 보호,관리되는지에 대해 개별적으로 전자우편 및
                  서면을 통해 고지하여 동의를 구하는 절차를 거치게 되며,
                  귀하께서 동의하지 않는 경우에는 제휴사에게 제공하거나 제휴사와
                  공유하지 않습니다. 제휴관계에 변화가 있거나 제휴관계가 종결될
                  때도 같은 절차에 의하여 고지하거나 동의를 구합니다.
                </span>
              </div>
              <div>
                <span>
                  - 위탁 처리 : 원활한 업무 처리를 위해 이용자의 개인정보를 위탁
                  처리할 경우 반드시 사전에 위탁처리 업체명과 위탁 처리되는
                  개인정보의 범위, 업무위탁 목적, 위탁 처리되는 과정, 위탁관계
                  유지기간 등에 대해 상세하게 고지합니다.
                </span>
              </div>
              <div>
                <span>
                  - 매각/인수합병 등 : 서비스제공자의 권리와 의무가 완전
                  승계,이전되는 경우 반드시 사전에 정당한 사유와 절차에 대해
                  상세하게 고지할 것이며 이용자의 개인정보에 대한 동의 철회의
                  선택권을 부여합니다.
                </span>
              </div>
              <div>
                <span>
                  ② 고지 및 동의방법은 온라인 홈페이지 초기화면의 공지사항을
                  통해 최소 10일 이전부터 고지함과 동시에 이메일 등을 이용하여
                  1회 이상 개별적으로 고지합니다.
                </span>
              </div>
              <div>
                <span>③ 다음은 예외로 합니다.</span>
              </div>
              <div>
                <span>
                  - 관계법령에 의하여 수사상의 목적으로 관계기관으로부터의
                  요구가 있을 경우
                </span>
              </div>
              <div>
                <span>
                  - 통계작성/학술연구나 시장조사를 위하여 특정 개인을 식별할 수
                  없는 형태로 광고주,협력사나 연구단체 등에 제공하는 경우
                </span>
              </div>
              <div>
                <span>
                  - 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우
                </span>
              </div>
              <div>
                <span>
                  - 그러나 예외 사항에서도 관계법령에 의하거나 수사기관의 요청에
                  의해 정보를 제공한 경우에는 이를 당사자에게 고지하는 것을
                  원칙으로 운영하고 있습니다. 법률상의 근거에 의해 부득이하게
                  고지를 하지 못할 수도 있습니다. 본래의 수집목적 및 이용목적에
                  반하여 무분별하게 정보가 제공되지 않도록 최대한
                  노력하겠습니다.
                </span>
              </div>
              <div>
                <span>개인정보의 열람, 정정</span>
              </div>
              <div>
                <span>
                  ① 귀하는 언제든지 등록되어 있는 귀하의 개인정보를 열람하거나
                  정정하실 수 있습니다. 개인정보 열람 및 정정을 하고자 할
                  경우에는 『개인정보변경』을 클릭하여 직접 열람 또는
                  정정하거나, 개인정보관리책임자 및 담당자에게 서면, 전화 또는
                  E-mail로 연락하시면 지체없이 조치하겠습니다.
                </span>
              </div>
              <div>
                <span>
                  ② 귀하가 개인정보의 오류에 대한 정정을 요청한 경우, 정정을
                  완료하기 전까지 당해 개인 정보를 이용 또는 제공하지 않습니다.
                </span>
              </div>
              <div>
                <span>
                  ③ 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정
                  처리결과를 제3자에게 지체없이 통지하여 정정하도록
                  조치하겠습니다.
                </span>
              </div>
              <div>
                <span>개인정보 수집, 이용, 제공에 대한 동의철회(회원탈퇴)</span>
              </div>
              <div>
                <span>
                  ① 회원가입 등을 통해 개인정보의 수집, 이용, 제공에 대해
                  귀하께서 동의하신 내용을 귀하는 언제든지 철회하실 수 있습니다.
                  동의철회는 홈페이지 첫 화면의 『동의철회(회원탈퇴)』를
                  클릭하거나 개인정보관리책임자에게 서면, 전화, E-mail등으로
                  연락하시면 즉시 개인정보의 삭제 등 필요한 조치를 하겠습니다.
                  동의 철회를 하고 개인정보를 파기하는 등의 조치를 취한 경우에는
                  그 사실을 귀하께 지체 없이 통지하도록 하겠습니다.
                </span>
              </div>
              <div>
                <span>
                  ② “(주)호윤”은 개인정보의 수집에 대한
                  동의철회(회원탈퇴)를 개인정보를 수집하는 방법보다 쉽게 할 수
                  있도록 필요한 조치를 취하겠습니다.
                </span>
              </div>
              <div>
                <span>개인정보의 보유기간 및 이용기간</span>
              </div>
              <div>
                <span>
                  ① 귀하의 개인정보는 다음과 같이 개인정보의 수집목적 또는
                  제공받은 목적이 달성되면 파기됩니다. 단, 상법 등 관련법령의
                  규정에 의하여 다음과 같이 거래 관련 권리 의무 관계의 확인 등을
                  이유로 일정기간 보유하여야 할 필요가 있을 경우에는 일정기간
                  보유합니다.
                </span>
              </div>
              <div>
                <span>
                  - 회원가입정보의 경우, 회원가입을 탈퇴하거나 회원에서 제명된
                  경우 등 일정한 사전에 보유목적, 기간 및 보유하는 개인 정보
                  항목을 명시하여 동의를 구합니다.
                </span>
              </div>
              <div>
                <span>- 계약 또는 청약철회 등에 관한 기록 : 5년</span>
              </div>
              <div>
                <span>- 대금결제 및 재화등의 공급에 관한 기록 : 5년</span>
              </div>
              <div>
                <span>- 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</span>
              </div>
              <div>
                <span>
                  ② 귀하의 동의를 받아 보유하고 있는 거래정보 등을 귀하께서
                  열람을 요구하는 경우 “(주)호윤”은 지체 없이 그 열람,확인
                  할 수 있도록 조치합니다.
                </span>
              </div>
              <div>
                <span>
                  ③ 회사는 귀중한 회원의 개인정보를 안전하게 처리하며, 유출의
                  방지를 위하여 다음과 같은 방법을 통하여 개인정보를 파기합니다.
                  - 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
                  파기합니다. - 전자적 파일 형태로 저장된 개인정보는 기록을
                  재생할 수 없는 기술적 방법을 사용하여 삭제합니다
                </span>
              </div>
              <div>
                <span>개인정보의 도용시 대처방법</span>
              </div>
              <div>
                <span>
                  ① “(주)호윤”은 이용자가 타인의 ID 및 기타 개인정보를
                  도용하여 회원가입 등을 하였음을 알게 된 때 지체 없이 해당
                  아이디에 대한 서비스 이용정지 또는 회원탈퇴 등 필요한 조치를
                  취하고 있습니다.
                </span>
              </div>
              <div>
                <span>
                  또 자신의 개인정보 도용을 인지한 이용자가 해당 아이디에 대한
                  서비스 이용정지 또는 회원 탈퇴를 요구하는 경우에도
                  “(주)호윤”은 즉시 조취를 취하고 있습니다.
                </span>
              </div>
              <div>
                <span>- 개인정보 신고 안내</span>
              </div>
              <div>
                <span>개인정보보호를 위한 기술 및 관리적 대책</span>
              </div>
              <div>
                <span>① 기술적 대책</span>
              </div>
              <div>
                <span>
                  “(주)호윤”은 귀하의 개인정보를 취급함에 있어 개인정보가
                  분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를
                  위하여 다음과 같은 기술적 대책을 강구하고 있습니다.
                </span>
              </div>
              <div>
                <span>
                  - 귀하의 개인정보는 비밀번호에 의해 보호되며 파일 및
                  전송데이터를 암호화하거나 파일 잠금기능(Lock)을 사용하여
                  중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다.
                </span>
              </div>
              <div>
                <span>
                  - “(주)호윤”은 암호알고리즘을 이용하여 네트워크 상의
                  개인정보를 안전하게 전송할 수 있는 보안장치( SSL )를 채택하고
                  있습니다. 단, 이용자 본인의 부주의나 인터넷상의 문제로 ID,
                  비밀번호, 휴대전화번호 등 개인정보가 유출해 발생한 문제에 대해
                  회사는 일체의 책임을 지지 않습니다.
                </span>
              </div>
              <div>
                <span>
                  - 회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가
                  유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.
                  개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신
                  백신프로그램을 이용하여 이용자들의 개인정보나 자료가
                  누출되거나 손상되지 않도록 방지하고 있습니다.
                </span>
              </div>
              <div>
                <span>② 관리적 대책</span>
              </div>
              <div>
                <span>
                  - “(주)호윤”은 귀하의 개인정보에 대한 접근권한을
                  최소한의 인원으로 제한하고 있습니다. 그 최소한의 인원에
                  해당하는 자는 다음과 같습니다.
                </span>
              </div>
              <div>
                <span>
                  ∵ 이용자를 직접 상대로 하여 마케팅 업무를 수행하는 자
                </span>
              </div>
              <div>
                <span>
                  ∵ 개인정보관리책임자 및 담당자 등 개인정보관리업무를 수행하는
                  자
                </span>
              </div>
              <div>
                <span>∵ 기타 업무상 개인정보의 취급이 불가피한 자</span>
              </div>
              <div>
                <span>
                  - 개인정보를 취급하는 직원을 대상으로 새로운 보안 기술 습득 및
                  개인정보 보호 의무 등에 관해 정기적인 사내 교육 및 외부
                  위탁교육을 실시하고 있습니다.
                </span>
              </div>
              <div>
                <span>
                  - 입사 시 전 직원의 보안서약서를 통하여 사람에 의한 정보유출을
                  사전에 방지하고 개인정보처리방침에 대한 이행사항 및 직원의
                  준수여부를 감사하기 위한 내부절차를 마련하고 있습니다.
                </span>
              </div>
              <div>
                <span>
                  - 개인정보 관련 취급자의 업무 인수인계는 보안이 유지된
                  상태에서 철저하게 이뤄지고 있으며 입사 및 퇴사 후 개인정보
                  사고에 대한 책임을 명확화하고 있습니다.
                </span>
              </div>
              <div>
                <span>
                  - 개인정보와 일반 데이터를 혼합하여 보관하지 않고 별도의
                  서버를 통해 분리하여 보관하고 있습니다.
                </span>
              </div>
              <div>
                <span>
                  - 전산실 및 자료 보관실 등을 특별 보호구역으로 설정하여 출입을
                  통제하고 있습니다.
                </span>
              </div>
              <div>
                <span>
                  - “(주)호윤”은 이용자 개인의 실수나 기본적인 인터넷의
                  위험성 때문에 일어나는 일들에 대해 책임을 지지 않습니다.
                </span>
              </div>
              <div>
                <span>
                  회원 개개인이 본인의 개인정보를 보호하기 위해서 자신의 ID 와
                  비밀번호를 적절하게 관리하고 여기에 대한 책임을 져야 합니다.
                </span>
              </div>
              <div>
                <span>
                  - 그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해
                  개인정보의 상실, 유출, 변조, 훼손이 유발될 경우
                  “(주)호윤”은 즉각 귀하께 사실을 알리고 적절한 대책과
                  보상을 강구할 것입니다.
                </span>
              </div>
              <div>
                <span>링크사이트</span>
              </div>
              <div>
                <span>
                  “(주)호윤”은 귀하께 다른 회사의 웹사이트 또는 자료에
                  대한 링크를 제공할 수 있습니다.
                </span>
              </div>
              <div>
                <span>
                  -이 경우 “(주)호윤”은 외부사이트 및 자료에 대한 아무런
                  통제권이 없으므로 그로부터 제공받는 서비스나 자료의 유용성에
                  대해 책임질 수 없으며 보증할 수 없습니다. “(주)호윤”이
                  포함하고 있는 링크를 클릭(click)하여 타 사이트(site)의
                  페이지로 옮겨갈 경우 해당 사이트의 개인정보처리방침은
                  “(주)호윤”과 무관하므로 새로 방문한 사이트의 정책을
                  검토해 보시기 바랍니다.
                </span>
              </div>
              <div>
                <span>게시물</span>
              </div>
              <div>
                <span>
                  ① “(주)호윤”은 귀하의 게시물을 소중하게 생각하며 변조,
                  훼손, 삭제되지 않도록 최선을 다하여 보호합니다. 그러나 다음의
                  경우는 그렇지 아니합니다.
                </span>
              </div>
              <div>
                <span>
                  - 스팸(spam)성 게시물 (예 : 행운의 편지, 8억 메일, 특정사이트
                  광고 등)
                </span>
              </div>
              <div>
                <span>
                  - 타인을 비방할 목적으로 허위 사실을 유포하여 타인의 명예를
                  훼손하는 글
                </span>
              </div>
              <div>
                <span>
                  - 동의 없는 타인의 신상공개, 제 3자의 저작권 등 권리를
                  침해하는 내용, 기타 게시판 주제와 다른 내용의 게시물
                </span>
              </div>
              <div>
                <span>
                  - 관리자는 바람직한 게시판 문화를 활성화하기 위하여 동의 없는
                  타인의 신상 공개시 특정부분을 삭제하거나 기호 등으로 수정하여
                  게시할 수 있습니다.
                </span>
              </div>
              <div>
                <span>
                  - 다른 주제의 게시판으로 이동 가능한 내용일 경우 해당 게시물에
                  이동 경로를 밝혀 오해가 없도록 하고 있습니다.
                </span>
              </div>
              <div>
                <span>
                  - 그 외의 경우 명시적 또는 개별적인 경고 후 삭제 조치할 수
                  있습니다.
                </span>
              </div>
              <div>
                <span>
                  ② 근본적으로 게시물에 관련된 제반 권리와 책임은 작성자
                  개인에게 있습니다. 또 게시물을 통해 자발적으로 공개된 정보는
                  보호받기 어려우므로 정보 공개 전에 심사숙고하시기 바랍니다.
                </span>
              </div>
              <div>
                <span>개인정보의 위탁처리</span>
              </div>
              <div>
                <span>
                  ① “(주)호윤”은 서비스 향상을 위해서 귀하의 개인정보를
                  외부에 수집/취급/관리등을 위탁하여 처리할 수 있습니다.
                </span>
              </div>
              <div>
                <span>
                  - 개인정보의 처리를 위탁하는 경우에는 위탁기관 및 그 사실을
                  홈페이지를 통해 미리 귀하에게 고지하겠습니다.
                </span>
              </div>
              <div>
                <span>
                  - 개인정보의 처리를 위탁하는 경우에는 위탁계약 등을 통하여
                  서비스제공자의 개인정보보호 관련 지시엄수, 개인정보에 관한
                  비밀유지, 제3자 제공의 금지 및 사고시의 책임부담, 위탁기간,
                  처리 종료 후의 개인정보의 반환 또는 파기 등을 명확히 규정하고
                  당해 계약내용을 서면 또는 전자적으로 보관하겠습니다.
                </span>
              </div>
              <div>
                <span>- 위탁대행 제휴 업무</span>
              </div>
              <div>
                <span>• 본인인증 : 코리아크레딧뷰로(주)</span>
              </div>
              <div>
                <span>• 결제대행 : 웰컴페이먼츠㈜</span>
              </div>
              <div>
                <span>이용자의 권리와 의무</span>
              </div>
              <div>
                <span>
                  ① 귀하의 개인정보를 최신의 상태로 정확하게 입력하여 불의의
                  사고를 예방해 주시기 바랍니다. 이용자가 입력한 부정확한 정보로
                  인해 발생하는 사고의 책임은 이용자 자신에게 있으며 타인 정보의
                  도용 등 허위정보를 입력할 경우 회원자격이 상실될 수 있습니다.
                </span>
              </div>
              <div>
                <span>
                  ② 귀하는 개인정보를 보호받을 권리와 함께 스스로를 보호하고
                  타인의 정보를 침해하지 않을 의무도 가지고 있습니다. 비밀번호를
                  포함한 귀하의 개인정보가 유출되지 않도록 조심하시고 게시물을
                  포함한 타인의 개인정보를 훼손하지 않도록 유의해 주십시오. 만약
                  이 같은 책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할
                  시에는 『정보통신망이용촉진및정보보호등에관한법률』등에 의해
                  처벌받을 수 있습니다.
                </span>
              </div>
              <div>
                <span>의견수렴 및 불만처리</span>
              </div>
              <div>
                <span>
                  ① 당사는 귀하의 의견을 소중하게 생각하며, 귀하는
                  의문사항으로부터 언제나 성실한 답변을 받을 권리가 있습니다.
                </span>
              </div>
              <div>
                <span>
                  ② 당사는 귀하와의 원활환 의사소통을 위해 민원처리센터를
                  운영하고 있으며 연락처는 다음과 같습니다.
                </span>
              </div>
              <div>
                <span>【 민원처리센터 】</span>
              </div>
              <div>
                <span>＠ 전자우편 : luluplaytv@hoyun.co.kr</span>
              </div>
              <div>
                <span>
                  ③ 전자우편이나 팩스 및 우편을 이용한 상담은 접수 후 12시간
                  내에 성실하게 답변 드리겠습니다. 다만, 근무시간 이후 또는 주말
                  및 공휴일에는 익일 처리하는 것을 원칙으로 합니다.
                </span>
              </div>
              <div>
                <span>
                  ④ 기타 개인정보에 관한 상담이 필요한 경우에는
                  개인정보침해신고센터로 문의하실 수 있습니다.
                </span>
              </div>
              <div>
                <span>
                  “(주)호윤”은 청소년이 건전한 인격체로 성장할 수 있도록
                  하기 위하여 정보통신망이용촉진및정보보호등에관한법률 및
                  청소년보호법에 근거하여 청소년 보호정책을 수립, 시행하고
                  있습니다.
                </span>
              </div>
              <div>
                <span>
                  회사는 정보통신윤리위원회 심의규정 및 청소년보호위원회
                  청소년유해매체물 심의규정 기준 등에 따라 19세 미만의
                  청소년들이 유해정보에 접근할 수 없도록 방지하고 있는 바, 본
                  청소년 보호정책을 통하여 회사가 청소년보호를 위해 어떠한
                  조치를 취하고 있는지 알려드립니다.
                </span>
              </div>
              <div>
                <span>1. 유해정보에 대한 청소년접근제한 및 관리조치</span>
              </div>
              <div>
                <span>
                  회사는 청소년이 아무런 제한장치 없이 청소년 유해정보에
                  노출되지 않도록 청소년유해매체물에 대해서는 별도의 인증장치를
                  마련, 적용하며 청소년 유해정보가 노출되지 않기 위한 예방차원의
                  조치를 강구합니다.
                </span>
              </div>
              <div>
                <span>
                  2. 유해정보로부터의 청소년보호를 위한 업무 담당자 교육 시행
                </span>
              </div>
              <div>
                <span>
                  회사는 정보통신업무 종사자를 대상으로 청소년보호 관련 법령 및
                  제재기준, 유해정보 발견시 대처방법, 위반사항 처리에 대한
                  보고절차 등을 교육하고 있습니다.
                </span>
              </div>
              <div>
                <span>개인정보관리책임자 및 담당자</span>
              </div>
              <div>
                <span>
                  “(주)호윤”은 귀하가 좋은 정보를 안전하게 이용할 수
                  있도록 최선을 다하고 있습니다. 개인정보를 보호하는데 있어
                  귀하께 고지한 사항들에 반하는 사고가 발생할 시에
                  개인정보관리책임자가 모든 책임을 집니다.
                </span>
              </div>
              <div>
                <span>
                  그러나 기술적인 보완조치를 했음에도 불구하고, 해킹 등 기본적인
                  네트워크상의 위험성에 의해 발생하는 예기치 못한 사고로 인한
                  정보의 훼손 및 방문자가 작성한 게시물에 의한 각종 분쟁에
                  관해서는 책임이 없습니다.
                </span>
              </div>
              <div>
                <span>
                  귀하의 개인정보를 취급하는 책임자 및 담당자는 다음과 같으며
                  개인정보 관련 문의사항에 신속하고 성실하게 답변해드리고
                  있습니다.
                </span>
              </div>
              <div>
                <span>【 민원처리센터 】</span>
              </div>
              <div>
                <span>＠ 전자우편 : luluplaytv@hoyun.co.kr</span>
              </div>
              <div>
                <span>광고성 정보 전송</span>
              </div>
              <div>
                <span>
                  ① “(주)호윤”은 귀하의 명시적인 수신거부의사에 반하여
                  영리목적의 광고성 정보를 전송하지 않습니다.
                </span>
              </div>
              <div>
                <span>
                  ② “(주)호윤”은 귀하가 뉴스레터 등 전자우편 전송에 대한
                  동의를 한 경우 전자우편의 제목란 및 본문란에 다음 사항과 같이
                  귀하가 쉽게 알아 볼 수 있도록 조치합니다.
                </span>
              </div>
              <div>
                <span>
                  - 전자우편의 제목란 : (광고)라는 문구를 제목란에 표시하지 않을
                  수 있으며 전자우편 본문란의 주요 내용을 표시합니다.
                </span>
              </div>
              <div>
                <span>- 전자우편의 본문란 :</span>
              </div>
              <div>
                <span>
                  ∵ 귀하가 수신거부의 의사표시를 할 수 있는 전송자의 명칭,
                  전자우편주소, 전화번호 및 주소를 명시합니다.
                </span>
              </div>
              <div>
                <span>
                  ∵ 귀하가 수신 거부의 의사를 쉽게 표시할 수 있는 방법을 한글 및
                  영문으로 각 각 명시합니다.
                </span>
              </div>
              <div>
                <span>∵ 귀하가 동의를 한 시기 및 내용을 명시합니다.</span>
              </div>
              <div>
                <span>
                  ③ “(주)호윤”은 상품정보 안내 등 온라인 마케팅을 위해
                  광고성 정보를 전자우편 등으로 전송하는 경우 전자우편의 제목란
                  및 본문란에 다음 사항과 같이 귀하가 쉽게 알아 볼 수 있도록
                  조치합니다.
                </span>
              </div>
              <div>
                <span>
                  - 전자우편의 제목란 : (광고)또는(성인광고)라는 문구를 제목란의
                  처음에 빈칸없이 한글로 표시하고 이어서 전자우편 본문란의 주요
                  내용을 표시합니다.
                </span>
              </div>
              <div>
                <span>- 전자우편의 본문란 :</span>
              </div>
              <div>
                <span>
                  ∵ 귀하가 수신거부의 의사표시를 할 수 있는 전송자의 명칭,
                  전자우편주소, 전화번호 및 주소를 명시합니다.
                </span>
              </div>
              <div>
                <span>
                  ∵ 귀하가 수신 거부의 의사를 쉽게 표시할 수 있는 방법을 한글 및
                  영문으로 각 각 명시합니다.
                </span>
              </div>
              <div>
                <span>
                  ④ 다음과 같이 청소년에게 유해한 정보를 전송하는 경우
                  “(성인광고)” 문구를 표시합니다.
                </span>
              </div>
              <div>
                <span>
                  - 본문란에 다음 각 항목 1에 해당하는 것이 부호,문자,영상 또는
                  음향의 형태로 표현된 경우(해당 전자우편의 본문란에는 직접
                  표현되어있지 아니하더라도 수신자가 내용을 쉽게 확인할 수
                  있도록 기술적 조치가 되어 있는 경우를 포함한다)에 는 해당
                  전자우편의 제목란에 "(성인광고)" 문구를 표시합니다.
                </span>
              </div>
              <div>
                <span>
                  ∵ 청소년(19세미만의 자를 말한다. 이하 같다)에게 성적인 욕구를
                  자극하는 선정적인 것이거나 음란한 것
                </span>
              </div>
              <div>
                <span>
                  ∵ 청소년에게 포악성이나 범죄의 충동을 일으킬 수 있는 것
                </span>
              </div>
              <div>
                <span>
                  ∵ 성폭력을 포함한 각종 형태의 폭력행사와 약물의 남용을
                  자극하거나 미화하는 것
                </span>
              </div>
              <div>
                <span>
                  ∵ 청소년보호법에 의하여 청소년 유해매체물로 결정,고시된 것
                </span>
              </div>
              <div>
                <span>
                  - 영리목적의 광고성 전자우편 본문란에서 제4항 각항목에
                  해당하는 내용을 다룬 인터넷 홈페이지를 알리는 경우에는
                </span>
              </div>
              <div>
                <span>
                  해당 전자우편의 제목란에 “(성인광고)” 문구를 표시합니다.
                </span>
              </div>
              <div>
                <span>
                  ⑤ 팩스/휴대폰 문자전송 등 전자우편 이외의 문자전송을 통해
                  영리목적의 광고성 정보를 전송하는 경우에는 전송내용 처음에
                  “(광고)”라는 문구를 표기하고 전송내용 중에 전송자의 연락처를
                  명시하도록 조치합니다.
                </span>
              </div>
              <br></br>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
