import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet-async";

export default function PaymentsApproval() {
  const params = useParams();
  const location = useLocation();
  
  console.log(location.state)
  // 이전페이지에서 전달받은 선택한 코인 상품 가격과 이름

  const price = location?.state?.price || null;
  const title = location?.state?.title || null;
  const isTicket = location?.state?.isTicket || false;

  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state) {
      alert('잘못된 접근입니다. 다시 시도해주세요.');
      navigate('/payments');
      return;
    }
  }, []);



  const handleSubmit = (e) => {
    e.preventDefault();

    if(isTicket) {
      // 티켓 결제일 시
      if (isMobile) {
        // window.location.href = `${import.meta.env.VITE_APP_API_SERVER_HOST}/payment/mobile/ticket?uuid=${params.uuid}`;
        window.location.href = `${import.meta.env.VITE_APP_API_SERVER_HOST}/payment/mobile/ticket/v2?uuid=${params.uuid}`;
      } else {
        // window.location.href = `${import.meta.env.VITE_APP_API_SERVER_HOST}/payment/pc/ticket?uuid=${params.uuid}`;
        window.location.href = `${import.meta.env.VITE_APP_API_SERVER_HOST}/payment/pc/ticket/v2?uuid=${params.uuid}`;
      }

    } else {
      // 코인 결제일 시
      if (isMobile) {
        // window.location.href = `${import.meta.env.VITE_APP_API_SERVER_HOST}/payment/mobile?uuid=${params.uuid}`;
        window.location.href = `${import.meta.env.VITE_APP_API_SERVER_HOST}/payment/mobile/v2?uuid=${params.uuid}`;
      } else {
        // window.location.href = `${import.meta.env.VITE_APP_API_SERVER_HOST}/payment/pc?uuid=${params.uuid}`;
        window.location.href = `${import.meta.env.VITE_APP_API_SERVER_HOST}/payment/pc/v2?uuid=${params.uuid}`;
      }
    }
  };

  // 모달
  const [failModal, setFailModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  // 모달 open, close, confirm handler
  const handleFailModalClose = () => setFailModal(false);
  const handleFailModalShow = () => setFailModal(true);
  const handleFailModalConfirm = () => {
    // navigate(-1);
    handleFailModalClose();
  };

  const handleSuccessModalClose = () => setSuccessModal(false);
  const handleSuccessModalShow = () => setSuccessModal(true);
  const handleSuccessModalConfirm = () => {
    navigate(-2);
    handleSuccessModalClose();
  };

  return (
    <>
      <Helmet>
        <title>주문서 정보 - 룰루플레이TV</title>
      </Helmet>
      <div id="payments-detail">
        <h3 className="text-center pt-5">주문서 정보</h3>
        <p className="text-center mb-5 text-gray">결제 정보를 확인 후 하단 결제 승인 버튼을 눌러주세요.</p>
        <div className="container">
          <div className="row gx-2 gx-sm-2 gx-md-3">
            <div className="col-12 col-md-6 col-md-4 mx-auto">
              <form name="fm" className="table">
                <ul>
                  <li>
                    <p>상품명</p>
                    <span style={{wordBreak: 'keep-all'}}>
                      {title ? title : "-"}
                      {isTicket ? "" : "개"}
                    </span>
                  </li>
                  <li>
                    <p>승인 금액</p>
                    <span>{price && price?.toLocaleString()}원</span>
                  </li>
                </ul>
                <button className="button mt-5" type="button" onClick={handleSubmit}>
                  결제 요청
                </button>
              </form>
            </div>
          </div>
        </div>

        {/* 성공 모달 */}
        <Modal show={successModal} onHide={handleSuccessModalClose} backdrop="static" keyboard={false} centered>
          <Modal.Body>
            <div className="mt-3">결제에 성공했습니다.</div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={handleSuccessModalConfirm}
              style={{
                height: "56px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span>확인</span>
            </Button>
          </Modal.Footer>
        </Modal>
        {/* 실패 모달 */}
        <Modal show={failModal} onHide={handleFailModalClose} backdrop="static" keyboard={false} centered>
          <Modal.Body>
            <div className="mt-3">결제에 실패했습니다. 다시 시도해주세요.</div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={handleFailModalConfirm}
              style={{
                height: "56px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span>확인</span>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
