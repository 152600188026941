import React from "react";
import Icon_luluplay from "../assets/images/icons/icon_luluplay.svg";
import { Helmet } from "react-helmet-async";

export default function ApplyActor() {

  // 구글 폼 링크
  const handleSubmit = () => {
    window.open("https://forms.gle/xV5FhMiPnjgcWzH18", "_blank");
  }

  return (
    <>
      <Helmet>
        <title>배우신청 - 룰루플레이TV</title>
      </Helmet>

      <div id="community">
        <div className="container">
          <div className="row gx-2 gx-sm-2 gx-md-3">
            <div className="col-12 col-md-10 col-xxl-10 mx-auto">
              <h3 className="pt-5 text-center">배우신청</h3>
              <div className="apply">
                <div className="mt-5">
                  <div style={{textAlign: 'center'}}>
                    <img src={Icon_luluplay} alt="lulutv logo" />
                    <h4 className="mb-4">여러분도 룰루플레이TV의 배우가 될 수 있습니다!</h4>
                    <p>외부 링크로 이동됩니다.</p>
                  </div>
                  <button type="button" className="button" onClick={handleSubmit} style={{maxWidth: 130, margin: '0 auto'}}>
                    신청하기
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </>
  );
}
