import React, { useEffect, useState } from 'react'
import {
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Img_logo from "../../assets/images/logo.png";
import useAuth from '../../Hooks/useAuth'
import {authStore} from "../../store/authStore";
import { Helmet } from 'react-helmet-async';
import Cookies from "universal-cookie";

export default function Login() {
  let location = useLocation();

  const cookies = new Cookies();
  const [userId, setUserId] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [hasAllValue, setHasAllValue] = useState(false);
  const [isRemember, setIsRemember] = useState(false);

  const {signin,signoutOnce} = useAuth();
  const {isLogin} = authStore();
  let from = location.state?.from?.pathname || "/";

  const navigate = useNavigate();
  
  useEffect(() => {
    if (isLogin) {
      navigate('/', {replace: true});
    }

  },[])
  useEffect(() => {
    if (userId != "" && userPassword != "") {
      setHasAllValue(true);
    } else {
      setHasAllValue(false);
    }
  }, [userId, userPassword]);

  useEffect(() => {
    /*저장된 쿠키값이 있으면, CheckBox TRUE 및 UserID에 값 셋팅*/
    if (cookies.get("remember_user_id")) {
      setIsRemember(true);
      setUserId(cookies.get("remember_user_id"));
    } else {
      setIsRemember(false);
    }
  }, []);

  const rememberCheckHandler = (e) => {
    setIsRemember(e.target.checked);
  };
  
  const isValidId = () => {
    const IdRegex = /^(?!(?:[0-9]+)$)([a-zA-Z]|[0-9a-zA-Z]){4,15}$/; // 아이디 : 4~15 영문, 혹은 영문+숫자 조합

    // 아이디 조건이 정규식과 일치하지 않을 때
    if (!IdRegex.test(userId)) {
      alert(
        "올바른 아이디 형식이 아닙니다. (영문 또는 영문, 숫자 조합 4~15 자리)"
      );
      return false;
    }
    return true;
  };

  const isValidPassword = () => {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,16}$/; // 비밀번호 : 영문+숫자 8~16

    // 비밀번호 조건이 정규식과 일치하지 않을 때
    if (!passwordRegex.test(userPassword)) {

      alert("올바른 비밀번호 형식이 아닙니다. (영문, 숫자 8자~16자 사이)");

      return false;
    }
    return true;
  };

  function handleSubmit(event) {
    event.preventDefault();

    // 아이디, 비밀번호 형식에 맞지 않을 시 Modal + error message 노출 + return
    if (!isValidId() || !isValidPassword()) {
      return;
    }

    let formData = new FormData(event.currentTarget);
    let userId = formData.get("userId");
    let userPassword = formData.get("userPassword");
    const userInfo = {
      userId,
      userPassword,
    };
    console.log(userId)


    signin(userInfo, isRemember);
  }

  return (
    <>
      <Helmet>
        <title>로그인 - 룰루플레이TV</title>
      </Helmet>
      <div id="login">
        <div className="container">
          <div className="row gx-2 gx-sm-2 gx-md-3">
            <div className="col-12 col-md-6 col-md-4 mx-auto">
              <div className="main-title">
                {location?.state?.access && <h2>잠깐! 로그인이 필요합니다.</h2>}
                <img className="w-100 mb-32" src={Img_logo} alt="" />
                <p className="text-center">룰루플레이TV에서 서비스하는 모든 영상은 영상물등급위원회에서 심의 과정을 의거하여 제작된 합법적인 콘텐츠입니다.</p>
              </div>
            </div>
          </div>
          <div className="row gx-2 gx-sm-2 gx-md-3">
            <div className="col-12 col-md-6 col-xxl-6 mx-auto">
              <form className="mt-5" onSubmit={handleSubmit}>
                <div className="input-text">
                  <label htmlFor="userId">아이디</label>
                  <input id="userId" name="userId" type="text" placeholder="아이디를 입력하세요." value={userId} onChange={(e) => setUserId(e.target.value)} />
                </div>
                <div className="input-text">
                  <label htmlFor="userPassword">비밀번호</label>
                  <input
                    id="userPassword"
                    name="userPassword"
                    type="password"
                    placeholder="비밀번호를 입력하세요."
                    autoComplete="true"
                    value={userPassword}
                    onChange={(e) => setUserPassword(e.target.value)}
                  />
                </div>
                <div className="input-checkbox mb-40">
                  <input id="saveIdInfo" type="checkbox" checked={isRemember} onChange={rememberCheckHandler} />
                  <label htmlFor="saveIdInfo">아이디 저장하기</label>
                </div>
                <button disabled={!hasAllValue} type="submit" className="button">
                  로그인하기
                </button>
                <div className="d-flex gap-3 mt-3">
                  <Link className="button white" to="/join">
                    회원가입하기
                  </Link>
                </div>

                <div className="find text-gray d-flex justify-content-center gap-1 mt-3">
                  <Link to="/find-id">아이디 찾기</Link>
                  <Link to="/find-password">비밀번호 찾기</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
