
import React from 'react'
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, IconButton, Stack } from '@mui/material';
import { Helmet } from 'react-helmet-async';

export default function Support() {

    const handleCopyClipBoard =  async () => {
      try {
        await navigator.clipboard.writeText("luluplaytv@hoyun.co.kr");
        alert("클립보드에 복사되었습니다.");
      } catch (error) {
        alert("클립보드 복사에 실패하였습니다.");
      }
    };


  return (
    <>
      <Helmet>
        <title>고객센터 - 룰루플레이TV</title>
      </Helmet>
    <div className="container">
      <div className="row gx-2 gx-sm-2 gx-md-3">
        <div className="col-12 col-md-12 col-md-12 mx-auto">
          <h3 className="mt-5">고객센터</h3>
          <div style={{ paddingBottom: 240 }}>
            <Box sx={{ textAlign: "center", wordBreak: "keep-all" }}>
              <div className="mb-2" style={{ opacity: 0.5 }}>
                고객센터 상담 메일 주소
              </div>
              <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} mb={5}>
                <h3 className="mb-0">luluplaytv@hoyun.co.kr</h3>
                <IconButton onClick={handleCopyClipBoard}>
                  <ContentCopyIcon sx={{ color: "white" }} />
                </IconButton>
              </Stack>
              <Box mb={5}>
                <div className="mb-2">
                  룰루플레이에 궁금하신 사항이 있으신가요?
                  <br />
                  룰루플레이 아이디와 문의사항을 남겨주시면 답변해 드리겠습니다.
                </div>
              </Box>
            </Box>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
