import { isIOS } from "react-device-detect";

export function isSamsungBrowser() {
    const userAgent = navigator.userAgent;
    return /SamsungBrowser/i.test(userAgent);
}

export function isIpad() {
    return isIOS && navigator.maxTouchPoints > 0;
}

