import React, { useEffect, useState } from "react";
import Icon_menu from "../assets/images/icons/menu.svg";
import Img_profile from "../assets/images/profile-default.svg";
import Img_logo from "../assets/images/logo.png";
import Icon_arr from "../assets/images/icons/arr.svg";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { authStore } from "../store/authStore";
import useAuth from "../Hooks/useAuth";
import PersonIcon from '@mui/icons-material/Person';
import Dropdown from 'react-bootstrap/Dropdown';
import useCoin from "../utils/useCoin";

export default function Header() {
  const { isLogin, username } = authStore();
  const { signout } = useAuth();

  const { coin } = useCoin();


  const handleLogout = () => {
    signout();
  };

  // Modal function
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);

  return (
    <>
      <nav className="top-nav">
        <div className="container">
          <div className="nav-wrap">
            {isLogin && (
              <div className="btn-group mr-2 d-none-pc">

                  <Dropdown drop="down">
                    <Dropdown.Toggle id="dropdown-basic">
                      <img src={Icon_menu} alt="menu" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item as={Link} to="/streaming">
                        <span className="dropdown-item">
                        스트리밍
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to="/release">
                        <span className="dropdown-item">
                        출시예정
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to="/notice">
                        <span className="dropdown-item">
                        공지사항
                        </span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
              </div>
            )}

            <Link to="/home">
              <img className="logo" src={Img_logo} alt="logo" />
            </Link>
            {isLogin ? (
              <div className="menu-wrap">
                <div className="nav-left">
                  <Link to="/streaming">스트리밍</Link>
                  <Link to="/release">출시예정</Link>
                  <Link to="/notice">공지사항</Link>
                </div>

                <div style={{
                  display: "flex",
                  alignItems: "center",
                }}>
                  <div className="nav-coin-button">
                    <Link className="coin" to="/payments">
                      코인충전
                    </Link>
                  </div>
                  <Dropdown drop="down">
                    <Dropdown.Toggle id="dropdown-basic">
                      <span className="mypage" style={{ backgroundColor: "#222" }}>
                        <PersonIcon sx={{ color: "#868686" }} />
                      </span>
                      <span className="username">{username} 님 &nbsp;</span>
                      <img className="arrow" src={Icon_arr} alt="arr" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <span className="has-coin">보유 코인 : {coin}</span>
                      <Dropdown.Item as={Link} to="/payments">
                        <span className="dropdown-item payments">
                          코인 충전
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to="/mypage">
                        <span className="dropdown-item">
                          마이페이지
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Item as={Link} to="/support">
                        <span className="dropdown-item">
                          고객센터
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={handleLogout}>
                        <span className="dropdown-item">
                          로그아웃
                        </span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            ) : (
              <div className="menu-wrap">
                <div className="nav-left"></div>
                <div className="nav-right">
                  <Link to="/join">회원가입</Link>
                  <Link to="/login">로그인</Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>

      <Modal show={modalShow} onHide={handleClose} backdrop="static" keyboard={false} centered>
        <Modal.Header>고객센터</Modal.Header>
        <Modal.Body>이메일 문의 : luluplaytv@hoyun.co.kr</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            확인
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
