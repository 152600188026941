import moment from "moment";
import React from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { authStore } from "../store/authStore";
import { useNoticeData } from "../utils/useNotice";


export default function Community() {
  const location = useLocation();
  const noticeList = location?.state?.noticeList;
  const currentIndex = location?.state?.currentIndex;
  const totalElements = location?.state?.totalElements;
  console.log("currentIndex")
  console.log(currentIndex)
  console.log("totalElements")
  console.log(totalElements)

  const param = useParams();

  console.log(param?.noticeId);

  const { noticeData: data, isError } = useNoticeData({noticeId: param?.noticeId});


  const noticeData = data?.data?.result;
  console.log(noticeData)
  const navigate = useNavigate()

    const formattedDate = (date) => {
      return moment(date).add(9, "h").format("YYYY-MM-DD");
    }
    if (isError) {
      alert('게시물이 존재하지 않습니다.');
      navigate('/notice');
    }
  return (
    <>
      <Helmet>
        <title>{noticeData?.title || "공지사항 상세"} - 룰루플레이TV</title>
      </Helmet>
      <div id="notice-detail">
        <div className="container">
          <div className="row gx-2 gx-sm-2 gx-md-3">
            <div className="col-12 col-md-10 col-xxl-10 mx-auto">
              <div className="notice-table">
                <h4 className="pt-5 mb-3">공지사항</h4>
                <div className="detail">
                  <div className="head">
                    <h4 className="title"> {noticeData?.title} </h4>
                    <div className="created-at">{formattedDate(noticeData?.createdAt)}</div>
                  </div>
                  <div className="body">
                    <div dangerouslySetInnerHTML={{ __html: noticeData?.content }}></div>
                  </div>
                  <div className="footer">
                    {currentIndex > 0 ? (
                      <Link
                        to={`/notice/${noticeList[currentIndex - 1]?.id}`}
                        state={{ noticeList: noticeList, currentIndex: currentIndex - 1, totalElements: totalElements }}
                      >
                        <div className="prev">
                          <p>이전</p>
                          {noticeList[currentIndex - 1]?.title}
                          <div className="created-at">{formattedDate(noticeList[currentIndex - 1]?.createdAt)}</div>
                        </div>
                      </Link>
                    ) : (
                      <></>
                    )}

                    
                    {currentIndex < totalElements - 1 ? (
                      <Link
                        to={`/notice/${noticeList[currentIndex + 1]?.id}`}
                        state={{ noticeList: noticeList, currentIndex: currentIndex + 1, totalElements: totalElements }}
                      >
                        <div className="next">
                          <p>다음</p>
                          {noticeList[currentIndex + 1]?.title}
                          <div className="created-at">{formattedDate(noticeList[currentIndex + 1]?.createdAt)}</div>
                        </div>
                      </Link>
                    ) : (
                      <></>
                    )}
                    <Link to="/notice" className="goto-list">
                      목록
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
