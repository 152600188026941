import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Icon_coin from "../assets/images/icons/coin-lg.svg";
import AuthApiService from "../services/AuthApiService";
import {authStore} from "../store/authStore";
import moment from "moment";

export default function CoinHistory() {
  const [chargeList, setChargeList] = useState([]);
  const [useList, setUseList] = useState([]);
  const [myVideoList, setMyVideoList] = useState([]);
  const [isFoldedChargeTable, setIsFoldedChargeTable] = useState(true); //펼쳐보기 전용
  const [isFoldedPurchaseTable, setIsPurchaseChargeTable] = useState(true); //펼쳐보기 전용
  
  const { accessToken } = authStore();

  useEffect(() => {
    fetchChargeList();
    fetchUseList();
    fetchMyVideoList();
  }, []);


  const fetchMyVideoList = async () => {
    AuthApiService.getMyVideoList()
      .then((response) => {
        if (response.data.message !== "SUCCESS") {
          console.log("비디오 정보를 불러올 수 없습니다.");
        }
        console.log("FETCH 구매한 비디오 리스트👇");
        console.log(response.data.result);
        setMyVideoList(response.data.result);
      })
      .catch((err) => {
        console.log("FETCH 구매한 비디오 ERROR!👇");
        console.log(err.message);
      });
  };

  const fetchChargeList = async () => {
    AuthApiService.getOrderCoin()
      .then((response) => {
        if (response.data.message !== "SUCCESS") {
          console.log("코인 충전내역을 불러올 수 없습니다.");
        }
        console.log("FETCH 코인 충전 리스트👇");
        console.log(response.data.result);
        setChargeList(response.data.result.reverse());
      })
      .catch((err) => {
        console.log("FETCH 코인 충전 리스트 ERROR!👇");
        console.log(err.message);
      });
  };

  const fetchUseList = async () => {
    AuthApiService.getUsedCoin()
      .then((response) => {
        if (response.data.message !== "SUCCESS") {
          console.log("코인 사용내역을 불러올 수 없습니다.");
        }
        console.log("FETCH 코인 사용 리스트👇");
        console.log(response.data.result);
        setUseList(response.data.result.reverse());
      })
      .catch((err) => {
        console.log("FETCH 코인 사용 리스트 ERROR!👇");
        console.log(err.message);
      });
  };

  function removeSeconds(date) {
    /**
     * [Before]
     * YYYY-MM-DD hh:mm:ss
     *
     * [After]
     * YYYY-MM-DD HH:mm
     */
    
    const formattedDate = moment(date).add(9, 'h').format('YYYY-MM-DD HH:mm');
    return formattedDate;
  }

  function replaceStringToSign(string) {
    const result = string.replace("코인 ", "+");
    return result;
  }

  // TODO: API 응답 값 변경되면 바꾸기
  function coinSourceToKR(source) {
    switch (source) {
      case "WALLET":
        return "영상구매";
      default:
        "영상구매"
    }
  }

  return (
    <>
      <Helmet>
        <title>코인 충전/사용내역 - 룰루플레이TV</title>
      </Helmet>
      <div id="coin-history">
        <div className="container">
          <div className="row gx-2 gx-sm-2 gx-md-3">
            <div className="col-12 col-md-10 col-xxl-10 mx-auto">
              <div className="charge-table">
                <h3 className="mb-4">코인 충전 내역</h3>
                <div className={isFoldedChargeTable ? "table-wrap folded" : "table-wrap"}>
                  <table className="table table-dark">
                    <thead>
                      <tr>
                        <th scope="col">날짜</th>
                        <th scope="col">내역</th>
                      </tr>
                    </thead>
                    <tbody>
                      {chargeList?.length === 0 && (
                        <tr>
                          <td
                            colSpan={2}
                            style={{
                              textAlign: "center",
                              borderBottom: 0,
                              height: 160,
                              position: "relative",
                            }}
                          >
                            <div className="text-gray text-center">
                              <span
                                style={{
                                  position: "absolute",
                                  left: "50%",
                                  top: "50%",
                                  transform: "translate(-50%, -50%)",
                                  display: "inline-block",
                                  minWidth: "170px",
                                }}
                              >
                                코인 충전 내역이 없습니다.
                              </span>
                            </div>
                          </td>
                        </tr>
                      )}
                      {chargeList?.length !== 0 &&
                        chargeList?.map((list, index) => {
                          return (
                            <tr key={index + "-key"}>
                              <td>{removeSeconds(list.updatedAt)}</td>
                              <td>
                                <ul>
                                  <li>
                                    <div>
                                      <span>{replaceStringToSign(list.productTitle)}</span>
                                      <img src={Icon_coin} alt="coin" />
                                    </div>
                                    <div>코인 충전</div>
                                  </li>
                                  {/* <li>금액: {list.price}원</li> */}
                                  <li>카드결제</li>
                                </ul>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                {
                  chargeList.length > 4 && isFoldedChargeTable && (
                    <button
                      className="more"
                      style={{
                        width: "100%",
                        marginTop: 32,
                      }}
                      onClick={() => {
                        setIsFoldedChargeTable(false);
                      }}
                    >
                      <span>펼쳐보기</span>
                    </button>
                  )
                }
              </div>
              <div className="purchase-table">
                <h3 className="mb-4">코인 사용 내역</h3>
                <div className={isFoldedPurchaseTable ? "table-wrap folded" : "table-wrap"}>
                  <table className="table table-dark">
                    <thead>
                      <tr>
                        <th scope="col">날짜</th>
                        <th scope="col">내역</th>
                      </tr>
                    </thead>
                    <tbody>
                      {useList?.length === 0 && (
                        <tr>
                          <td
                            colSpan={2}
                            style={{
                              textAlign: "center",
                              borderBottom: 0,
                              height: 160,
                              position: "relative",
                            }}
                          >
                            <div className="text-gray text-center">
                              <span
                                style={{
                                  position: "absolute",
                                  left: "50%",
                                  top: "50%",
                                  transform: "translate(-50%, -50%)",
                                  display: "inline-block",
                                  minWidth: "170px",
                                }}
                              >
                                코인 사용 내역이 없습니다.
                              </span>
                            </div>
                          </td>
                        </tr>
                      )}
                      {useList?.length !== 0 &&
                        useList?.map((list, index) => {
                          return (
                            <tr key={index + "-key"}>
                              <td>{removeSeconds(list.coin.createdAt)}</td>
                              <td>
                                <ul>
                                  <li>
                                    <div>
                                      <span>{list.coin.coin}</span>
                                      <img src={Icon_coin} alt="coin" />
                                    </div>
                                    <div>코인 사용</div>
                                  </li>
                                  {/* <li>{myVideoList[index]?.video.title}</li> */}
                                  <li>{coinSourceToKR(list.coin.coinSource)}</li>
                                </ul>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                {
                  useList.length > 4 && isFoldedPurchaseTable && (
                    <button
                      className="more"
                      style={{
                        width: "100%",
                        marginTop: 32,
                      }}
                      onClick={() => {
                        setIsPurchaseChargeTable(false);
                      }}
                    >
                      <span>펼쳐보기</span>
                    </button>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
