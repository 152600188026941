import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import ApiService from "../services/ApiService";
import AuthApiService from "../services/AuthApiService";
import {authStore} from "../store/authStore";
import { Button } from "react-bootstrap";
import { AspectRatio } from "@mui/joy";
import { Skeleton } from "@mui/material";

export default function ShortFormList() {
  const navigate = useNavigate();
  const [shortFormData, setShortFormData] = useState([]);
  const [totalElements, setTotalElements] = useState(null);

  const { isLogin, userAge } = authStore();
  /** 처음에 불러올 숏폼 갯수 */
  const INIT_SHORTFORM_SIZE = 10;
  /** 숏폼 더 불러올 때 한번에 몇개씩 가져올지 */
  const BUNCH = 10;

  useEffect(() => {
    getShortFormList(INIT_SHORTFORM_SIZE);
  }, []);  

  const getShortFormList = async (size) => {
    // 로그인 한 사람이면 -> playurl 있음/ 비회원 -> playurl 없음

    if (isLogin) {
      fetchShortFormForUser(size);
    } else {
      fetchShortFormForGuest(size);
    }
  };

  const fetchShortFormForGuest = (size) => {
    ApiService.getShortFormList(size)
      .then((response) => {
        if (response.data.message !== "SUCCESS") {
          console.log("숏폼숏폼 정보를 불러올 수 없습니다.");
        }

        console.log("FETCH 숏폼 리스트 for GUEST👇");
        console.log(response.data.result);

        setShortFormData(response.data.result);
      })
      .catch((err) => {
        console.log("FETCH 숏폼숏폼 ERROR!👇");
        console.log(err);
      });
  }

  const fetchShortFormForUser = (size) => {
    AuthApiService.getShortFormListWithPlayUrl(size)
      .then((response) => {
        console.log("숏폼 성공 요청 response");
        console.log(response);
        if (response.data.message !== "SUCCESS") {
          console.log("숏폼숏폼 정보를 불러올 수 없습니다.");
        }

        console.log("FETCH 숏폼 리스트 for USER👇");
        console.log(response.data.result);

        setShortFormData(response.data.result);
        setTotalElements(response.data.paging.totalElements);
      })
      .catch((err) => {
        console.log("FETCH 숏폼숏폼 ERROR!👇");
        console.log(err);

        // 만료된 토큰일 시 게스트용 숏폼 데이터 불러옴
        console.log("❌❌실패 후 비회원용 숏폼 리스트 호출❌❌");
        fetchShortFormForGuest(size);
      });
  }

  const updateShortFormList = async (size) => {
    AuthApiService.getShortFormListWithPlayUrl(size)
      .then((response) => {
        if (response.data.message !== "SUCCESS") {
          console.log("숏폼숏폼 정보를 불러올 수 없습니다.");
        }

        console.log("업데이트 숏폼숏폼 리스트👇");
        console.log(response.data.result);

        setShortFormData(response.data.result);

        // 상세로 이동
        navigate(`/shortform-detail`, {
          state: {
            selectedIndex: INIT_SHORTFORM_SIZE,
            data: response.data.result,
            BUNCH: BUNCH,
            totalElements: response.data.paging.totalElements,
          },
        });
      })
      .catch((err) => {
        console.log("FETCH 숏폼숏폼 ERROR!👇");
        console.log(err);
      });
  };

  const handleBrowseMoreList = (size) => {
    updateShortFormList(INIT_SHORTFORM_SIZE + size);
  };

  if(shortFormData.length > 0 && shortFormData) {
    return (
      <>
        <h3 className="video-title">👍 둘러보기</h3>
        <div className="list-container">
          <div className="shortform-list-wrapper">
            <Swiper
              spaceBetween={8}
              slidesPerView={2.4}
              modules={[FreeMode]}
              freeMode={true}
              breakpoints={{
                1440: {
                  slidesPerView: 6.4,
                  spaceBetween: 16,
                },
                744: {
                  slidesPerView: 6.4,
                  spaceBetween: 8,
                },
              }}
              onSwiper={(swiper) => {}}
            >
              {shortFormData.length > 0 &&
                shortFormData.map((item, index) => {
                  const { title, thumbnailUrl, servicePolicyAgeLevel } = item;
                  return (
                    <SwiperSlide className="shortform-listItem" key={title}>
                      <Link
                        to={`/shortform-detail`}
                        state={{
                          selectedIndex: index,
                          data: shortFormData,
                          BUNCH: BUNCH,
                          totalElements: totalElements && totalElements,
                        }}
                      >
                        <div className={userAge !== "ADULT" && servicePolicyAgeLevel === "ADULT" ? "image-wrap blur" : "image-wrap"}>
                          <img src={`${thumbnailUrl}?w=318&h=565&q=100`} alt={title} />
                        </div>
                        <span>{title}</span>
                      </Link>
                    </SwiperSlide>
                  );
                })}
              {shortFormData.length < INIT_SHORTFORM_SIZE ? null : (
                <SwiperSlide style={{ height: "auto" }}>
                  <button
                    className="more"
                    style={{
                      height: "100%",
                    }}
                    onClick={() => handleBrowseMoreList(BUNCH)}
                  >
                    더보기
                  </button>
                </SwiperSlide>
              )}
            </Swiper>
          </div>
        </div>
      </>
    );
  } else {
    // 숏폼 리스트 스켈레톤 컴포넌트
    return (
      <>
        <h3 className="video-title">
          <Skeleton sx={{ width: "30%", bgcolor: "grey.900", WebkitTransform: "unset", height: "24px" }} />
        </h3>
        <div className="list-container">
          <div className="shortform-list-wrapper">
            <Swiper
              spaceBetween={8}
              slidesPerView={2.4}
              breakpoints={{
                1440: {
                  slidesPerView: 6.4,
                  spaceBetween: 16,
                },
                744: {
                  slidesPerView: 6.4,
                  spaceBetween: 8,
                },
              }}
            >
              {Array.from({ length: 7 }).map((list, index) => {
                return (
                  <SwiperSlide className="shortform-listItem" key={`shortform-${index}`}>
                    <AspectRatio
                      ratio="9/16"
                      variant="plain"
                      objectFit="cover"
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Skeleton sx={{ width: "100%", height: "100px", bgcolor: "grey.900", WebkitTransform: "unset" }}></Skeleton>
                    </AspectRatio>
                    <Skeleton sx={{ width: "80%", bgcolor: "grey.900", WebkitTransform: "unset", height: "18px", marginTop: "10px" }} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      </>
    );
  }
}
