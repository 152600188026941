import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Icon_coin from "../assets/images/icons/coin-lg-red.svg";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {authStore} from "../store/authStore";
import { Helmet } from "react-helmet-async";
import AuthApiService from "../services/AuthApiService";
import loadingStore from "../store/loadingStore";
import { Skeleton } from "@mui/material";
import useCoin from "../utils/useCoin";

export default function Payments() {
  const { userAge } = authStore();
  const { setIsLoading } = loadingStore();
  const [coinListData, setCoinListData] = useState([]);
  const navigate = useNavigate();

  // 코인
  const { coin } = useCoin();

  useEffect(() => {
    if (userAge !== "ADULT") {
      alert("코인 충전을 위해 성인인증을 해주세요.");
      navigate("/authentication-request");
    }
    
    fetchChargableCoinList();
  }, []);

  // 모달
  const [termsModal, setTermsModal] = useState(false);
  const handleTermsModalClose = () => setTermsModal(false);
  const handleTermsModalShow = () => setTermsModal(true);
  const [reasonTermsModal, setReasonTermsModal] = useState(false);
  const handleReasonTermsModalClose = () => setReasonTermsModal(false);
  const handleReasonTermsModalShow = () => setReasonTermsModal(true);

  const fetchChargableCoinList = async () => {
    AuthApiService.getProductCoin()
      .then((response) => {
        if (response.data.message !== "SUCCESS") {
          console.log("코인 상품 정보를 불러올 수 없습니다.");
        }
        console.log("FETCH 코인상품👇");
        console.log(response.data.result);
        setCoinListData(response.data.result);
      })
      .catch((err) => {
        console.log("FETCH 코인상품 ERROR!👇");
        console.log(err.message);
      });
  };

  // const fetchChargableCoinList = async () => {

  //   axios.get(`${import.meta.env.VITE_APP_API_SERVER_HOST}/product/coin`, {
  //     headers: {
  //       Authorization: accessToken,
  //     },
  //   })
  //   .then(response => {
  //     console.log(response)
  //   })
  //   .catch(error => {
  //     console.log(error);

  //   })
  // }

  //   await fetch(`${import.meta.env.VITE_APP_API_SERVER_HOST}/product/coin`, {
  //     method: "GET",
  //     headers: {
  //       Authorization: accessToken,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log("datadatadatadatadatadata");
  //       console.log(data);
  //     })
  //     .catch((error) => {

  //       console.log("errorerrorerrorerrorerrorerror");
  //       console.log(error.response);
  //     });
  // };

  const [selectedIndex, setSelectedIndex] = useState(null);
  // 약관동의 checkbox values
  const [checkAll, setCheckAll] = useState(false);
  const [checkTerms, setCheckTerms] = useState(false);
  const [checkPrivacyPolicy, setCheckPrivacyPolicy] = useState(false);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  // 약관동의 checkbox 동작
  function handleChange(value, setValue) {
    setValue(!value);
  }
  // 약관동의 전체 체크 동작
  function changeCheckAll() {
    if (!checkAll) {
      setCheckTerms(true);
      setCheckPrivacyPolicy(true);
    } else {
      setCheckTerms(false);
      setCheckPrivacyPolicy(false);
    }
  }

  useEffect(() => {
    if (!checkTerms || !checkPrivacyPolicy) {
      setCheckAll(false);
    }
    if (checkTerms && checkPrivacyPolicy) {
      setCheckAll(true);
    }
    // 버튼 disabled 컨트롤
    if (selectedIndex != null && checkTerms && checkPrivacyPolicy) {
      setIsCheckedAll(true);
    } else {
      setIsCheckedAll(false);
    }
  }, [selectedIndex, checkAll, checkTerms, checkPrivacyPolicy]);

  const fetchOrderList = async (id) => {
    setIsLoading(true);
    AuthApiService.postOrderCoin(id)
      .then((response) => {
        setIsLoading(false);
        if (response.data.message !== "SUCCESS") {
          console.log("주문서 정보를 불러올 수 없습니다.");
        } else {
          console.log("FETCH 주문서 응답👇");
          console.log(response.data.result);

          const result = response.data.result;
          navigate(`/payments/${result.uuid}`, {
            state: {
              price: result?.price,
              title: result?.productTitle,
            },
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);

        console.log("FETCH 주문서 ERROR!👇");
        console.log(err.message);
      });
  };

  const handleChangeSubmit = () => {
    fetchOrderList(coinListData[selectedIndex].productCoin.id);
  };

  return (
    <>
      <Helmet>
        <title>코인 충전 - 룰루플레이TV</title>
      </Helmet>
      <div id="payments">
        <div className="container">
          <div className="row gx-2 gx-sm-2 gx-md-3">
            <div className="col-12 col-md-12 col-xxl-12 mx-auto">
              <h3 className="mt-5">코인 충전</h3>
              <div className="now-coin">
                <img src={Icon_coin} alt="coin" />
                <span>현재 보유한 코인</span>
                <i>{coin}개</i>
              </div>
              <ul className="charge-list">
                {coinListData.length > 0 && coinListData
                  ? coinListData?.map((item, index) => {
                      return (
                        <li
                          onClick={() => {
                            setSelectedIndex(index);
                          }}
                          key={item.productCoin.id + "-key"}
                          className={selectedIndex === index ? "active" : null}
                        >
                          <div className="head">
                            <img src={Icon_coin} alt="coin" />
                            <span>X {item.productCoin.quantity}</span>
                          </div>
                          <div className="body">
                            <div className="discount-price">{item.productCoin.price.toLocaleString("ko-KR")}원</div>
                          </div>
                        </li>
                      );
                    })
                  : Array.from({ length: 6 }).map((list, index) => {
                      // 코인 상품 스켈레톤 추가
                      return (
                        <li key={`prudoct-${index}`} className={selectedIndex === index ? "active" : null}>
                          <Skeleton
                            className="head"
                            sx={{ minWidth: "100%", bgcolor: "grey.900", WebkitTransform: "unset", backgroundColor: "#212121 !important" }}
                          >
                            <img style={{ width: "100%" }} alt="#" />
                          </Skeleton>
                          <Skeleton className="body" sx={{ width: "100%", bgcolor: "grey.900", WebkitTransform: "unset", margin: "0 auto", marginTop: "10px" }}>
                            <div className="discount-price" style={{ width: "100%", textAlign: "center" }}>
                              Product
                            </div>
                          </Skeleton>
                        </li>
                      );
                    })}
              </ul>
              <div className="mb-40 mt-4">
                <div className="input-checkbox mb-3">
                  <input
                    id="agreeAll"
                    type="checkbox"
                    checked={checkAll}
                    onChange={(e) => {
                      handleChange(checkAll, setCheckAll);
                      changeCheckAll();
                    }}
                  />
                  <label htmlFor="agreeAll" className="fw-700">
                    모든 약관을 확인하고 전체 동의합니다.
                  </label>
                </div>
                <ul>
                  <li>
                    <div className="input-checkbox mb-3">
                      <input id="agreeTerms" type="checkbox" checked={checkTerms} onChange={() => handleChange(checkTerms, setCheckTerms)} />
                      <label htmlFor="agreeTerms">
                        <span onClick={() => handleTermsModalShow()}>동영상 환불 규정 (필수)</span>
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="input-checkbox mb-3">
                      <input
                        id="agreePrivacy"
                        type="checkbox"
                        checked={checkPrivacyPolicy}
                        onChange={() => handleChange(checkPrivacyPolicy, setCheckPrivacyPolicy)}
                      />
                      <label htmlFor="agreePrivacy">
                        <span onClick={() => handleReasonTermsModalShow()}>환불 불가 사유 (필수)</span>
                      </label>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="summary">
                <ul className="summary-list">
                  <li>
                    <span>현재 코인</span>
                    <span>
                      <i>{coin}</i>
                      <img src={Icon_coin} alt="coin" />
                    </span>
                  </li>
                  <li>
                    <span>충전할 코인</span>
                    <span>
                      <i>{(selectedIndex !== null && coinListData[selectedIndex]?.productCoin?.quantity) || "0"}</i>
                      <img src={Icon_coin} alt="coin" />
                    </span>
                  </li>
                  <li>
                    <span>충전후 코인</span>
                    <span>
                      {selectedIndex !== null ? <i>{coin + coinListData[selectedIndex]?.productCoin?.quantity}</i> : <i>{coin}</i>}
                      <img src={Icon_coin} alt="coin" />
                    </span>
                  </li>
                </ul>
                <Button onClick={handleChangeSubmit} id="submit" className={isCheckedAll ? `button` : `disabled button`}>
                  충전하기
                </Button>
                {/* <button className="button" onClick={handleSubmit} disabled={!isCheckedAll}>충전하기</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 환불 규정 약관 모달 */}
      <Modal show={termsModal} onHide={handleTermsModalClose} backdrop="static" keyboard={false} centered>
        <Modal.Body>
          <div className="mt-3 text-start">
            <strong>동영상 환불 규정(필수)</strong>
            <br />
            <br />
            이용고객이 자신의 ID를 통해 결제한 유료 서비스 및 충전한 포인트 잔여액의 요금 환불을 요청한 경우 다음 각 호의 문제가 발생 시에 한하여 회사는
            이용고객이 결제한 요금을 환불합니다.
            <br />
            <br />
            &nbsp;&nbsp;a. 회사의 서비스에 문제가 발생하여 이용고객이 서비스를 받지 못한 경우
            <br />
            &nbsp;&nbsp;b. 이용고객의 환불요청이 상거래의 관례에 비추어 회사에서 정당하다고 판단되는 경우
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleTermsModalClose} style={{ height: "56px", display: "flex", flexDirection: "column" }}>
            <span>확인</span>
          </Button>
        </Modal.Footer>
      </Modal>

      {/* 환불 불가 사유 약관 모달 */}
      <Modal show={reasonTermsModal} onHide={handleReasonTermsModalClose} backdrop="static" keyboard={false} centered>
        <Modal.Body>
          <div className="mt-3 text-start">
            <strong>환불 불가 사유(필수)</strong>
            <br />
            <br />
            전 1항의 환불조건에도 불구하고 아래 각 호의 해당이 되는 경우 회사는 환불을 거부할 수 있습니다.
            <br />
            <br />
            &nbsp;&nbsp;a. 이용고객의 요금결제 후 기간이 3일이 경과된 경우
            <br />
            &nbsp;&nbsp;b. 이용고객이 콘텐츠 사용을 목적으로 1개 이상 상품구입을 한 경우
            <br />
            &nbsp;&nbsp;c. 이용고객의 컴퓨터 및 사무환경 문제로 서비스를 이용하지 못한 경우
            <br />
            &nbsp;&nbsp;d. 이용고객이 요금결제 후 단순변심(콘텐츠불만족,사이트 재이용 여부) 등으로 환불을 요청하는 경우
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleReasonTermsModalClose} style={{ height: "56px", display: "flex", flexDirection: "column" }}>
            <span>확인</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
